/* eslint-disable no-plusplus */
import axios from 'axios';
import { useMemo, useRef } from 'react';

export function dataURLtoFile(dataurl, fileName) {
  if (dataurl) {
    const arr = dataurl.split(',');
    if (arr && typeof arr === 'object' && arr.length > 0) {
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = window.atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
    }
  }
  return null;
}

export const useQuill = () => {
  const QuillRef = useRef();

  const imageHandler = () => {
    // 파일을 업로드 하기 위한 input 태그 생성
    const input = document.createElement('input');
    const formData = new FormData();
    let url = '';

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files;
      if (file !== null) {
        formData.append('prefix', 'test');
        formData.append('image', file[0]);
        try {
          const token = sessionStorage.getItem('token');
          const res = await axios.post('/file', formData, {
            headers: { token, 'Content-Type': 'multipart/form-data' },
          });

          url = res.data.data.fileList[0].url;
          const { filename } = res.data.data.fileList[0];

          const range = QuillRef.current?.getEditor().getSelection()?.index;
          if (range !== null && range !== undefined) {
            const quill = QuillRef.current?.getEditor();

            quill?.setSelection(range, 1);

            quill?.clipboard.dangerouslyPasteHTML(
              range,
              `<img src=${url} alt=${filename} />`,
            );
          }
        } catch (error) {
          // const err = error;
          // return { ...err.response, success: false };
        }
      }
    };
  };

  const quillModules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ size: ['small', false, 'large', 'huge'] }, { color: [] }],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
            { align: [] },
          ],
          ['image', 'video'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [],
  );

  return { quillModules, QuillRef };
};

const quillModules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['video', 'link', 'image'],
    ['clean'],
  ],
};

export default quillModules;
