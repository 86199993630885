import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useHistory } from 'react-router';

function switchTitle(title) {
  switch (title) {
    case 'bps':
      return '사업추진 실적';
    case 'labNews':
      return '연구소 소식';
    case 'notices':
      return '공지사항';
    default:
      return '';
  }
}

function switchURL(url) {
  switch (url) {
    case 'bps':
      return '/perform';
    case 'labNews':
      return '/labnews';
    case 'notices':
      return '/notice';
    default:
      return '';
  }
}

function TileBoard() {
  const [sections, setSections] = useState({});
  const history = useHistory();

  const onShowBoard = useCallback(async () => {
    try {
      const result = await axios.get('/main');

      if (result.data.status === 200) {
        setSections(result.data.data.board);
      } else {
        throw new Error('error');
      }
    } catch (err) {
      // const a = 1;
    }
  }, []);

  useEffect(() => {
    onShowBoard();
  }, []);

  const onMove = useCallback(
    (url) => () => {
      history.push(url);
    },
    [history],
  );

  return (
    <>
      {Object.entries(sections)
        .reverse()
        .map(([key, value]) => {
          const title = value.length > 0 ? value.slice(0, 1).pop()?.title : '';
          const isExistBracket = title ? title.split(']').length > 1 : false;
          return (
            <Board key={key}>
              <div className='tile-title'>
                <div>
                  <div className='tile-title-baseColor'></div>
                  <div className='tile-title-whiteColor'></div>
                </div>
                <h2>{switchTitle(key)}</h2>
              </div>
              <div className='tile-content'>
                <div className='tile-content-letter'>
                  {isExistBracket ? title.split(']')[0].concat(']') : title}
                  <br />
                  {isExistBracket ? title.split(']')[1] : ''}
                </div>
                <div
                  className='tile-content-detail'
                  role='presentation'
                  onClick={onMove(switchURL(key))}>
                  <button>자세히보기&nbsp;&nbsp;&gt;&gt;</button>
                </div>
              </div>
              <TileFooter>
                {value && value.slice(1) ? (
                  value.slice(1).map((post) => (
                    <div key={post.id}>
                      <div className='tile-footer-circle'></div>
                      <div className='tile-footer-letter'>{post.title}</div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </TileFooter>
            </Board>
          );
        })}
    </>
  );
}

export const Board = styled.div`
  min-width: 380px;
  width: 380px;
  height: 320px;
  background-color: #fff;
  box-sizing: border-box;
  margin-right: 30px;
  margin-left: 30px;
  border: 1px solid #ccc;
  padding: 20px;
  .tile-title {
    display: flex;
    margin-bottom: 20px;
    .tile-title-baseColor {
      width: 5px;
      height: 11px;
      background-color: #08587d;
    }
    .tile-title-whiteColor {
      width: 5px;
      height: 11px;
      background-color: #ccc;
    }
    h2 {
      margin: 0;
      margin-left: 10px;
      font-size: 20px;
    }
  }
  .tile-content {
    border-bottom: 1px solid #ccc;
    .tile-content-letter {
      padding-top: 10px;
      margin-bottom: 5px;
      box-sizing: border-box;
      line-height: 1.5;
      font-size: 17px;
      font-weight: 700;
    }
    .tile-content-detail {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 50px;
      font-size: 17px;
      button {
        color: #08587d;
        outline: none;
        border: none;
        background-color: transparent;
      }
    }
  }
`;

export const TileFooter = styled.div`
  & > div {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .tile-footer-circle {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #08587d;
  }
  .tile-footer-letter {
    margin-left: 5px;
    font-size: 17px;
    color: #707070;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export default React.memo(TileBoard);
