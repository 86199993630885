import React from 'react';
import styled from 'styled-components';
import CertImg from 'public/images/certification.png';

function CertSection() {
  return (
    <>
      <CertSectionContent>
        <img src={CertImg} width='95%' alt='Cert' />
      </CertSectionContent>
    </>
  );
}

const CertSectionContent = styled.section`
  display: flex;
  justify-content: center;
  border: 1px solid #cccccc;
  padding: 30px 0;
  margin: 0 1rem;
  margin-bottom: 4rem;
`;
export default React.memo(CertSection);
