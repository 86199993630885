import React from 'react';
import styled from 'styled-components';

import { theme } from '@utils/theme';
import { pixelToRem, flexAlign } from '@utils/commonStyles';

function MobileTopSection() {
  return (
    <>
      <MobileTopSide>
        <div className='weare'>
          <strong className='bar'>ㅡ</strong> <br />
          <strong>{'WE ARE'}</strong> <br />
          <p>{'CARAMELLA'}</p>
        </div>
        <div className='info-text'>
          (주)카라멜라는 빅데이터, AI, 딥러닝/연구개발 노하우를
          <br />
          바탕으로 축적된 데이터 분야의 자체 기술력을 더욱 발전시켜
          <br />
          AI 및 빅데이터 분야의 선도 기업으로 더욱 성장해 나아갈 것입니다.
          <br />
        </div>
      </MobileTopSide>
    </>
  );
}

const MobileTopSide = styled.section`
  height: 14rem;
  background-color: ${theme.background.lightGray};
  color: ${theme.color.gray707070};
  font-weight: bold;
  ${flexAlign('column')}
  @media only all and (min-width: 768px) {
    height: 16rem;
    strong {
      font-size: 20px;
    }
    p {
      font-size: 20px;
    }
  }

  .weare {
    margin-top: -10px;
    text-align: center;
    line-height: 0.4;
    font-weight: 800;
    .bar {
      color: ${theme.color.baseColor};
    }
    strong {
      font-size: 20px;
      line-height: 1.4;
      color: #a9aec1;
      margin-left: 1px;
    }
    p {
      margin-right: 3px;
      margin-bottom: ${pixelToRem(20)};
      font-size: 20px;
      color: ${theme.color.baseColor};
    }
  }

  .info-text {
    font-size: 12px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    line-height: 1.8;
    font-weight: 600;
    margin-top: ${pixelToRem(10)};
    @media only all and (min-width: 768px) {
      font-size: 15px;
      margin-bottom: ${pixelToRem(10)};
    }
  }
`;
export default React.memo(MobileTopSection);
