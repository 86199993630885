import React from 'react';

import metu from 'public/images/business/desktop/metu.png';
import selfieToy from 'public/images/business/desktop/selfieToy.png';

import { deepLearningAIText } from '@statics/text';

import Common from '../../../layout/desktop/Common';
import {
  Contents,
  ImageLinkContainer,
  ImageLink,
} from '../../../components/desktop/business/styles';

function DeepLearning() {
  return (
    <Common>
      <Contents>
        <div className='explain'>
          {deepLearningAIText.map((text) => (
            <p className='text-bold' key={text}>
              {text}
            </p>
          ))}
        </div>
        <ImageLinkContainer>
          <ImageLink
            href='https://metu.ai'
            target='_blank'
            rel='noopener noreferrer'>
            <p>{'METU (미투)'}</p>
            <img src={metu} alt='metu' />
          </ImageLink>
          <ImageLink>
            <p>{'Selfietoy (셀피토이)'}</p>
            <img src={selfieToy} alt='selfietoy' />
          </ImageLink>
        </ImageLinkContainer>
      </Contents>
    </Common>
  );
}

export default React.memo(DeepLearning);
