import React from 'react';
import styled from 'styled-components';

import { TelText } from '@statics/text';

function Footer() {
  return (
    <FooterContainer>
      <FooterItem>
        <div className='footer-logo'>CARAMELLA</div>
        <div>
          <p>
            {`(주)카라멜라 대표: 박서연 사업자등록번호: 405-86-01678 TEL: ${TelText}`}
          </p>
          <p>{'본사: 광주광역시 남구 송암로24번가길 46, 503호'}</p>
          <p>Copyright &copy; 2021 CARAMELLA Inc. All rights reserved.</p>
        </div>
      </FooterItem>
    </FooterContainer>
  );
}

export const FooterContainer = styled.div`
  min-width: 1280px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background-color: #0c4161;
`;

const FooterItem = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 110px;
  padding-top: 35px;
  padding-bottom: 35px;
  .footer-logo {
    min-width: 300px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
    font-family: Lato;
  }
  div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > p {
      margin-top: 0;
      margin-bottom: 0;
      line-height: 2;
      color: #fff;
      font-size: 17px;
    }
  }
`;

export default React.memo(Footer);
