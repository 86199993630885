import React, { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import styled from 'styled-components';
import useChangeString from '@hooks/useChangeString';
import { baseUrl } from '@config/config';
import useModal from '@hooks/useModal';
import { theme } from '@utils/theme';
import { flexAlign, pixelToRem } from '@utils/commonStyles';
import Modal from '../Modal';

function DevelopSection() {
  const [companyName, onChangeCompanyName, setCompanyName] = useChangeString();
  const [name, onChangeName, setName] = useChangeString();
  const [email, onChangeEmail, setEmail] = useChangeString();
  const [tel, onChangeTel, setTel] = useChangeString();
  const [inquiryContent, onChangeInquiryContent, setInquiryContent] =
    useChangeString();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileNames, setUploadedFileNames] = useState([]);
  const history = useHistory();
  const [modalHidden, onHidden] = useModal();

  const onUpload = useCallback((e) => {
    if (!e.target.files) {
      return;
    }
    if (!e.target.files[0]) {
      return;
    }

    if (
      Array.from(e.target.files).some((file) => file.size > 100 * 1024 * 1024)
    ) {
      return;
    }

    Array.from(e.target.files).forEach((files) => {
      setUploadedFiles((prev) => prev.concat(files));
      setUploadedFileNames((prev) => prev.concat(files.name));
    });
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      if (
        !companyName.trim() ||
        !name.trim() ||
        !email.trim() ||
        !tel.trim() ||
        !inquiryContent.trim()
      ) {
        return;
      }

      const formData = new FormData();

      const body = {
        service: 'support',
        contents: JSON.stringify({
          to: 'pm@caramella.kr',
          from: email,
          subject: `${companyName}으로부터 문의가 도착했습니다.`,
          text: `회사명: ${companyName}\n담당자 성함: ${name}\n담당자 이메일: ${email}\n담당자 연락처: ${tel}\n문의 내용: ${inquiryContent}`,
        }),
      };

      Object.entries(body).forEach(([key, value]) => {
        formData.append(`${key}`, value);
      });

      if (uploadedFiles.length > 0) {
        uploadedFiles.forEach((uploadFile) => {
          formData.append(uploadFile.name, uploadFile);
        });
      }

      const result = await axios.post(`${baseUrl}/email`, formData);

      if (result.data.status === 200) {
        setCompanyName('');
        setName('');
        setEmail('');
        setTel('');
        setInquiryContent('');
        setUploadedFiles([]);
        onHidden();
      } else {
        throw new Error('error');
      }
    } catch (err) {
      // const a = 1;
    }
  }, [baseUrl, companyName, name, email, tel, inquiryContent, uploadedFiles]);

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);
  const modalSentenceStyle = useMemo(
    () => ({
      fontSize: 15,
      fontWeight: 600,
      color: '#000',
      textAlign: 'center',
    }),
    [],
  );

  return (
    <>
      <Modal
        onClick={onHidden}
        modalHidden={modalHidden}
        submitText={'확인'}
        isExistSecondButton={false}
        onHidden={onHidden}>
        <p style={modalSentenceStyle}>문의가 등록되었습니다</p>
      </Modal>
      <DevelopSectionContent>
        <div className='input-field'>
          <div className='left-field'>
            <span>*</span>회사명
          </div>
          <input
            type='text'
            placeholder='회사명을 입력해주세요'
            className='right-field'
            value={companyName}
            onChange={onChangeCompanyName}
          />
        </div>
        <div className='input-field'>
          <div className='left-field'>
            <span>*</span>담당자 성함
          </div>
          <input
            type='text'
            className='right-field'
            placeholder='담당자 성함을 입력해주세요'
            value={name}
            onChange={onChangeName}
          />
        </div>
        <div className='input-field'>
          <div className='left-field email'>
            <span>*</span>담당자 이메일
          </div>
          <input
            type='email'
            className='right-field'
            placeholder='담당자 이메일을 입력해주세요'
            value={email}
            onChange={onChangeEmail}
          />
        </div>
        <div className='input-field'>
          <div className='left-field'>
            <span>*</span>담당자 연락처
          </div>
          <input
            type='text'
            className='right-field'
            placeholder='담당자 연락처를 입력해주세요'
            value={tel}
            onChange={onChangeTel}
          />
        </div>
        <div className='textarea-field'>
          <div className='left-field'>
            <span>*</span>문의 내용
          </div>
          <textarea
            value={inquiryContent}
            onChange={onChangeInquiryContent}
            rows='10'
            placeholder='개발 문의 내용을 가능한 구체적으로 기입해주세요'
            className='right-field textarea'></textarea>
        </div>
        <div className='input-field'>
          <div className='left-field' style={{ marginTop: '-0.5rem' }}>
            첨부파일
          </div>
          <div className='label-field'>
            <input
              id='upload-files'
              type='file'
              accept='image/*'
              multiple
              onChange={onUpload}
              hidden
            />
            <div className='label-container'>
              <div className='no-func'>
                <>
                  {`${'\u0020'.normalize()}`}{' '}
                  {uploadedFileNames.map((v, i) => (
                    <span key={i}>{`${v} `}</span>
                  ))}
                </>
              </div>
              <label htmlFor='upload-files' className='upload-label'>
                업로드
              </label>
            </div>
          </div>
        </div>
        <div className='additional'>{`${'\u002A'.normalize()} 프로젝트 파악 및 견적 산출에 도움이 될 자료가 있는 경우 첨부파일로 보내주시길 바랍니다`}</div>
      </DevelopSectionContent>
      <SubmitButton>
        <button onClick={onSubmit}>{'등록'}</button>
        <button onClick={onCancel}>{'닫기'}</button>
      </SubmitButton>
    </>
  );
}

const DevelopSectionContent = styled.div`
  padding: 20px 10px;
  box-sizing: border-box;
  border: 1px solid ${theme.border.gray};
  margin: 0 3%;

  .input-field {
    display: flex;
    height: ${pixelToRem(20)};
    margin-bottom: ${pixelToRem(40)};

    .left-field {
      width: ${pixelToRem(100)};
      ${flexAlign(undefined, undefined, 'none')}
      color: ${theme.color.black};
      font-size: 14px;
      font-weight: 600;
      margin-right: ${pixelToRem(20)};
      height: 2rem;

      span {
        color: red;
        margin-right: 0.3rem;
      }
      .required {
        color: #f00;
      }
    }

    .right-field {
      flex: 8;
      box-sizing: border-box;
      border: 1px solid ${theme.border.gray};
      background-color: ${theme.background.white};
      color: ${theme.color.black};
      font-size: 13px;
      ${flexAlign(undefined, undefined, 'none')}
      padding-left: ${pixelToRem(10)};
      outline: none;
      height: 2rem;
      &::placeholder {
        font-size: 10px;
        color: ${theme.color.gray707070};
      }
    }

    .label-field {
      flex: 8;
      margin-top: -0.7rem;
      .label-container {
        display: flex;
        .no-func {
          ${flexAlign(undefined, undefined, 'none')}
          width: 65%;
          margin-right: 0.5rem;
          margin-left: 0.2rem;
          background-color: ${theme.background.white};
          box-sizing: border-box;
          border: 1px solid ${theme.border.gray};
          height: ${pixelToRem(40)};
          padding-left: 3px;
          padding-right: 3px;
          span {
            margin-right: 5px;
            font-size: 5px;
          }
        }
      }

      .upload-label {
        height: ${pixelToRem(40)};
        width: 31%;
        ${flexAlign()}
        color: ${theme.color.white};
        background-color: ${theme.background.baseColor};
        outline: none;
        cursor: pointer;
        border: none;
        font-size: ${pixelToRem(13)};
      }
    }
  }
  .textarea-field {
    display: flex;
    margin-bottom: ${pixelToRem(40)};
    .left-field {
      width: ${pixelToRem(120)};
      display: flex;
      color: ${theme.color.black};
      font-weight: 600;
      font-size: 14px;
      span {
        color: red;
        margin-right: 0.3rem;
      }
      .required {
        color: #f00;
      }
    }
    .right-field {
      flex: 8;
      padding: ${pixelToRem(10)};
      outline: none;
      resize: none;
      border: 1px solid ${theme.border.gray};
      ::placeholder {
        font-size: 10px;
        color: ${theme.color.gray707070};
      }
    }
  }
  .additional {
    color: ${theme.color.gray707070};
    font-size: 10px;
  }
`;
export const SubmitButton = styled.div`
  ${flexAlign()}
  margin: 4rem 0;
  button {
    outline: none;
    border: none;
    font-size: 13px;
    background-color: ${theme.background.baseColor};
    color: ${theme.color.white};
    height: ${pixelToRem(40)};
    width: ${pixelToRem(133)};
    cursor: pointer;

    &:first-child {
      margin-right: ${pixelToRem(30)};
    }

    &:last-child {
      border: 1px solid ${theme.border.baseColor};
      background-color: ${theme.background.white};
      color: ${theme.color.baseColor};
    }
  }
`;

export default React.memo(DevelopSection);
