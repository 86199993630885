import React from 'react';
import { useLocation } from 'react-router';
import DesktopLayout from '../../../components/desktop/DesktopLayout';
import ContentContainer from '../../../components/desktop/ContentContainer';
import TileBoard from '../../../components/desktop/TileBoard';
import Contact from '../../../components/desktop/Contact';
import Head from '../../../components/Head';
import { BoardContainer, MainBanner, BoardWrapper } from './styles';

function Home() {
  const { pathname } = useLocation();
  return (
    <DesktopLayout>
      <Head pathname={pathname} />
      <MainBanner>
        <div className='main-bg'>
          <div className={'main-background'}>
            <h2>IT 융합기술로 미래를 향한 도전</h2>
            <p>{'빅데이터를 이용하여 최적화된 미래를 만드는'}</p>
            <p>{'앞선 기술로 여러분과 함께 더 나은 미래를 만들어 갑니다'}</p>
          </div>
        </div>
      </MainBanner>
      <ContentContainer />
      <BoardWrapper>
        <BoardContainer>
          <TileBoard />
        </BoardContainer>
      </BoardWrapper>
      <Contact />
    </DesktopLayout>
  );
}

export default React.memo(Home);
