import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import { theme } from '@utils/theme';
// import { flexAlign, pixelToRem } from '@utils/commonStyles';

const SubMenu = ({ item }) => {
  const [subNav, setSubNav] = useState(false);
  const showSubNav = () => setSubNav(!subNav);

  return (
    <>
      <SidebarLink to={item.path} onClick={item.subNav && showSubNav}>
        <div>
          <SidebarLabel subnav={subNav}>
            {item.title}
            {item.icon}
          </SidebarLabel>
        </div>
        <div>
          {item.subNav && subNav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subNav &&
        item.subNav.map((items, index) => (
          <DropdownLink to={items.path} key={index}>
            {items.icon}
            <SidebarLabel>{items.title}</SidebarLabel>
          </DropdownLink>
        ))}
    </>
  );
};

const SidebarLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 1.6rem;
  margin: 1rem 0 1rem 0;
  text-decoration: none;
  font-size: 0.95rem;
  position: relative;
  @media only all and (min-width: 630px) {
    font-size: 1rem;
  }

  &:hover {
    color: gray;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  .arrow-down {
    position: absolute;
    right: 0.6rem;
    transition: 850ms all;
    transform: ${({ subnav }) => (subnav ? 'rotate(180deg)' : '')};
  }
`;

const DropdownLink = styled(Link)`
  height: 1.5rem;
  margin: 2px 0 0 0.7rem;
  display: flex;
  text-decoration: none;
  font-size: 0.6rem;
  @media only all and (min-width: 630px) {
    font-size: 0.8rem;
    margin: 4px 0 0 0.7rem;
  }
  &:hover {
    cursor: pointer;
  }
`;
export default SubMenu;
