import styled from 'styled-components';
import mainBanner from '@/public/images/banner3.png';

export const MainBanner = styled.div`
  background-image: url(${mainBanner});
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  color: #fff;
  height: 500px;
  max-width: 1920px;
  margin: 0 auto;
  .main-bg {
    max-width: 1920px;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.29);
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .main-background {
    max-width: 1920px;
    width: 1920px;
    overflow: hidden;
    min-width: 1280px;
    box-sizing: border-box;
    padding-left: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      font-size: 30px;
    }

    p {
      font-size: 17px;
      margin: 0;
      line-height: 1.5;
    }
  }
`;

export const Contents = styled.div`
  display: flex;
  border: 1px solid #ccc;
  margin: 0 auto;
  max-width: 1920px;
  min-width: 1280px;
  & > div {
    box-sizing: border-box;
    border-left: 1px solid #ccc;
  }
  .company-letter {
    width: 370px;
    min-width: 370px;
    border-left: none;
    padding-left: 30px;
    margin-top: 60px;
    & > .line {
      background-color: #08587d;
      width: 48px;
      height: 3px;
    }
    & > p {
      margin: 0;
      font-size: 50px;
      line-height: 1.4;
    }
    & > .light {
      color: #d0d5e5;
      margin-top: 30px;
    }
    & > p:nth-child(3) {
      color: #08587d;
      font-weight: 600;
    }
  }
  .intro {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 400px;
    .number {
      color: #08587d;
      display: flex;
      justify-content: flex-end;
    }
    .out-circle {
      background-color: #08587d;
      border-radius: 50%;
      width: 170px;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      .in-circle {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        .content-icon {
          transform: scale(1.5);
          svg {
            fill: #08587d;
          }
        }
      }
    }
    .title {
      font-size: 17px;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 600;
    }
    .desc {
      font-size: 17px;
      color: #707070;
      line-height: 25px;
    }
    .detail-link {
      color: #08587d;
      font-size: 17px;
      margin-top: 15px;
    }
    /* &:hover {
      background-color: #f7f8fa;
      .out-circle {
        background-color: #08587d;
      }
      .in-circle {
        background-color: #fff;
        svg {
          transform: rotateY(180deg);
          transition: 0.7s;
          fill: #08587d;
        }
      }
    } */
  }
`;

export const BoardWrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  background-color: #f9fafc;
`;

export const BoardContainer = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
`;
