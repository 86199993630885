import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { MobileView } from 'react-device-detect';
import Mobile from './pages/mobile/home/index';
import Greeting from './pages/mobile/company/Greeting';
import History from './pages/mobile/company/History';
import Organization from './pages/mobile/company/Organization';
import Cert from './pages/mobile/company/Cert';
import Address from './pages/mobile/company/Address';
import Business from './pages/mobile/business/Business';
import Web from './pages/mobile/business/Web';
import DeepLearning from './pages/mobile/business/DeepLearning';
import Ai from './pages/mobile/business/Ai';
import Develop from './pages/mobile/develop/Develop';
import Portfolio from './pages/mobile/portfolio/Portfolio';
import Notice from './pages/mobile/notice/Notice';
import NoticeCreate from './pages/mobile/notice/NoticeCreate';
import NoticeRead from './pages/mobile/notice/NoticeRead';
import NoticeUpdate from './pages/mobile/notice/NoticeUpdate';
import Login from './pages/mobile/login/Login';

function MobileDisplay() {
  return (
    <Router>
      <MobileView>
        <Switch>
          <Route exact path='/' component={Mobile} />
          <Route exact path='/greeting' component={Greeting} />
          <Route exact path='/history' component={History} />
          <Route exact path='/organization' component={Organization} />
          <Route exact path='/cert' component={Cert} />
          <Route exact path='/business' component={Business} />
          <Route exact path='/web' component={Web} />
          <Route exact path='/deeplearning' component={DeepLearning} />
          <Route exact path='/ai' component={Ai} />
          <Route exact path='/develop' component={Develop} />
          <Route exact path='/portfolio' component={Portfolio} />
          <Route exact path='/map' component={Address} />
          <Route exact path='/notice' component={Notice} />
          <Route exact path='/perform' component={Notice} />
          <Route exact path='/labnews' component={Notice} />
          <Route exact path='/media' component={Notice} />
          <Route exact path='/user/login' component={Login} />
          <Route exact path='/:category/create' component={NoticeCreate} />
          <Route exact path='/:category/:id' component={NoticeRead} />
          <Route
            exact
            path={'/:category/update/:id'}
            component={NoticeUpdate}
          />
        </Switch>
      </MobileView>
    </Router>
  );
}

export default React.memo(MobileDisplay);
