import styled from 'styled-components';
import { theme } from '@utils/theme';
import { pixelToRem, flexAlign } from '@utils/commonStyles';

export const BusinessContent = styled.section`
  line-height: 0;
  margin: 0 1rem;
  margin-bottom: 4rem;

  .content_bottom {
    ${flexAlign()}
    line-height: 1.4;
    font-weight: 600;
    font-size: 0.8rem;

    @media only all and (min-width: 630px) {
      font-size: ${pixelToRem(13)};
    }
  }

  @media only all and (max-width: 427px) {
    .content_bottom {
      ${flexAlign()}
      line-height: 1.4;
      font-weight: 600;
      font-size: ${pixelToRem(12)};
    }
  }
`;
export const BusinessBotContent = styled.section`
  background-color: ${theme.background.lightGray};
  ${flexAlign()}
  .bot-content a,
  div {
    margin: 2rem 0;
    ${flexAlign('column')}
    :last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: ${pixelToRem(16)};
    margin-bottom: 2rem;
  }
  img {
    width: 60%;
  }
  div {
    margin-bottom: 2rem;
  }
`;
