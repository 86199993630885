import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import ClipIcon from 'public/images/attachment.svg';
import axios from 'axios';
import { FileContainer, ReadContainer, ReadContent } from './styles';

function Read() {
  const history = useHistory();
  const [post, setPost] = useState('');
  const [fileList, setFileList] = useState([]);

  const onList = useCallback(() => {
    history.push(
      `/${history.location.pathname.split('/').filter((c) => c)[0]}`,
    );
  }, [history]);

  const showPost = useCallback(async () => {
    const result = await axios.get(
      `/post/${history.location.pathname.split('/').at(-1)}`,
    );
    if (result.data.status === 200) {
      setPost(result.data.data.post);

      if (result.data.data.files) {
        setFileList(result.data.data.files);
      }
    } else {
      throw new Error('');
    }
  }, [history]);

  const onDownload = useCallback(
    (e) => {
      const { dataset } = e.target;
      const { idx } = dataset;
      const { name, url } = fileList[idx];
      const aTag = document.createElement('a');
      aTag.href = url;
      aTag.download = name;
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    },
    [fileList],
  );

  useEffect(() => {
    showPost();
  }, []);

  return (
    <ReadContainer>
      <div className={'read-header'}>
        <div>{`${post?.title}`}</div>
        <div>{`${new Date(post?.createdAt)
          .toLocaleDateString('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          .replace(/\s+/g, '')
          .slice(0, -1)}`}</div>
      </div>
      <ReadContent>
        {fileList && fileList.length > 0 ? (
          <FileContainer>
            <div className={'addedFile'}>
              <span>{'첨부파일'}</span>
              <div className={'file-icon'}>
                <ClipIcon />
              </div>
            </div>
            <div>
              {fileList.map((file, index) => (
                <button
                  className={'file-items'}
                  onClick={onDownload}
                  data-idx={index}
                  key={`file-${file.id}`}>
                  {file.name}
                </button>
              ))}
            </div>
          </FileContainer>
        ) : null}
        <div dangerouslySetInnerHTML={{ __html: post?.contents || '' }}></div>
      </ReadContent>
      <div className={'read-footer'}>
        <button type='button' onClick={onList}>
          목록보기
        </button>
      </div>
    </ReadContainer>
  );
}

export default React.memo(Read);
