import React from 'react';
import styled from 'styled-components';
import CloseIcon from 'public/images/close.svg';

function Modal({
  modalHidden,
  onHidden,
  title = '알림',
  onClick = () => {},
  children,
  submitText = '확인',
  cancelText = '닫기',
  isExistFirstButton = true,
  isExistSecondButton = true,
  flex = 0.5,
  titleSize = 30,
  titleBold = 900,
}) {
  return (
    <ModalContainer hidden={modalHidden}>
      <ModalWrapper flex={flex}>
        <ModalHeader titleSize={titleSize} titleBold={titleBold}>
          <h3>{title}</h3>
          <button type='button' onClick={onHidden}>
            <CloseIcon />
          </button>
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
        <ModalFooter>
          {isExistFirstButton ? (
            <button type='button' onClick={onClick}>
              {submitText}
            </button>
          ) : null}
          {isExistSecondButton ? (
            <button type='button' onClick={onHidden}>
              {cancelText}
            </button>
          ) : null}
        </ModalFooter>
      </ModalWrapper>
    </ModalContainer>
  );
}

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.39);
  display: ${(props) => (props.hidden ? 'flex' : 'none')};
`;

export const ModalWrapper = styled.div`
  background-color: #fff;
  flex: ${(props) => (props.flex ? props.flex : 0.5)};
  border-radius: 7px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
  box-sizing: border-box;
  border-bottom: 1px solid #707070;
  h3 {
    color: #000;
    margin: 0;
    font-size: ${(props) => `${props.titleSize}px` || '30px'};
    font-weight: ${(props) => props.titleBold || 900};
  }
  button {
    cursor: pointer;
    outline: none;
    border: none;
    box-sizing: border-box;
    padding: 0;
    background-color: #fff;
    width: 2rem;
    height: 2rem;
    svg {
      fill: #707070;
      transform: scale(1.333);
      margin: 0;
    }
  }
`;

export const ModalContent = styled.div`
  background-color: #fff;
  padding: 32px;
  .flexable {
    display: flex;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-top: 1px solid #707070;
  padding: 1rem;
  flex: 1;
  button {
    cursor: pointer;
    outline: none;
    border: none;
    box-sizing: border-box;
    width: 8.3125rem;
    height: 2.5rem;
  }
  button:last-child {
    background-color: #fff;
    color: #08587d;
    border: 1px solid #08587d;
    margin-left: 0.5rem;
  }
  button:first-child {
    background-color: #08587d;
    color: #fff;
    margin-right: 0.5rem;
  }
`;

export default React.memo(Modal);
