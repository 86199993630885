import React, { useEffect, useState } from 'react';
import arrowLeft from 'public/images/arrowLeft.png';
import arrowRight from 'public/images/arrowRight.png';
import arrowDoubleLeft from 'public/images/arrowDoubleLeft.png';
import arrowDoubleRight from 'public/images/arrowDoubleRight.png';
import styled from 'styled-components';
import { theme } from '../../utils/theme';

const Pagination = ({ setPage, page, totalCount, isExistPermission }) => {
  const [nums, setNums] = useState(
    new Array(totalCount).fill(0).map((v, i) => i + 1),
  );

  useEffect(() => {
    const len = Math.ceil(totalCount / 50);
    if (len > 0) {
      const array = new Array(len).fill(0).map((v, i) => i + 1);
      setNums(array);
    } else {
      setNums([]);
    }
  }, [totalCount]);

  const onPage = (n) => () => {
    setPage(n);
  };

  const onFullPrevPage = () => {
    setPage(1);
  };

  const onPrevPage = () => {
    if (page - 5 < 1) {
      setPage(1);
      return;
    }
    setPage(page - 5);
  };

  const onNextPage = () => {
    if (page + 5 > Math.ceil(totalCount / 50)) {
      setPage(Math.ceil(totalCount / 50));
      return;
    }
    setPage(page + 5);
  };

  const onFullNextPage = () => {
    setPage(Math.ceil(totalCount / 50));
  };

  return (
    <PaginationContainer isExistPermission={isExistPermission}>
      <div
        role='presentation'
        className='arrow'
        onClick={onFullPrevPage}
        onKeyUp={() => {}}>
        <img src={arrowDoubleLeft} alt={'first page'} />
      </div>
      <div
        className='arrow'
        role='presentation'
        onClick={onPrevPage}
        onKeyUp={() => {}}>
        <img src={arrowLeft} alt={'prev page'} />
      </div>
      {page % 5 !== 0
        ? nums
            .slice(Math.floor(page / 5) * 5, Math.floor(page / 5) * 5 + 5)
            .map((v) => (
              <PageButton
                isMatch={page === v}
                role='presentation'
                className='number'
                key={v}
                onClick={onPage(v)}
                onKeyUp={() => {}}>
                {v}
              </PageButton>
            ))
        : nums
            .slice(Math.floor(page / 5) * 5 - 5, Math.floor(page / 5) * 5)
            .map((v) => (
              <PageButton
                isMatch={page === v}
                role='presentation'
                className='number'
                key={v}
                onClick={onPage(v)}
                onKeyUp={() => {}}>
                {v}
              </PageButton>
            ))}
      <div
        className='arrow'
        role='presentation'
        onClick={onNextPage}
        onKeyUp={() => {}}>
        <img src={arrowRight} alt={'next page'} />
      </div>
      <div
        className='arrow'
        role='presentation'
        onClick={onFullNextPage}
        onKeyUp={() => {}}>
        <img src={arrowDoubleRight} alt={'last page'} />
      </div>
    </PaginationContainer>
  );
};

export const PaginationContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.isExistPermission ? '133px' : 0)};
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    margin: 0 5px;
  }
  .arrow,
  .number {
    box-sizing: border-box;
    cursor: pointer;
  }
  .arrow:hover,
  .number:hover {
  }
  .number {
  }
  .selected {
    /* border: 1px solid #08587d; */
    color: ${theme.color.baseColor};
  }
`;

export const PageButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin: 0 5px;
  color: ${(props) => (props.isMatch ? '#08587d' : '#000')};
`;

export default React.memo(Pagination);
