import React from 'react';
import styled from 'styled-components';

import { MobilegreetingText } from '@statics/text';

import { theme } from '@utils/theme';
import { flexAlign } from '@utils/commonStyles';

function GreetingSection() {
  return (
    <>
      <GreetingSectionContent>
        <div className='greeting_content_bold'>
          <p>빠르게 발전하고 있는 Smart한 IT 문화</p>
          <div className='greeting_content_low'>
            <p>
              <span className='color_content'> (주)카라멜라</span>가 여러분과
              함께 하겠습니다
            </p>
          </div>
        </div>

        {MobilegreetingText.map((text) => (
          <p className='greeting_content_bottom' key={text}>
            {text}
          </p>
        ))}
      </GreetingSectionContent>
    </>
  );
}

const GreetingSectionContent = styled.section`
  line-height: 0;
  margin-bottom: 3rem;
  @media only all and (min-width: 1024px) {
    margin-bottom: 16rem;
  }
  .greeting_content_bold {
    margin-bottom: 2rem;
  }
  .greeting_content_bold > p {
    font-size: 20px;
    ${flexAlign()}
    font-weight: 700;
    @media only all and (min-width: 1024px) {
      font-size: 25px;
    }
  }
  .greeting_content_low {
    ${flexAlign()}
    font-size: 18px;
    font-weight: 500;
    margin-top: -0.3rem;
    .color_content {
      color: ${theme.color.baseColor};
    }
    @media only all and (min-width: 1024px) {
      margin-bottom: 5rem;
    }
  }
  .greeting_content_bottom {
    ${flexAlign()}
    line-height: 1.4;
    font-weight: 600;
    font-size: 0.7rem;
    @media only all and (min-width: 1024px) {
      font-size: 1rem;
    }
  }

  @media only all and (max-width: 428px) {
    .greeting_content_bold > p {
      font-size: 18px;
      font-weight: 700;
    }
    .greeting_content_low {
      font-size: 16px;
      font-weight: 500;
      margin-top: -0.3rem;
      .color_content {
        color: #08587d;
      }
    }
    .greeting_content_bottom {
      line-height: 1.4;
      font-weight: 600;
      font-size: 10px;
    }
  }
`;
export default React.memo(GreetingSection);
