import React from 'react';
import styled from 'styled-components';

import {
  businessMapper,
  businessServiceText,
  businessServiceItem,
} from '@statics/texts/business';
import { theme } from '@utils/theme';
import { flexAlign, pixelToRem } from '@utils/commonStyles';

import SVGFactory from '../SVGFactory';
import VerticalBar from '../VerticalBar';

function BusinessContent() {
  return (
    <BusinessContentContainer>
      <OurBusinessContainer>
        {businessMapper.map((value) => (
          <div className='intro-square' key={value.mainContent}>
            <div className='icon-section'>
              <SVGFactory Icon={value.img} />
            </div>
            <div className='intro-content'>
              <h3 className='main-content'>{value.mainContent}</h3>
              <p className='sub-content'>
                {value.subContent[0]}
                <br />
                {value.subContent[1]}
                <br />
                {value.subContent[2]}
              </p>
            </div>
          </div>
        ))}
      </OurBusinessContainer>
      <SoftwareBaseServiceContainer>
        <div className='service-header'>
          <h2>{'SaaS(Software as a Service) 전문 기업'}</h2>
          <VerticalBar
            width={133}
            height={3}
            bgColor={theme.color.baseColor}
            marginTop={10}
            marginBottom={0}
          />
        </div>
        <div className='service-section'>
          {businessServiceText.map((value) => (
            <p key={value}>{value}</p>
          ))}
        </div>
        <div className='service-circle-list'>
          {businessServiceItem.map((value) => (
            <div className='service-circle-item' key={value}>
              <div className='circle1'>{value}</div>
            </div>
          ))}
        </div>
      </SoftwareBaseServiceContainer>
    </BusinessContentContainer>
  );
}

export const BusinessContentContainer = styled.article`
  ${flexAlign('column', undefined, 'flex-start', 'stretch')};
`;

export const OurBusinessContainer = styled.section`
  ${flexAlign(undefined, undefined, 'space-between', 'stretch')};
  .intro-square {
    border: 1px solid ${theme.border.gray};
    border-top: 10px solid ${theme.color.baseColor};
    padding: ${pixelToRem(50)} ${pixelToRem(80)};
    box-sizing: border-box;
    ${flexAlign('column')};
    cursor: pointer;
    margin-bottom: ${pixelToRem(100)};

    .icon-section {
      ${flexAlign()};
      background-color: ${theme.background.lightGray};
      border-radius: 50%;
      width: ${pixelToRem(170)};
      height: ${pixelToRem(170)};
      margin-bottom: ${pixelToRem(10)};
      svg {
        transform: scale(3.333);
        fill: ${theme.color.baseColor};
      }
    }

    .main-content {
      font-weight: 900;
      font-size: ${pixelToRem(20)};
      color: ${theme.color.black};
      text-align: center;
    }

    .sub-content {
      text-align: center;
      line-height: 1.5;
      p {
        margin: 0;
        text-align: center;
        line-height: 1.5;
      }
    }

    &:hover {
      .icon-section {
        svg {
          transform: scale(3.333) rotateY(180deg);
          transition: 0.7s;
          fill: ${theme.color.gray707070};
        }
      }
    }
  }
`;

export const SoftwareBaseServiceContainer = styled.section`
  background-color: ${theme.background.lightGray};
  padding-top: ${pixelToRem(50)};
  padding-bottom: ${pixelToRem(50)};

  .service-header {
    ${flexAlign('column', undefined, 'flex-start')};
    h2 {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .service-section {
    color: ${theme.color.gray707070};
    font-size: ${pixelToRem(20)};
    margin-top: ${pixelToRem(50)};
    margin-bottom: ${pixelToRem(50)};
    ${flexAlign('column', undefined, 'stretch')};
    p {
      margin: 0;
      line-height: 1.5;
    }
  }

  .service-circle-list {
    ${flexAlign(undefined, undefined, 'space-evenly')};
    flex: 1;
    .service-circle-item {
      .circle1 {
        ${flexAlign('column')};
        flex: 1;
        font-weight: 600;
        color: ${theme.color.baseColor};
        font-size: ${pixelToRem(22)};
        border-radius: 50%;
        background-color: ${theme.background.white};
        width: ${pixelToRem(230)};
        min-height: ${pixelToRem(230)};
      }
    }
  }
`;

export default React.memo(BusinessContent);
