import React from 'react';
import styled from 'styled-components';
import OrganImg from 'public/images/organization.png';

function OrganizationSection() {
  return (
    <>
      <OrganSectionContent>
        <img src={OrganImg} alt='Organization' width='95%' />
      </OrganSectionContent>
    </>
  );
}

const OrganSectionContent = styled.section`
  display: flex;
  justify-content: center;
  /* border: 1px solid #cccccc; */
  padding: 30px 0;
  margin: 0 0.3rem;
  margin-bottom: 5rem;
`;
export default React.memo(OrganizationSection);
