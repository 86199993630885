import React from 'react';
import styled from 'styled-components';

import CopyImg from 'public/images/img.png';
import MapImg from 'public/images/map2.png';

import { theme } from '@utils/theme';
import { flexAlign, pixelToRem } from '@utils/commonStyles';

const address = '광주광역시 동구 금남로 193-12, 702호';

function AddressSection() {
  const onCopy = async () => {
    navigator.clipboard.writeText(address).then(() => {
      // eslint-disable-next-line no-alert
      alert('주소가 복사되었습니다!');
    });
  };
  return (
    <>
      <AddressSectionContent>
        <div className='address-text' role='presentation' onClick={onCopy}>
          <button></button>
          <p>{'광주광역시 동구 금남로 193-12, 702호'}</p>
        </div>
        <img src={MapImg} alt='Address' width='100%' />
      </AddressSectionContent>
    </>
  );
}

const AddressSectionContent = styled.section`
  margin: 0 0.8rem;
  margin-bottom: 5rem;
  .address-text {
    ${flexAlign}
    align-items: center;
  }
  button {
    outline: 0;
    width: ${pixelToRem(40)};
    height: ${pixelToRem(40)};
    border: 0;
    background-image: url(${CopyImg});
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${theme.background.white};
  }
  p {
    margin: 0;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
  }
  img {
    margin-top: ${pixelToRem(10)};
  }
`;
export default React.memo(AddressSection);
