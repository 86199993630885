import React from 'react';
import Common from '../../../layout/mobile/Common';
import CertSection from '../../../components/mobile/company/CertSection';

function Cert() {
  return (
    <>
      <Common>
        <CertSection />
      </Common>
    </>
  );
}
export default React.memo(Cert);
