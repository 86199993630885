import React from 'react';
import Common from '../../../layout/desktop/Common';
import PortfolioGrid from '../../../components/desktop/portfolio/PortfolioGrid';

function Portfolio() {
  return (
    <Common>
      <PortfolioGrid />
    </Common>
  );
}

export default React.memo(Portfolio);
