import React, { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router';
import styled from 'styled-components';

import commonBanner from 'public/images/commonBanner.jpeg';

import { header, subMenu } from '@statics/menu';

import Footer from '../../components/mobile/Footer';
import Header from '../../components/mobile/Header';
import VerticalBar from '../../components/desktop/VerticalBar';

function Common({ children, isExistBar = true, isExistSectionHeader = true }) {
  const location = useLocation();
  const history = useHistory();
  const onClick = useCallback(
    (path) => {
      history.push(path);
    },
    [history],
  );
  const [active, isActive] = React.useState(false);
  const setIsActive = () => isActive(!active);

  return (
    <>
      <Header />
      <TopBanner>
        <div className='banner-title'>
          <h2>{`${
            header.find((k) => {
              const obj = Object.entries(subMenu).find(([, arr]) =>
                arr.find((v) => location.pathname.startsWith(v.path)),
              )[0];
              return k.key === obj;
            })?.text || '회사소개'
          }`}</h2>
          <p>{`${
            header.find(
              (k) =>
                k.key ===
                Object.entries(subMenu).find(([, arr]) =>
                  arr.find((v) => location.pathname.startsWith(v.path)),
                )[0],
            )?.text || '회사소개'
          } ${String.fromCharCode(62)} ${
            Object.values(subMenu)
              .flat()
              .find((arr) => arr.path === location.pathname)?.title || '인사말'
          }`}</p>
        </div>
      </TopBanner>
      <TopTabNavigator active={active} onClick={setIsActive}>
        {Object.values(subMenu)
          .map((x) =>
            x
              .map((k) => location.pathname.startsWith(k.path))
              .some((z) => z === true),
          )
          .map((p, q) => {
            if (p === true) return q;
            return undefined;
          })
          .filter((x) => x !== undefined)
          .map((k) => Object.values(subMenu)[k])
          .flat()
          .map((element) => (
            <TopTabButton
              type='button'
              onClick={() => onClick(element.path)}
              key={element.title}
              isSelected={location.pathname.startsWith(element.path)}>
              {element.title}
            </TopTabButton>
          ))}
      </TopTabNavigator>
      <ChildrenContainer>
        {isExistSectionHeader ? (
          <div className='section-header'>
            <h1>
              {
                Object.values(subMenu)
                  .flat()
                  .find((v) =>
                    v.path.startsWith(
                      `/${location.pathname.split('/').filter((c) => c)[0]}`,
                    ),
                  )?.title
              }
            </h1>
            <h3>
              {
                Object.values(subMenu)
                  .flat()
                  .find((v) =>
                    v.path.startsWith(
                      `/${location.pathname.split('/').filter((c) => c)[0]}`,
                    ),
                  )?.subTitle
              }
            </h3>
          </div>
        ) : null}
        {isExistBar ? <VerticalBar width={1} height={40} /> : null}
        {children}
      </ChildrenContainer>
      <Footer />
    </>
  );
}

export const TopBanner = styled.div`
  background-image: url(${commonBanner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  @media only all and (min-width: 630px) {
    height: 10rem;
  }
  @media only all and (min-width: 1024px) {
    height: 13rem;
  }
  .banner-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 17px;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    @media only all and (min-width: 630px) {
      font-size: 25px;
    }
  }
  p {
    font-size: 10px;
    margin: 0;
    font-weight: 600;
    @media only all and (min-width: 630px) {
      font-size: 13px;
    }
  }
`;

export const TopTabNavigator = styled.div`
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  @media only all and (min-width: 1024px) {
    height: 3rem;
  }
`;

export const TopTabButton = styled.button`
  & {
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0 10px;
    font-size: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #707070;
    font-weight: 600;
    position: relative;
    height: 100%;
    @media only all and (min-width: 1024px) {
      font-size: 14px;
    }

    &::before {
      position: absolute;
      bottom: 0;
      content: '';
      width: 100%;
      height: 3px;
      box-sizing: border-box;
      background-color: #08587d;
      display: ${(props) => (props.isSelected ? 'block' : 'none')};
    }
    &:hover {
      color: #08587d;
      font-weight: 900;

      &:hover::before {
        position: absolute;
        bottom: 0;
        content: '';
        width: 100%;
        height: 5px;
        background-color: #08587d;
      }
    }
  }
`;

export const ChildrenContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .section-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 30px;
      margin: 0;
      margin-top: 2rem;
      font-weight: 400;
    }
    h3 {
      font-size: 17px;
      font-weight: 100;
      margin-top: 1rem;
      margin-bottom: -0.1rem;
    }
  }
  @media only all and (max-width: 428px) {
    .section-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1 {
        font-size: 25px;
        margin: 0;
        margin-top: 2rem;
        font-weight: 400;
      }
      h3 {
        font-size: 14px;
        font-weight: 100;
        margin-top: 1rem;
        margin-bottom: -0.1rem;
      }
    }
  }
`;

export default React.memo(Common);
