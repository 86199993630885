import styled from 'styled-components';

const VerticalBar = styled.div`
  width: ${(props) => `${props.width}px` || `${1}px`};
  height: ${(props) => `${props.height}px` || `${50}px`};
  background-color: ${(props) => props.bgColor || '#707070'};
  margin-top: ${(props) =>
    typeof props.marginTop === 'number' ? `${props.marginTop}px` : '30px'};
  margin-bottom: ${(props) =>
    typeof props.marginBottom === 'number'
      ? `${props.marginBottom}px`
      : '30px'};
  align-self: center;
`;

export default VerticalBar;
