import React from 'react';

import MetUWeb from 'public/images/business/mobile/metuweb.png';
import SelfWeb from 'public/images/business/mobile/selfweb.png';

import { deepLearningAIText } from '@statics/text';

import { BusinessContent, BusinessBotContent } from './style';

function DeepSection() {
  return (
    <>
      <BusinessContent>
        {deepLearningAIText.map((text) => (
          <div className='content_bottom' key={text}>
            <div>
              <br />
              {text}
            </div>
          </div>
        ))}
      </BusinessContent>
      <BusinessBotContent>
        <div className='bot-content'>
          <a href='https://metu.ai/' target='_blank' rel='noopener noreferrer'>
            <h1>METU (미투)</h1>
            <img src={MetUWeb} alt='METU' />
          </a>
          <a
            href='https://caramella.kr'
            target='_blank'
            rel='noopener noreferrer'>
            <h1>Selfietoy (셀피토이)</h1>
            <img src={SelfWeb} alt='SELF' />
          </a>
          <div></div>
        </div>
      </BusinessBotContent>
    </>
  );
}

export default React.memo(DeepSection);
