import { css } from 'styled-components';

export const flexAlign = (
  dir = 'row',
  display = 'flex',
  justify = 'center',
  align = 'center',
) => css`
  display: ${display};
  flex-direction: ${dir};
  justify-content: ${justify};
  align-items: ${align};
`;

export const pixelToRem = (pixel = 16) => `${pixel / 16}rem`;
