import { useCallback, useState } from 'react';

export default function useText() {
  const [HTMLText, setHTMLText] = useState('');
  const [noHTMLText, setNoHTMLText] = useState();

  const onChange = useCallback((content, delta, source, editor) => {
    const text = editor.getText();
    setHTMLText(content);
    setNoHTMLText(text);
  }, []);

  return [HTMLText, onChange, noHTMLText, setHTMLText];
}
