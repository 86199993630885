import styled from 'styled-components';
import { theme } from '../../../utils/theme';
import { flexAlign, pixelToRem } from '../../../utils/commonStyles';

export const Contents = styled.div`
  padding: 0 10%;
  ${flexAlign('column', undefined, undefined, undefined)};

  .image-container {
    flex: 0.3;
  }
  // img 50%
  img.percent50 {
    width: 50%;
  }
  .explain {
    margin-top: ${pixelToRem(50)};
  }
  .text-bold {
    font-size: ${pixelToRem(25)};
    color: ${theme.color.black};
    font-weight: 600;
    text-align: center;
    margin-top: 0;
    margin-bottom: ${pixelToRem(10)};
  }
  .text-baseColor {
    font-weight: 900;
    color: ${theme.color.baseColor};
  }
  .text-size-20 {
    text-align: center;
    font-size: ${pixelToRem(20)};
  }
  .bottom-space {
    margin-bottom: ${pixelToRem(80)};
  }

  .scalable {
    @keyframes scalable {
      0% {
        transform: scaleX(1);
      }
      50% {
        transform: scale3d(1.05, 1.05, 1.05);
      }
      100% {
        transform: scaleX(1);
      }
    }
    animation-name: scalable;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }
`;

export const ImageLinkContainer = styled.div`
  margin-top: ${pixelToRem(100)};
  ${flexAlign(undefined, undefined, undefined, 'stretch')};
`;

export const ImageLink = styled.a`
  text-decoration: none;
  color: ${theme.color.black};
  max-width: 33%;
  margin-right: ${pixelToRem(30)};
  &:last-child {
    margin: 0;
  }
  ${flexAlign('column')}

  &:visited,
  &:hover,
  &:active {
    color: ${theme.color.black};
  }

  p {
    margin: 0;
    margin-bottom: 3rem;
    font-size: ${pixelToRem(30)};
    font-weight: 600;
  }

  img {
    width: 100%;
  }
`;
