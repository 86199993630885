import React, { useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useHistory } from 'react-router';
import backgroundImage from 'public/images/footer.jpg';
import Header from '../../../components/desktop/Header';
import Footer from '../../../components/desktop/Footer';
import useChangeString from '../../../hooks/useChangeString';

function Login() {
  const [id, onChangeId] = useChangeString();
  const [password, onChangePassword] = useChangeString();
  const history = useHistory();
  const [isError, setIsError] = React.useState(false);

  const onLogin = useCallback(async () => {
    try {
      if (!id.trim() || !password.trim()) {
        return;
      }
      setIsError(false);
      const result = await axios.post('/user', {
        email: id,
        password,
      });
      if (result.data.status === 200) {
        const { token } = result.data.data;
        sessionStorage.setItem('token', token);
        history.push('/');
      } else {
        throw new Error('failed to login!');
      }
    } catch (err) {
      setIsError(true);
    }
  }, [id, password]);

  const onLoginKey = useCallback(
    async (e) => {
      try {
        if (e.key === 'Enter') {
          if (!id.trim() || !password.trim()) {
            return;
          }
          setIsError(false);
          const result = await axios.post('/user', {
            email: id,
            password,
          });
          if (result.data.status === 200) {
            const { token } = result.data.data;
            sessionStorage.setItem('token', token);
            history.push('/');
          }
        } else {
          throw new Error('failed to login!');
        }
      } catch (err) {
        setIsError(true);
      }
    },
    [id, password],
  );

  return (
    <LoginContainer>
      <Header isExistMenu={false} />
      <LoginBackground>
        <LoginAlphaChannel>
          <div className={'group'}>
            <span>{'아이디 (ID)'}</span>
            <input value={id} onChange={onChangeId} />
          </div>
          <div className={'group'}>
            <span>{'비밀번호 (PW)'}</span>
            <input
              type='password'
              value={password}
              onChange={onChangePassword}
              onKeyUp={onLoginKey}
            />
          </div>
          <div className='group'>
            <span className={'error-message'}>
              {isError
                ? '아이디와 비밀번호를 다시 확인해주세요'
                : '\u0020'.normalize()}
            </span>
            <button type='button' onClick={onLogin}>
              {'로그인'}
            </button>
          </div>
        </LoginAlphaChannel>
      </LoginBackground>
      <Footer />
    </LoginContainer>
  );
}

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const LoginBackground = styled.div`
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: calc(100% - 180px);
  display: flex;
`;

export const LoginAlphaChannel = styled.div`
  background-color: rgba(0, 0, 0, 0.52);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  input,
  button {
    width: 340px;
    height: 45px;
    outline: none;
    font-size: 17px;
    box-sizing: border-box;
  }

  input {
    border: 1px solid #707070;
    padding-left: 16px;
  }

  input[type='password'] {
    font-family: auto;
  }

  button {
    background-color: #08587d;
    color: #ffffff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    span {
      color: #fff;
      margin-bottom: 5px;
    }

    .error-message {
      color: #f00;
    }
  }
`;

export default React.memo(Login);
