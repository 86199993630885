import React from 'react';
import Common from '../../../layout/desktop/Common';
import HistoryContent from '../../../components/desktop/company/HistoryContent';

function HistoryContainer() {
  return (
    <Common>
      <HistoryContent />
    </Common>
  );
}

export default React.memo(HistoryContainer);
