import React from 'react';
import { useLocation, Redirect } from 'react-router';

// import { NoticeProvider } from '@contexts/Notice';

import Common from '../../../layout/mobile/Common';
import NoticeSection from '../../../components/mobile/notice/NoticeSection';

const paths = ['notice', 'perform', 'labnews', 'media'];

function Notice() {
  const location = useLocation();

  if (!paths.includes(location.pathname.split('/').filter((c) => c)[0])) {
    return <Redirect to={'/'} />;
  }

  return (
    <>
      <Common>
        <NoticeSection />
      </Common>
    </>
  );
}
export default React.memo(Notice);
