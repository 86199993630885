import React from 'react';
import PortfolioSection from '../../../components/mobile/portfolio/PortfolioSection';
import Common from '../../../layout/mobile/Common';

function Portfolio() {
  return (
    <>
      <Common>
        <PortfolioSection />
      </Common>
    </>
  );
}
export default React.memo(Portfolio);
