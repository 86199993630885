import styled from 'styled-components';

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 50px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin-right: 100px;
  margin-left: 100px;

  .input-field {
    display: flex;
    height: 40px;
    margin-bottom: 40px;

    &:last-child {
      margin-top: 16px;
    }

    .left-field {
      width: 150px;
      display: flex;
      align-items: center;
      color: #000000;
      font-size: 20px;
      font-weight: 600;
      margin-right: 20px;

      .required {
        color: #f00;
        margin-right: 0.5rem;
      }
    }

    .right-field {
      flex: 8;
      box-sizing: border-box;
      border: 1px solid #ccc;
      background-color: #fff;
      color: #000;
      font-size: 17px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      outline: none;

      &::placeholder {
        color: #707070;
      }
    }

    .label-field {
      flex: 8;
      display: flex;
      flex-direction: column;
      .label-container {
        display: flex;

        .no-func {
          flex: 1;
          background-color: #fff;
          box-sizing: border-box;
          border: 1px solid #ccc;
          height: 40px;
          margin-right: 16px;
          display: flex;
          align-items: center;
          padding-left: 16px;
          padding-right: 16px;
          span {
            margin-right: 5px;
          }
        }
      }

      .additional {
        margin-top: 10px;
        color: #707070;
        font-size: 17px;
      }

      .upload-label {
        height: 40px;
        width: 133px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #08587d;
        outline: none;
        cursor: pointer;
        border: none;
        font-size: 17px;
        margin-right: 16px;
      }
    }
  }
  .textarea-field {
    display: flex;
    margin-bottom: 40px;
    .left-field {
      width: 150px;
      display: flex;
      color: #000000;
      font-weight: 600;
      font-size: 20px;
      margin-right: 20px;
      .required {
        color: #f00;
        margin-right: 0.5rem;
      }
    }
    .right-field {
      flex: 8;
      outline: none;
      padding-left: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-right: 16px;
      resize: none;
      border: 1px solid #ccc;
    }
  }
`;

export const SubmitButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  button {
    outline: none;
    border: none;
    font-size: 17px;
    background-color: #08587d;
    color: #fff;
    height: 40px;
    width: 133px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:first-child {
      margin-right: 30px;
    }

    &:last-child {
      border: 1px solid #08587d;
      background-color: #fff;
      color: #08587d;
    }
  }
`;
