import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import arrowLeft from 'public/images/arrowLeft.png';
import arrowRight from 'public/images/arrowRight.png';
import arrowDoubleLeft from 'public/images/arrowDoubleLeft.png';
import arrowDoubleRight from 'public/images/arrowDoubleRight.png';

import { theme } from '@utils/theme';
import { flexAlign, pixelToRem } from '@utils/commonStyles';

const Pagination = ({ setPage, page, totalCount }) => {
  const [nums, setNums] = useState(
    new Array(totalCount).fill(0).map((v, i) => i + 1),
  );

  useEffect(() => {
    const len = Math.ceil(totalCount / 10);
    if (len > 0) {
      const array = new Array(len).fill(0).map((v, i) => i + 1);
      setNums(array);
    } else {
      setNums([]);
    }
  }, [totalCount]);

  const onPage = (n) => () => {
    setPage(n);
  };

  const onFullPrevPage = () => {
    const minPage = 1;
    setPage(minPage);
  };

  const onPrevPage = () => {
    // const maxPage = Math.ceil(totalCount / 10);
    const minPage = 1;

    if (page === nums.at(0) && page - 1 >= minPage) {
      setPage((f) => f - 1);
      return;
    }

    if (page !== nums.at(0)) {
      if (page - 5 >= minPage) {
        setPage((f) => f - 5);
      } else {
        setPage(minPage);
      }
    }
  };

  const onNextPage = () => {
    const maxPage = Math.ceil(totalCount / 10);

    if (page === nums.at(-1) && page + 1 <= maxPage) {
      setPage((f) => f + 1);
      return;
    }

    if (page !== nums.at(-1)) {
      if (page + 5 > maxPage) {
        setPage(maxPage);
        return;
      }
      setPage(page + 5);
    }
  };

  const onFullNextPage = () => {
    const maxPage = Math.ceil(totalCount / 10);
    setPage(maxPage);
  };

  return (
    <PaginationContainer>
      <div
        role='presentation'
        className='arrow'
        onClick={onFullPrevPage}
        onKeyUp={() => {}}>
        <img src={arrowDoubleLeft} alt={'first page'} />
      </div>
      <div
        className='arrow'
        role='presentation'
        onClick={onPrevPage}
        onKeyUp={() => {}}>
        <img src={arrowLeft} alt={'prev page'} />
      </div>
      {page % 5 !== 0
        ? nums
            .slice(Math.floor(page / 5) * 5, Math.floor(page / 5) * 5 + 5)
            .map((v) => (
              <PageButton
                isMatch={page === v}
                role='presentation'
                className='number'
                key={v}
                onClick={onPage(v)}
                onKeyUp={() => {}}>
                {v}
              </PageButton>
            ))
        : nums
            .slice(Math.floor(page / 5) * 5 - 5, Math.floor(page / 5) * 5)
            .map((v) => (
              <PageButton
                isMatch={page === v}
                role='presentation'
                className='number'
                key={v}
                onClick={onPage(v)}
                onKeyUp={() => {}}>
                {v}
              </PageButton>
            ))}
      <div
        className='arrow'
        role='presentation'
        onClick={onNextPage}
        onKeyUp={() => {}}>
        <img src={arrowRight} alt={'next page'} />
      </div>
      <div
        className='arrow'
        role='presentation'
        onClick={onFullNextPage}
        onKeyUp={() => {}}>
        <img src={arrowDoubleRight} alt={'last page'} />
      </div>
    </PaginationContainer>
  );
};

export const PaginationContainer = styled.div`
  ${flexAlign()}
  margin: -2rem 0 3rem 0;

  div {
    ${flexAlign()}
    width: ${pixelToRem(28)};
    height: ${pixelToRem(28)};
    margin: 0 5px;
  }
  .arrow,
  .number {
    box-sizing: border-box;
    cursor: pointer;
  }
  .arrow:hover,
  .number:hover {
  }
  .number {
  }
  .selected {
    color: ${theme.color.baseColor};
  }
`;

export const PageButton = styled.div`
  ${flexAlign()}
  width: ${pixelToRem(28)};
  height: ${pixelToRem(28)};
  margin: 0 5px;
  color: ${(props) => (props.isMatch ? '#08587d' : '#000')};
`;

export default React.memo(Pagination);
