import axios from 'axios';

function checkMobile() {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    userAgent.includes('android') ||
    userAgent.includes('iphone') ||
    userAgent.includes('ipad')
  );
}

export const baseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://api.caramella.kr'
    : checkMobile()
    ? 'https://api.caramella.kr'
    : 'http://localhost:3000';

axios.defaults.baseURL = baseUrl;
