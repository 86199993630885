import React from 'react';
import Building from 'public/images/building.svg';
import Command from 'public/images/command.svg';
import Chat from 'public/images/chat.svg';
import { useHistory } from 'react-router';
import SVGFactory from './SVGFactory';
import { Contents } from '../../pages/desktop/home/styles';

function ContentContainer() {
  const history = useHistory();
  const onMove = React.useCallback(
    (url) => () => {
      history.push(url);
    },
    [history],
  );

  return (
    <Contents>
      <div className='company-letter'>
        <div className='line'></div>
        <p className='light'>WE ARE</p>
        <p>CARAMELLA</p>
      </div>
      <div className='intro' role='presentation' onClick={onMove('/greeting')}>
        {/* <div className='number'>01</div> */}
        <div>
          <div className='out-circle'>
            <div className='in-circle'>
              <div className='content-icon'>
                <SVGFactory Icon={Building} width={24} height={24} />
              </div>
            </div>
          </div>
        </div>
        <div className='title'>회사소개</div>
        <div className='desc'>{'(주)카라멜라는 IT융합기술을'}</div>
        <div className='desc'>{'바탕으로 성장하는 회사입니다'}</div>
        <div className='detail-link'>자세히보기 &gt;&gt;</div>
      </div>
      <div className='intro' role='presentation' onClick={onMove('/business')}>
        {/* <div className='number'>02</div> */}
        <div>
          <div className='out-circle'>
            <div className='in-circle'>
              <div className='content-icon'>
                <SVGFactory Icon={Command} width={24} height={24} />
              </div>
            </div>
          </div>
        </div>
        <div className='title'>사업분야</div>
        <div className='desc'>{'빅데이터를 활용한 앞선 기술로'}</div>
        <div className='desc'>{'S/W 서비스를 만들어 갑니다'}</div>
        <div className='detail-link'>자세히보기 &gt;&gt;</div>
      </div>
      <div className='intro' role='presentation' onClick={onMove('/develop')}>
        {/* <div className='number'>03</div> */}
        <div>
          <div className='out-circle'>
            <div className='in-circle'>
              <div className='content-icon'>
                <SVGFactory Icon={Chat} width={24} height={24} />
              </div>
            </div>
          </div>
        </div>
        <div className='title'>개발문의</div>
        <div className='desc'>{'고객님의 니즈에 맞게'}</div>
        <div className='desc'>{'최선을 다해 개발해드립니다'}</div>
        <div className='detail-link'>자세히보기 &gt;&gt;</div>
      </div>
    </Contents>
  );
}

export default React.memo(ContentContainer);
