export const header = [
  { key: 'company', text: '회사소개' },
  { key: 'branch', text: '사업분야' },
  { key: 'develop', text: '개발문의' },
  { key: 'portfolio', text: '포트폴리오' },
  { key: 'notice', text: '공지사항' },
];

export const subMenu = {
  company: [
    { path: '/greeting', title: '인사말', subTitle: 'Greeting' },
    { path: '/history', title: '연혁', subTitle: 'History' },
    { path: '/organization', title: '조직도', subTitle: 'Organization chart' },
    { path: '/cert', title: '기업 인증서', subTitle: 'Corporate certificate' },
    { path: '/map', title: '오시는 길', subTitle: 'Address' },
  ],
  branch: [
    { path: '/business', title: '사업 소개', subTitle: 'Our Business' },
    { path: '/web', title: '웹 서비스 S/W', subTitle: 'Web Service Software' },
    {
      path: '/deeplearning',
      title: '딥러닝 얼굴인식 S/W',
      subTitle: 'Deep Learning Facial Recognition Software',
    },
    {
      path: '/ai',
      title: 'AI 학습용 데이터 가공',
      subTitle: 'AI Learning Data Processing',
    },
  ],
  develop: [
    {
      path: '/develop',
      title: 'S/W 개발문의',
      subTitle: 'S/W Development Inquiry',
    },
  ],
  portfolio: [
    { path: '/portfolio', title: '포트폴리오', subTitle: 'Portfolio' },
  ],
  notice: [
    { path: '/notice', title: '공지사항', subTitle: 'Notice' },
    { path: '/perform', title: '사업실적', subTitle: 'Business Performance' },
    { path: '/labnews', title: '연구소 소식', subTitle: 'Lab news' },
    { path: '/media', title: '보도자료', subTitle: 'News' },
  ],
};

export const urlOfNoticeSubMenu = subMenu.notice.map((r) =>
  r.path.replace('/', ''),
);
