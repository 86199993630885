import React from 'react';
import mapImage from 'public/images/map2.png';
import MapPin from 'public/images/mapPin.svg';
import Common from '../../../layout/desktop/Common';
import {
  ContentAlign,
  AddressAlign,
} from '../../../components/desktop/company/styles';

const address = '광주광역시 동구 금남로 193-12, 702호';

function Address() {
  const onCopy = async () => {
    navigator.clipboard.writeText(address).then(() => {
      // eslint-disable-next-line no-alert
      alert('주소가 복사되었습니다!');
    });
  };

  return (
    <Common>
      <AddressAlign>
        <div className='clipboard' role='presentation' onClick={onCopy}>
          <MapPin />
          <p>{address}</p>
        </div>
      </AddressAlign>
      <ContentAlign>
        <img src={mapImage} alt='map' style={{ minWidth: '70%' }} />
      </ContentAlign>
    </Common>
  );
}

export default React.memo(Address);
