import React from 'react';
import styled from 'styled-components';

import { greetingText } from '@statics/text';

import { theme } from '@utils/theme';
import { flexAlign, pixelToRem } from '@utils/commonStyles';

function GreetingContent() {
  return (
    <GreetingContentContainer>
      <p className='content-letter-bold'>
        {'빠르게 발전하고 있는 Smart한 IT문화'}
      </p>
      <p className='content-letter-bold content-space'>
        <span className='content-baseColor'>{'(주)카라멜라'}</span>
        {'가 여러분과 함께 하겠습니다'}
      </p>
      {greetingText.map((text) => (
        <p className='content-letter-bottom' key={text}>
          {text}
        </p>
      ))}
    </GreetingContentContainer>
  );
}

export default React.memo(GreetingContent);

export const GreetingContentContainer = styled.div`
  ${flexAlign('column')};
  p {
    margin: 0;
    line-height: 2;
  }

  .content-letter-bold {
    font-weight: 900;
    font-size: ${pixelToRem(25)};
  }

  .content-baseColor {
    color: ${theme.color.baseColor};
  }

  .content-space {
    margin-bottom: ${pixelToRem(50)};
  }

  .content-letter-bottom {
    font-size: ${pixelToRem(20)};
    color: ${theme.color.black};
  }
`;
