import React from 'react';

import MollyWeb from 'public/images/business/mobile/dataTool.png';

import { AiLearningText } from '@statics/text';

import { BusinessContent, BusinessBotContent } from './style';

function AiSection() {
  return (
    <>
      <BusinessContent>
        {AiLearningText.map((text) => (
          <div className='content_bottom' key={text} item={text}>
            <div>
              <br />
              {text}
            </div>
          </div>
        ))}
      </BusinessContent>
      <BusinessBotContent>
        <div className='bot-content'>
          <div href='none' target='_blank' rel='noopener noreferrer'>
            <h1>데이터 라벨링 툴</h1>
            <img src={MollyWeb} alt='METU' />
          </div>

          <div></div>
        </div>
      </BusinessBotContent>
    </>
  );
}

export default React.memo(AiSection);
