import React from 'react';
import Common from '../../../layout/mobile/Common';
import DeepSection from '../../../components/mobile/business/DeepSection';

function DeepLearning() {
  return (
    <>
      <Common>
        <DeepSection />
      </Common>
    </>
  );
}
export default React.memo(DeepLearning);
