import React from 'react';
import { useParams, Redirect } from 'react-router';
import Common from '../../../layout/mobile/Common';
import Create from '../../../components/mobile/notice/Create';

const paths = ['notice', 'perform', 'labnews', 'media'];

function NoticeCreate() {
  const params = useParams();

  if (!paths.includes(params.category)) {
    return <Redirect to={'/'} />;
  }

  return (
    <Common>
      <Create />
    </Common>
  );
}

export default React.memo(NoticeCreate);
