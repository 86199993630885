import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import { IoIosClose } from 'react-icons/io';
import { GoGrabber } from 'react-icons/go';
import Logo from 'public/images/logo.png';
import { SidebarData } from './SideBarDatas';
import SubMenu from './SubTitle';
import { theme } from '../../utils/theme';
import { flexAlign, pixelToRem } from '../../utils/commonStyles';

function mobileHeader() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: '#000000' }}>
        <Nav>
          <GoGrabber className='hamberger' onClick={showSidebar} />
          <a href='/' className='logo'>
            <img src={Logo} alt={'Logo'} width='90%' />
          </a>
          <div className='empty'></div>
        </Nav>

        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon to='#'>
              <IoIosClose className='close-icon' onClick={showSidebar} />
            </NavIcon>
            {SidebarData.map((item, index) => (
              <SubMenu item={item} key={index}>
                {item.title}
              </SubMenu>
            ))}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
}

const Nav = styled.header`
  position: sticky;
  top: 0;
  background-color: ${theme.background.white};
  z-index: 10;
  height: 1.4rem;
  ${flexAlign(undefined, undefined, 'space-between')}

  padding: 0.5rem;
  .logo {
    width: 8.5rem;
    ${flexAlign()}
  }
  .empty {
    width: 2.3rem;
    height: 2.3rem;
  }

  .hamberger {
    width: 2.3rem;
    height: 2.3rem;
  }
`;

const NavIcon = styled(Link)`
  font-size: 3rem;
  ${flexAlign()}
  width: 2.5rem;
  height: 2.5rem;
  margin-left: ${pixelToRem(5)};
  margin-bottom: ${pixelToRem(40)}; ;
`;

const SidebarNav = styled.nav`
  background-color: rgba(255, 255, 255, 0.98);
  width: 35%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 550ms ease;
  -webkit-transition: 550ms ease;
  -moz-transition: 550ms ease;
  -o-transition: 550ms ease;
  z-index: 10;
  scrollbar-width: none;
  overflow-y: scroll;
  @media only all and (min-width: 630px) {
    width: 11rem;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

export default React.memo(mobileHeader);
