import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import Home from './pages/desktop/home/index';

import Organization from './pages/desktop/company/Organization';
import Greeting from './pages/desktop/company/Greeting';
import HistoryContainer from './pages/desktop/company/HistoryContainer';
import Cert from './pages/desktop/company/Cert';
import Address from './pages/desktop/company/Address';

import Business from './pages/desktop/business/Business';
import Web from './pages/desktop/business/Web';
import DeepLearning from './pages/desktop/business/DeepLearning';
import AI from './pages/desktop/business/AI';

import Develop from './pages/desktop/develop/Develop';

import Portfolio from './pages/desktop/portfolio/Portfolio';

import Notice from './pages/desktop/notice/Notice';
import NoticeRead from './pages/desktop/notice/NoticeRead';
import NoticeUpdate from './pages/desktop/notice/NoticeUpdate';
import NoticeCreate from './pages/desktop/notice/NoticeCreate';

import Redirect from './components/desktop/Redirect';
import Login from './pages/desktop/login/Login';

const DesktopGlobalStyles = createGlobalStyle`
  html, body, #root {
    width: 100%;
    height: 100%;
    min-width: 1280px;
  }
`;

function DesktopDisplay() {
  return (
    <>
      <DesktopGlobalStyles />
      <Router>
        <Switch>
          <Route exact path='/greeting' component={Greeting} />
          <Route exact path='/history' component={HistoryContainer} />
          <Route exact path='/organization' component={Organization} />
          <Route exact path='/cert' component={Cert} />
          <Route exact path='/map' component={Address} />
          <Route exact path='/business' component={Business} />
          <Route exact path='/web' component={Web} />
          <Route exact path='/deeplearning' component={DeepLearning} />
          <Route exact path='/ai' component={AI} />
          <Route exact path='/develop' component={Develop} />
          <Route exact path='/portfolio' component={Portfolio} />
          <Route exact path='/notice' component={Notice} />
          <Route exact path='/perform' component={Notice} />
          <Route exact path='/labnews' component={Notice} />
          <Route exact path='/media' component={Notice} />
          <Route exact path='/user/login' component={Login} />
          <Route exact path='/:category/create' component={NoticeCreate} />
          <Route exact path='/:category/:id' component={NoticeRead} />
          <Route
            exact
            path={'/:category/update/:id'}
            component={NoticeUpdate}
          />
          <Route exact path='/' component={Home} />
          <Route component={Redirect} />
        </Switch>
      </Router>
    </>
  );
}

export default React.memo(DesktopDisplay);
