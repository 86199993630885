import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import ReactQuill from 'react-quill';
import axios from 'axios';

import { subMenu } from '@statics/menu';
import { useQuill } from '@statics/quillModules';

import useText from '@hooks/useText';
import useChangeString from '@hooks/useChangeString';

import getFirstUrl from '@utils/getFirstUrl';
import testFiles from '@utils/testFiles';
import postTypeSwitch from '@utils/postTypeSwitch';

import { CreateContainer, Fields, FileTag } from './styles';
import './quill.snow.css';

function Create() {
  const [textEditor, onTextEditor, textContents] = useText('');
  const [title, onTitle] = useChangeString();
  const [author, onAuthor] = useChangeString();
  const [uploadedFileNames, setUploadedFileNames] = React.useState([]);
  const [uploadFiles, setUploadFiles] = React.useState([]);
  const location = useLocation();
  const history = useHistory();

  const { quillModules, QuillRef } = useQuill();

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onUpload = useCallback((e) => {
    if (testFiles(e)) {
      return;
    }

    Array.from(e.target.files).forEach((file) => {
      setUploadFiles((prev) => prev.concat(file));
      setUploadedFileNames((prev) => prev.concat(file.name));
    });
  }, []);

  const onRemove = useCallback((filename) => {
    setUploadedFileNames((prev) => prev.filter((v) => v !== filename));
    setUploadFiles((prev) => prev.filter((x) => x.name !== filename));
  }, []);

  const onCreate = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const token = sessionStorage.getItem('token');
        if (
          !title.trim() ||
          !author.trim() ||
          !textContents.trim().replace(/\s/g, '') ||
          !token
        ) {
          return;
        }

        const formData = new FormData();

        formData.append('title', title.trim());
        formData.append('contents', textEditor);

        formData.append(
          'postType',
          postTypeSwitch(getFirstUrl(location.pathname)),
        );

        if (uploadFiles.length > 0) {
          uploadFiles.forEach((uploadFile) => {
            formData.append(uploadFile.name, uploadFile);
          });
        }

        const result = await axios.post('/post', formData, {
          headers: { token, 'Content-Type': 'multipart/form-data' },
        });

        if (result.data.status === 200) {
          history.push(`/${getFirstUrl(location.pathname)}`);
        } else {
          throw new Error('error');
        }
      } catch (err) {
        // const b = 3;
      }
    },
    [location, title, author, textContents, textEditor, history, uploadFiles],
  );

  return (
    <CreateContainer>
      <form encType={'multipart/form-data'} onSubmit={onCreate}>
        <div className={'create-header'}>{`${
          Object.values(subMenu)
            .flat()
            .find((x) => location.pathname.startsWith(x.path))?.title
        } 글 등록`}</div>
        <Fields>
          <div className='input-field'>
            <div className='left-field'>제목</div>
            <input
              type='text'
              className='right-field'
              value={title}
              onChange={onTitle}
            />
          </div>
          <div className='input-field'>
            <div className='left-field'>작성자</div>
            <input
              type='text'
              className='right-field'
              value={author}
              onChange={onAuthor}
            />
          </div>
          <div className='textarea-field'>
            <div className='left-field'>내용</div>
            <ReactQuill
              ref={(element) => {
                if (element !== null) {
                  QuillRef.current = element;
                }
              }}
              modules={quillModules}
              theme='snow'
              value={textEditor}
              onChange={onTextEditor}
              className='right-field textarea'
            />
          </div>
          <div className='input-field'>
            <div className='left-field'>첨부파일</div>
            <div className='label-field'>
              <input
                hidden
                id='upload-files'
                type='file'
                multiple
                onChange={onUpload}
              />
              <label htmlFor='upload-files' className='upload-label'>
                파일선택
              </label>
              {uploadedFileNames.map((v) => (
                <FileTag key={v}>
                  <span>{v}</span>
                  <span
                    role='presentation'
                    className='cancel-imoji'
                    onClick={() => onRemove(v)}
                    onKeyUp={() => {}}>
                    {'x'}
                  </span>
                </FileTag>
              ))}
            </div>
          </div>
        </Fields>
        <div className={'create-footer'}>
          <button type='submit'>등록</button>
          <button type='button' onClick={onCancel}>
            닫기
          </button>
        </div>
      </form>
    </CreateContainer>
  );
}

export default React.memo(Create);
