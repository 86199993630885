import React from 'react';
import Common from '../../../layout/desktop/Common';
import BusinessContent from '../../../components/desktop/business/BusinessContent';

function Business() {
  return (
    <Common>
      <BusinessContent />
    </Common>
  );
}

export default React.memo(Business);
