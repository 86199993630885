import { useCallback, useState } from 'react';

export default function useChangeString(initialString = '') {
  const [str, setStr] = useState(initialString);

  const onStr = useCallback((e) => {
    setStr(e.target.value);
  }, []);

  return [str, onStr, setStr];
}
