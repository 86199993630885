import React from 'react';
import { useLocation, Redirect } from 'react-router';
import Common from '../../../layout/desktop/Common';
import List from '../../../components/desktop/notice/List';

const paths = ['notice', 'perform', 'labnews', 'media'];

function Notice() {
  const location = useLocation();

  if (!paths.includes(location.pathname.split('/').filter((c) => c)[0])) {
    return <Redirect to={'/'} />;
  }

  return (
    <Common>
      <List />
    </Common>
  );
}

export default React.memo(Notice);
