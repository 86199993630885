import React from 'react';
import Common from '../../../layout/mobile/Common';
import GreetingSection from '../../../components/mobile/company/GreetingSection';

function Greeting() {
  return (
    <>
      <Common>
        <GreetingSection />
      </Common>
    </>
  );
}

export default React.memo(Greeting);
