import React from 'react';
import Common from '../../../layout/mobile/Common';
import WebSection from '../../../components/mobile/business/WebSection';

function Web() {
  return (
    <>
      <Common>
        <WebSection />
      </Common>
    </>
  );
}
export default React.memo(Web);
