import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { subMenu } from '@statics/menu';

function Submenu({ isHover }) {
  const history = useHistory();
  return (
    <SubMenu isHover={isHover}>
      {Object.keys(subMenu).map((key) => (
        <ul key={key.toString()}>
          {subMenu[key].map((v) => (
            <li
              role='presentation'
              key={v.title}
              onKeyUp={() => {}}
              onClick={() => history.push(`${v.path}`)}>
              {v.title}
            </li>
          ))}
        </ul>
      ))}
    </SubMenu>
  );
}

export const SubMenu = styled.div`
  background-color: rgba(255, 255, 255, 0.96);
  position: absolute;
  width: 100%;
  display: ${(props) => (props.isHover ? 'flex' : 'none')};
  height: ${(props) => (props.isHover ? 'auto' : 0)};
  transform: scaleY(265px);
  transform-origin: top;
  transition: transform 2s ease;
  top: 70px;
  right: 0;
  margin: 0;
  box-sizing: border-box;
  min-width: 1280px;
  justify-content: flex-end;
  padding-right: 50px;
  /* margin-right: 50px; */
  ul {
    margin: 0;
    padding: 0;
    margin-left: 50px;
    &:nth-child(1) {
      width: 100px;
    }
    &:nth-child(2) {
      width: 180px;
    }
    &:nth-child(3) {
      width: 120px;
    }
    &:nth-child(4) {
      width: 120px;
    }
    &:nth-child(5) {
      width: 85px;
    }
    li {
      margin: 30px 0;
      text-align: center;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      &:hover {
        color: #08587d;
      }
    }
  }
`;

export default React.memo(Submenu);
