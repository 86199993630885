import React from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';

export const SidebarData = [
  {
    title: '회사소개',
    path: '#',
    icon: <RiArrowDownSLine className='arrow-down' />,
    subNav: [
      {
        title: '인사말',
        path: '/greeting',
      },
      {
        title: '연혁',
        path: '/history',
      },
      {
        title: '조직도',
        path: '/organization',
      },
      {
        title: '기업인증서',
        path: '/cert',
      },
      {
        title: '오시는길',
        path: '/map',
      },
    ],
  },
  {
    title: '사업분야',
    path: '#',
    icon: <RiArrowDownSLine className='arrow-down' />,
    subNav: [
      {
        title: '사업소개',
        path: '/business',
      },
      {
        title: '웹 서비스S/W',
        path: '/web',
      },
      {
        title: '딥러닝 얼굴인식 S/W',
        path: '/deeplearning',
      },
      {
        title: 'AI 학습용 데이터 가공',
        path: '/ai',
      },
    ],
  },
  {
    title: '개발문의',
    path: '#',
    icon: <RiArrowDownSLine className='arrow-down' />,
    subNav: [
      {
        title: '개발문의',
        path: '/develop',
      },
    ],
  },
  {
    title: '포트폴리오',
    path: '#',
    icon: <RiArrowDownSLine className='arrow-down' />,
    subNav: [
      {
        title: '포트폴리오',
        path: '/portfolio',
      },
    ],
  },
  {
    title: '공지사항',
    path: '#',
    icon: <RiArrowDownSLine className='arrow-down' />,
    subNav: [
      {
        title: '공지사항',
        path: '/notice',
      },
      {
        title: '사업실적',
        path: '/perform',
      },
      {
        title: '연구소 소식',
        path: '/labnews',
      },
      {
        title: '보도자료',
        path: '/media',
      },
    ],
  },
];
