import GlobalImg from 'public/images/global.svg';
import GearImg from 'public/images/gear.svg';
import OrganizationImg from 'public/images/organization.svg';

export const businessMapper = [
  {
    img: GlobalImg,
    mainContent: '웹서비스 S/W',
    subContent: [
      'JavaScript, ReactJs, NodeJS,',
      'Mongo 등을 사용해 고객에게',
      '최고의 SaaS 경험을 제공하고 있습니다.',
    ],
  },
  {
    img: OrganizationImg,
    mainContent: '딥러닝 S/W',
    subContent: [
      '데이터 크롤러를 이용해 다양한 데이터를',
      '수집하여 이미지 분류, 시계열처리 등의',
      '딥러닝을 개발하고 있습니다.',
    ],
  },
  {
    img: GearImg,
    mainContent: 'AI 학습용 데이터 가공',
    subContent: [
      '머신러닝, 딥러닝에 사용될 미가공 데이터를',
      '학습에 적합하게 가공하여',
      '고객에게 제공하고 있습니다.',
    ],
  },
];

export const businessServiceText = [
  '웹기반 소프트웨어 중점 개발 기업입니다.',
  'On-Demend 서비스 구축을 위한 다양한 서비스를 개발했습니다.',
  '서버, 클라우드, 디자인 등의 전문가들이 모여 높은 퀄리티의 기술을 제공합니다.',
];

export const businessServiceItem = [
  '기술 교육 지원',
  '연계 확산 지원',
  '기술 서비스 지원',
  '해외 진출 / 사업화 지원',
];
