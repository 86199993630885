import React, { useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useHistory } from 'react-router';

import FooterImg from 'public/images/footer.jpg';

import { baseUrl } from '@config/config';

import { TelText } from '@statics/text';

import useChangeString from '@hooks/useChangeString';

import Header from '../../../components/mobile/Header';

function Login() {
  const [id, onChangeId] = useChangeString();
  const [password, onChangePassword] = useChangeString();
  const history = useHistory();
  const [isError, setIsError] = React.useState(false);

  const onLogin = useCallback(async () => {
    try {
      if (!id.trim() || !password.trim()) {
        return;
      }
      setIsError(false);
      const result = await axios.post(`${baseUrl}/user`, {
        email: id,
        password,
      });
      if (result.data.status === 200) {
        const { token } = result.data.data;
        sessionStorage.setItem('token', token);
        history.push('/');
      } else {
        throw new Error('failed to login!');
      }
    } catch (err) {
      setIsError(true);
    }
  }, [baseUrl, id, password]);

  const onLoginKey = useCallback(
    async (e) => {
      try {
        if (e.key === 'Enter') {
          if (!id.trim() || !password.trim()) {
            return;
          }
          setIsError(false);
          const result = await axios.post(`${baseUrl}/user`, {
            email: id,
            password,
          });
          if (result.data.status === 200) {
            const { token } = result.data.data;
            sessionStorage.setItem('token', token);
            history.push('/');
          }
        } else {
          throw new Error('failed to login!');
        }
      } catch (err) {
        setIsError(true);
      }
    },
    [baseUrl, id, password],
  );

  return (
    <>
      <Header />
      <LoginForm>
        <div className='background-cover'>
          <div className='id-form'>
            <div>{'아이디 (ID)'}</div>
            <input className='id' name='id' value={id} onChange={onChangeId} />
          </div>
          <div className='pw-form'>
            <div>{'비밀번호 (PW)'}</div>
            <input
              className='password'
              name='password'
              type='password'
              value={password}
              onChange={onChangePassword}
              onKeyUp={onLoginKey}
            />
          </div>
          <span className='error-message'>
            {isError
              ? '아이디와 비밀번호를 다시 확인해주세요'
              : '\u0020'.normalize()}
          </span>
          <button type='button' onClick={onLogin}>
            로그인
          </button>
        </div>
      </LoginForm>
      <MobileFooterInfo>
        <div className='footer-logo'>CARAMELLA</div>
        <div className='footer-info'>
          <p>
            {`(주)카라멜라 대표: 박서연 사업자등록번호: 405-86-01678 TEL: ${TelText}`}
          </p>
          <p>{'주소: 광주광역시 동구 금남로 193-12, 702호'}</p>
          <p>Copyright &copy; CARAMELLA All rights reserved.</p>
        </div>
      </MobileFooterInfo>
    </>
  );
}

const LoginForm = styled.div`
  background-image: url(${FooterImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  position: relative;
  .background-cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(2, 21, 67, 0.3);
    .id-form > div {
      color: #fff;
      margin-bottom: 0.3rem;
      font-size: 15px;
    }
    .pw-form > div {
      color: #fff;
      margin-bottom: 0.3rem;
      font-size: 15px;
    }
  }
  .id {
    height: 2rem;
    width: 18rem;
    margin-bottom: 1rem;
  }
  .password {
    height: 2rem;
    width: 18rem;
    font-family: '굴림';
  }

  button {
    height: 3rem;
    width: 18rem;
    border: 0;
    font-size: 20px;
    background-color: #08587d;
    color: #fff;
    margin: 2rem 0 0 0;
  }
  .error-message {
    margin-top: 1rem;
    margin-bottom: -1rem;
    color: #fff;
  }
`;
const MobileFooterInfo = styled.footer`
  display: flex;
  background-color: #0c4161;
  height: 2.3rem;

  .footer-logo {
    margin-right: 1rem;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 10px;
    letter-spacing: 2px;
  }
  div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > p {
      font-size: 5px;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 1.4;
      color: #fff;
    }
  }
`;

export default React.memo(Login);
