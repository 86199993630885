import React from 'react';
import Common from '../../../layout/mobile/Common';
import AiDataSection from '../../../components/mobile/business/AiDataSection';

function Ai() {
  return (
    <>
      <Common>
        <AiDataSection />
      </Common>
    </>
  );
}
export default React.memo(Ai);
