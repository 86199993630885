import React, { useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';
import axios from 'axios';
import ReactQuill from 'react-quill';

import { baseUrl } from '@config/config';

import { useQuill } from '@statics/quillModules';
import { subMenu } from '@statics/menu';

import useText from '@hooks/useText';
import useChangeString from '@hooks/useChangeString';

import getFirstUrl from '@utils/getFirstUrl';

import './quill.snow.css';

function Update() {
  const [textEditor, onTextEditor, textContents, setTextEditor] = useText();
  const [title, onTitle, setTitle] = useChangeString();
  const [author, onAuthor, setAuthor] = useChangeString();

  const [uploadedFileNames, setUploadedFileNames] = React.useState([]);
  const [uploadFiles, setUploadFiles] = React.useState([]);

  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const { QuillRef } = useQuill();

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onUpload = useCallback((e) => {
    if (!e.target.files) {
      return;
    }

    if (!e.target.files[0]) {
      return;
    }

    if (
      Array.from(e.target.files).some((file) => file.size > 100 * 1024 * 1024)
    ) {
      return;
    }

    Array.from(e.target.files).forEach((file) => {
      setUploadFiles((prev) => prev.concat(file));
      setUploadedFileNames((prev) => prev.concat(file.name));
    });
    // });
  }, []);

  const onRemove = useCallback((filename) => {
    setUploadedFileNames((prev) => prev.filter((v) => v !== filename));
    setUploadFiles((prev) => prev.filter((x) => x.name !== filename));
  }, []);

  const onUpdate = useCallback(async () => {
    try {
      const token = sessionStorage.getItem('token');
      if (
        !title.trim() ||
        !author.trim() ||
        !textContents.trim().replace(/\s/g, '') ||
        !token
      ) {
        return;
      }

      const formData = new FormData();
      const body = {
        id: params.id,
        title,
        contents: textEditor,
        author,
      };

      Object.entries(body).forEach(([key, value]) => {
        formData.append(`${key}`, value);
      });
      if (uploadFiles.length > 0) {
        uploadFiles.forEach((uploadFile) => {
          formData.append(uploadFile.name, uploadFile);
        });
      }

      const result = await axios.post(`${baseUrl}/post/edit`, formData, {
        headers: { token },
      });

      if (result.data.status === 200) {
        history.push(`/${getFirstUrl(location.pathname)}`);
      } else {
        throw new Error('error');
      }
    } catch (err) {
      // const b = 3;
    }
  }, [location, title, author, textContents, textEditor, history, uploadFiles]);

  const onLoadContent = useCallback(async () => {
    try {
      const result = await axios.get(`${baseUrl}/post/${params.id}`);
      if (result.data.status === 200) {
        const { post } = result.data.data;
        // const file = result.data.data;

        setTitle(post.title);
        setAuthor(post.author);
        setTextEditor(post.contents);
        // setFiles(file.files);
      } else {
        throw new Error('error');
      }
    } catch (err) {
      // const a = 1
    }
  }, [baseUrl, params]);

  useEffect(() => {
    onLoadContent();
  }, []);

  return (
    <>
      <CreateContent>
        <div className='notice-text'>
          {`${
            Object.values(subMenu)
              .flat()
              .find(
                (x) =>
                  x.path ===
                  `/${location.pathname.split('/').filter((c) => c)[0]}`,
              ).title
          } 글 수정`}
        </div>

        <div className='input-field'>
          <div className='left-field'>
            <span>*</span>제목
          </div>
          <input
            type='text'
            className='right-field'
            value={title}
            onChange={onTitle}
          />
        </div>
        <div className='input-field'>
          <div className='left-field'>
            <span>*</span>작성자
          </div>
          <input
            type='text'
            className='right-field'
            value={author}
            onChange={onAuthor}
          />
        </div>
        <div className='textarea-field'>
          <div className='left-field'>
            <span>*</span>공지 내용
          </div>
          <ReactQuill
            ref={(element) => {
              if (element !== null) {
                QuillRef.current = element;
              }
            }}
            modules={{ toolbar: false }}
            theme='snow'
            value={textEditor}
            onChange={onTextEditor}
          />
        </div>
        <div className='input-field'>
          <div className='left-field'>첨부파일</div>
          <div className='label-field'>
            <input
              hidden
              id='upload-files'
              type='file'
              accept='image/*'
              multiple
              onChange={onUpload}
            />
            <label htmlFor='upload-files' className='upload-label'>
              파일선택
            </label>

            {uploadedFileNames.map((v) => (
              <FileTag key={v}>
                <span>{v}</span>
                <span
                  role='presentation'
                  className='cancel-imoji'
                  onClick={() => onRemove(v)}
                  onKeyUp={() => {}}>
                  {String.fromCharCode(0x1f5d9)}
                </span>
              </FileTag>
            ))}
          </div>
        </div>
        <div className='line'></div>
        <div className='buttons'>
          <button type='button' onClick={onUpdate}>
            수정
          </button>
          <button type='button' onClick={onCancel}>
            닫기
          </button>
        </div>
      </CreateContent>
    </>
  );
}

const CreateContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin: 4rem 1rem;
  .line {
    margin: 0 auto;
    width: 100%;
    border-top: 1px solid #ccc;
  }
  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
  }
  button {
    outline: none;
    border: none;
    font-size: 13px;
    background-color: #08587d;
    color: #fff;
    height: 40px;
    width: 133px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:first-child {
      margin-right: 30px;
    }

    &:last-child {
      border: 1px solid #08587d;
      background-color: #fff;
      color: #08587d;
    }
  }

  .notice-text {
    border-bottom: 1px solid #ccc;
    margin-bottom: 2rem;
    padding: 10px 0;
  }
  .input-field {
    display: flex;
    height: 20px;
    margin-bottom: 40px;

    &:last-child {
      margin-top: 16px;
    }

    .left-field {
      width: 100px;
      display: flex;
      align-items: center;
      color: #000000;
      font-size: 14px;
      font-weight: 600;
      height: 2rem;
      span {
        color: red;
        margin-right: 0.3rem;
      }

      .required {
        color: #f00;
      }
    }

    .right-field {
      flex: 8;
      box-sizing: border-box;
      border: 1px solid #ccc;
      background-color: #fff;
      color: #000;
      font-size: 13px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      outline: none;
      height: 2rem;

      &::placeholder {
        font-size: 10px;
        color: #707070;
      }
    }

    .label-field {
      flex: 8;
      display: flex;
      align-items: center;
      .no-func {
        flex: 1;
        background-color: #fff;
        box-sizing: border-box;
        border: 1px solid #ccc;
        height: 30px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        padding-left: 3px;
        padding-right: 3px;
        span {
          margin-right: 5px;
          font-size: 5px;
        }
      }
    }

    .upload-label {
      height: 30px;
      width: 50px;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: #08587d;
      outline: none;
      cursor: pointer;
      border: none;
      font-size: 10px;
    }
  }
  .textarea-field {
    display: flex;
    margin-bottom: 40px;

    .left-field {
      width: 100px;

      display: flex;
      color: #000000;
      font-weight: 600;
      font-size: 14px;
      span {
        color: red;
        margin-right: 0.3rem;
      }
      .required {
        color: #f00;
      }
    }
    .right-field {
      flex: 8;
      padding: 16px;
      outline: none;
      resize: none;
      border: 1px solid #ccc;
      ::placeholder {
        font-size: 10px;
        color: #707070;
      }
    }
  }
  .additional {
    color: #707070;
    font-size: 10px;
  }
`;

export const FileTag = styled.div`
  border-radius: 17px;
  height: 18px;
  border: 2px solid #08587d;
  display: flex;
  margin: auto 0.8rem;
  align-items: center;
  justify-content: space-evenly;
  color: #08587d;
  padding: 5px 0;
  font-size: 7px;
  .cancel-imoji {
    display: flex;
    align-items: center;
    color: #707070;
    margin-left: 5px;
    font-size: 8px;
    cursor: pointer;
  }
`;
export default React.memo(Update);
