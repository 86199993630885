import React from 'react';
import styled from 'styled-components';

import FooterImg from 'public/images/footer.jpg';
import Phone from 'public/images/phone.svg';
import Mail from 'public/images/mail.svg';
import MapPin from 'public/images/mapPin.svg';

import { theme } from '@utils/theme';
import { flexAlign } from '@utils/commonStyles';

import { TelText } from '@statics/text';

import SVGFactory from './home/SVGFactory';

function mobileFooter() {
  return (
    <>
      <MobileFooterSide>
        <div className='background-cover'>
          <div className='CUS-sections'>
            <div className='line'>ㅡ</div>
            <p className='c-title'>CONTACT</p>
          </div>
          <div className='info-section'>
            <div className='sections'>
              <div className='info-title'>
                <SVGFactory Icon={Phone} width={24} height={24}></SVGFactory>
                <p className='title'>TEL.</p>
                {/* <img src={Tel} alt='tel' className='sub-title' width='50px' /> */}
                <p className='sub-title'>062-714-3626</p>
              </div>
            </div>
          </div>
          <div className='info-section'>
            <div className='sections'>
              <div className='info-title'>
                <SVGFactory Icon={Mail} width={24} height={24}></SVGFactory>
                <p className='title'>E-mail.</p>
                <p className='sub-title'>PM@caramella.kr</p>
              </div>
            </div>
          </div>
          <div className='info-section'>
            <div className='sections'>
              <div className='info-title'>
                <SVGFactory Icon={MapPin} width={24} height={24}></SVGFactory>
                <p className='title'>Address.</p>
                <p className='sub-title'>
                  {'광주광역시 남구 송암로24번가길 46, 503호'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </MobileFooterSide>
      <MobileFooterInfo>
        <div className='footer-logo'>CARAMELLA</div>
        <div className='footer-info'>
          <p className='information'>
            {`(주)카라멜라 대표: 박서연 사업자등록번호: 405-86-01678 TEL: ${TelText}`}
          </p>
          <p className='information'>
            {'본사: 광주광역시 남구 송암로24번가길 46, 503호'}
          </p>
          <p className='copy'>
            Copyright &copy; 2021 Caramella Inc. All rights reserved.
          </p>
        </div>
      </MobileFooterInfo>
    </>
  );
}

const MobileFooterSide = styled.footer`
  height: 10rem;
  background-image: url(${FooterImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media only all and (min-width: 1024px) {
    height: 8rem;

    .CUS-sections {
      font-size: 20px;
    }
  }
  div.info-section:nth-child(4)
    > div:nth-child(1)
    > div:nth-child(1)
    > p:nth-child(3) {
    margin: 0;
  }
  div.info-section:nth-child(3)
    > div:nth-child(1)
    > div:nth-child(1)
    > p:nth-child(3) {
    font-size: 7.5px;
  }
  .background-cover {
    display: grid;
    grid-template-columns: 37% 21% 21% 21%;
    width: 100%;
    height: 100%;
    background-color: rgba(2, 21, 67, 0.56);
  }
  .CUS-sections {
    border-right: 1px solid ${theme.border.white};
    display: grid;
    align-items: end;
    padding: 10% 10% 10% 0;
    letter-spacing: 2px;
    color: ${theme.color.white};
    margin-left: 0.9rem;
    .c-title {
      margin: 0 0 10px 0;
    }
  }

  .info-section {
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    border-right: 1px solid ${theme.border.white};
    flex: 1;
    padding: 10%;
    position: relative;
    :last-child {
      border: none;
    }

    .info-title {
      font-weight: 400;
      color: ${theme.color.white};
      line-height: 1;
      * {
        word-break: break-all;
      }
      & > svg {
        transform: scale(0.6);
        fill: #ffffff;
      }
      .title {
        position: absolute;
        top: 50%;
        left: 7%;
        font-size: 11px;
        font-weight: 600;
        display: flex;
        align-items: center;
        @media only all and (min-width: 1024px) {
          font-size: 20px;
          position: absolute;
          top: 40%;
          left: 7%;
        }
      }
      img {
        @media only all and (min-width: 1024px) {
          width: 90px !important;
        }
      }
      .sub-title {
        position: absolute;
        font-weight: 400;
        top: 80%;
        left: 7%;
        font-size: 6.5px;
        margin: 0;
        @media only all and (min-width: 1024px) {
          font-size: 13px !important;
          position: absolute;
          top: 80%;
          left: 7%;
        }
      }

      .iconimg {
        position: absolute;
        top: 5%;
        right: 5%;
      }
    }
  }
`;

const MobileFooterInfo = styled.footer`
  display: flex;
  background-color: #0c4161;
  height: auto;
  padding: 15px 0;
  @media only all and (min-width: 1024px) {
    padding: 15px 0;
  }

  .footer-logo {
    margin-right: 1rem;
    margin-left: 1rem;
    ${flexAlign()}
    color: ${theme.color.white};
    font-size: 10px;
    letter-spacing: 2px;
    @media only all and (min-width: 1024px) {
      font-size: 14px;
    }
  }
  div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .information {
      font-size: 6.5px;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 1.4;
      color: #fff;
      @media only all and (min-width: 1024px) {
        font-size: 9px;
      }
    }
    & > p {
      font-size: 5px;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 1.4;
      color: ${theme.color.white};
      @media only all and (min-width: 1024px) {
        font-size: 6px;
      }
    }
  }
`;
export default React.memo(mobileFooter);
