export const theme = {
  color: {
    baseColor: '#08587d',
    black: '#000',
    gray707070: '#707070',
    white: '#fff',
    red: '#ff0000',
    gray: '#ccc',
  },
  border: {
    gray707070: '#707070',
    gray: '#ccc',
    grayC0c0c0: '#c0c0c0',
    black: '#000',
    white: '#fff',
    baseColor: '#08587d',
  },
  background: {
    baseColor: '#08587d',
    lightGray: '#f9fafc',
    white: '#fff',
    grayE4e4e4: '#e4e4e4',
  },
};
