import React from 'react';
import styled from 'styled-components';

import GlobalImg from 'public/images/global.svg';
import GearImg from 'public/images/gear.svg';
import OrganizationImg from 'public/images/organization.svg';

import { theme } from '@utils/theme';
import { pixelToRem, flexAlign } from '@utils/commonStyles';

import SVGFactory from '../home/SVGFactory';
import HorizonBar from '../HorizonBar';

function BusinessSection() {
  return (
    <>
      <BusinessSectionContent>
        <div className='business-intro'>
          <div className='intro-square'>
            <div className='icon-section'>
              <SVGFactory Icon={GlobalImg} />
            </div>

            <div className='intro-content'>
              <h3 className='main-content'>웹서비스 S/W</h3>
              <p className='sub-content'>
                JavaScript, ReactJS, <br />
                NodeJS, Mongo 등을 사용해서
                <br />
                고객에게 최고의 SaaS 경험을
                <br /> 제공하고 있습니다.
              </p>
            </div>
          </div>
          <div className='intro-square'>
            <div className='icon-section'>
              <SVGFactory Icon={OrganizationImg} />
            </div>
            <div className='intro-content'>
              <h3 className='main-content'>딥러닝 S/W</h3>
              <p className='sub-content'>
                데이터 크롤러를 이용해 다양한 <br />
                데이터를 수집하여, 이미지 분류,
                <br /> 시계열처리 등의 <br />
                딥러닝을 개발하고 있습니다.
              </p>
            </div>
          </div>
          <div className='intro-square'>
            <div className='icon-section'>
              <SVGFactory Icon={GearImg} />
            </div>
            <div className='intro-content'>
              <h3 className='main-content'>AI 학습용 데이터</h3>
              <p className='sub-content'>
                머신러닝, 딥러닝에 <br />
                사용될 미가공 데이터를
                <br /> 학습에 적합하게 가공하여 <br />
                고객에게 제공하고 있습니다.
              </p>
            </div>
          </div>
        </div>
      </BusinessSectionContent>
      <BuinessServiceContent>
        <div className='service-content'>
          <div className='service-title'>
            <h3>SaaS(Software as a service) 전문 기업</h3>
            <HorizonBar width={50} height={2} />
            <p>
              웹기반 소프트웨어 중점 개발 기업 입니다. <br />
              On-Demend 서비스 구축을 위한 다양한 서비스를 개발 했습니다.
              <br />
              서버, 클라우드, 디자인 등의 전문가들이 모여 높은 퀄리티의 기술을
              제공합니다.
            </p>
          </div>
          <div className='service-circle'>
            <div className='circle1'>
              <p>기술 서비스 지원</p>
            </div>
            <div className='circle1'>
              <p>기술 교육 지원</p>
            </div>
            <div className='circle1'>
              <p>연계 확산 지원</p>
            </div>
            <div className='circle1'>
              <p>
                해외 진출
                <br />
                사업화 지원
              </p>
            </div>
          </div>
        </div>
      </BuinessServiceContent>
    </>
  );
}

const BusinessSectionContent = styled.section`
  margin: 0 auto;

  .business-intro {
    display: flex;
  }

  .intro-square {
    padding: 0.7rem 0;
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-right: 0.8rem;
    width: 6.8rem;
    border: 1px solid ${theme.border.gray};
    border-top: 3px solid ${theme.border.baseColor};
    @media only all and (min-width: 630px) {
      width: 15rem;
      height: 11rem;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .icon-section {
    ${flexAlign()}
    width: ${pixelToRem(50)};
    height: ${pixelToRem(50)};
    background-color: ${theme.background.lightGray};
    border-radius: 50%;
    margin-bottom: ${pixelToRem(10)};
    @media only all and (min-width: 630px) {
      border-radius: 50%;
      width: ${pixelToRem(70)};
      height: ${pixelToRem(70)};
    }

    & > svg {
      fill: #08587d;
      scale: 0.9;
      @media only all and (min-width: 630px) {
        scale: 1.3;
      }
    }
  }
  .intro-content {
    ${flexAlign('column')}
    width: 100%;

    h3 {
      margin: 5px 0;
      font-size: ${pixelToRem(10)};
      font-weight: 800;
      @media only all and (min-width: 630px) {
        font-size: ${pixelToRem(15)};
      }
    }
    .sub-content {
      line-height: 1.6;
      text-align: center;
      margin: 10px 0 0 0;
      font-size: 7.4px;
      padding: 0 3px;
      margin: 0;
      @media only all and (min-width: 630px) {
        font-size: 11px;
      }
    }
  }
`;

const BuinessServiceContent = styled.section`
  background-color: ${theme.background.lightGray};
  margin-top: 4rem;
  height: 28rem;
  .service-title {
    ${flexAlign('column')}
    margin-top: 1rem;

    h3 {
      font-weight: 800;
      font-size: 16px;
    }

    p {
      color: ${theme.color.gray707070};
      font-size: 10px;
      text-align: center;
      margin: 0;
      line-height: 1.7;
      @media only all and (min-width: 630px) {
        font-size: 13px;
      }
    }
  }

  .service-circle {
    width: 70%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 1rem;
    margin: 1rem auto 0;
  }

  .circle1 {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background-color: ${theme.background.white};
    text-align: center;
    ${flexAlign()}
    margin: 0 auto;
    p {
      margin: 0;
      padding: 0;
      font-weight: bolder;
      font-size: 12px;
      color: ${theme.color.baseColor};
    }
  }
`;
export default React.memo(BusinessSection);
