import styled from 'styled-components';

import { theme } from '@utils/theme';
import { pixelToRem, flexAlign } from '@utils/commonStyles';

export const NotioceSectionContent = styled.section`
  margin: 0 1rem 2rem;
  border: 1px solid ${theme.border.gray};
  .notice_content {
    margin: 0 1rem;
  }
  .top:last-child > .notice_info {
    border-bottom: none;
  }

  .admin-btn {
    display: flex;
    margin-top: ${pixelToRem(5)};
    .update {
      margin-right: ${pixelToRem(5)};
    }

    button {
      border: 0;
      height: ${pixelToRem(20)};
      width: ${pixelToRem(40)};
      font-size: ${pixelToRem(10)};
      background-color: ${theme.background.baseColor};
      color: ${theme.color.white};
    }
  }

  .top-title {
    margin-top: 0.7rem;
    ${flexAlign(undefined, undefined, 'space-between')};
    .notice_title {
      font-weight: 700;

      font-size: ${pixelToRem(15)};
      @media only all and (min-width: 640px) {
        font-size: ${pixelToRem(17)};
      }
    }
  }

  .searchZone {
    ${flexAlign(undefined, undefined, 'space-between', undefined)};
    height: ${pixelToRem(35)};
    padding: 3px 0;
    margin: 0 10px;
    border-bottom: 1.5px solid ${theme.color.gray707070};
    & > svg {
      transform: scale(0.8);
      z-index: -1;
    }
  }

  input {
    height: ${pixelToRem(23)};
    outline: 0;
    border: 0;
    padding: 0 5px;
    width: 100%;
  }
  .notice-id {
    display: none;
  }
  .searchBtn {
    outline: 0;
    background-color: ${theme.background.baseColor};
    color: ${theme.color.white};
    width: ${pixelToRem(60)};
    height: ${pixelToRem(25)};
    border: 0;
  }
  .notice_info {
    ${flexAlign(undefined, undefined, 'space-between')}
    margin-top: ${pixelToRem(10)};
    padding-top: ${pixelToRem(5)};
    font-weight: 400;
    color: #707070;
    border-bottom: 1px solid ${theme.border.gray};
    font-size: ${pixelToRem(15)};

    .notice_date,
    .notice_author {
      margin-bottom: 4px;
    }
  }
`;
export const WritingBtnSection = styled.section`
  margin: 0 1.6rem 4rem;
  position: relative;
  justify-content: ${(props) =>
    props.isExistPermission ? 'space-between' : 'center'};
  button.create {
    z-index: 0;
    position: absolute;
    top: -1rem;
    right: 0;
    border: 0;
    outline: none;
    background-color: ${theme.background.baseColor};
    color: ${theme.color.white};
    width: ${pixelToRem(70)};
    height: ${pixelToRem(30)};
  }
`;
export const ReadContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid ${theme.border.gray};
  background-color: ${theme.background.white};
  margin: 0 1rem 4rem;
  a {
    color: #08587d;
    font-weight: 700;
    word-break: break-all;
  }
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.3em;
  }
  h3 {
    font-size: 1.1em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.8em;
  }
  h6 {
    font-size: 0.6em;
  }

  .admin-btn {
    display: flex;
    margin-top: 5px;
    .update {
      margin-right: 5px;
    }

    button {
      border: 0;
      height: ${pixelToRem(25)};
      width: ${pixelToRem(45)};
      font-size: ${pixelToRem(10)};
      background-color: ${theme.background.baseColor};
      color: ${theme.color.white};
    }
    .delete {
      width: ${pixelToRem(45)};
      height: ${pixelToRem(25)};
      color: ${theme.color.baseColor};
      border: 1px solid ${theme.border.baseColor};
      background: ${theme.background.white};
      font-size: ${pixelToRem(10)};
    }
  }
  .read-header {
    height: ${pixelToRem(70)};
    padding: 0 ${pixelToRem(15)};
    ${flexAlign(undefined, undefined, 'space-between', undefined)};
    border-bottom: 1px solid ${theme.border.gray};
    font-weight: 900;
    .read-title {
      font-size: ${pixelToRem(16)};
    }
  }
  .read-info {
    color: ${theme.color.gray707070};
    font-weight: 500;
    .read-author {
      margin-left: ${pixelToRem(10)};
    }
  }

  .read-footer {
    ${flexAlign(undefined, undefined, 'flex-start', undefined)};
    height: ${pixelToRem(91)};
    box-sizing: border-box;
    border-top: 1px solid ${theme.border.gray};
    padding-left: ${pixelToRem(20)};
    button {
      height: ${pixelToRem(35)};
      width: ${pixelToRem(100)};
      outline: none;
      cursor: pointer;
      box-sizing: border-box;
      border: 1px solid ${theme.border.baseColor};
      color: ${theme.color.baseColor};
      background-color: ${theme.background.white};
      font-size: ${pixelToRem(13)};
      font-weight: 600;
    }
  }
`;

export const ReadContent = styled.div`
  padding: ${pixelToRem(15)};
  .main-content {
    margin-top: ${pixelToRem(40)};

    img {
      width: 100%;
    }
  }
  p {
    margin: 0;
    font-size: ${pixelToRem(14)};
    line-height: 1.4;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  margin-top: ${pixelToRem(10)};
  margin-bottom: ${pixelToRem(14)};
  .file-items {
    color: ${theme.color.baseColor};
    border: 1px solid ${theme.color.baseColor};
    border-radius: ${pixelToRem(16)};
    padding: 3px 7px;
    font-size: ${pixelToRem(10)};
    margin-right: ${pixelToRem(10)};
  }
  .addedFile {
    margin-right: ${pixelToRem(10)};
    font-size: ${pixelToRem(16)};
    color: ${theme.color.black};
    display: flex;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }

    .file-icon {
      ${flexAlign()}
      width: ${pixelToRem(17)};
      height: ${pixelToRem(17)};

      svg {
        transform: scale(0.7);
      }
    }
  }

  .file-name {
    color: ${theme.color.baseColor};
    margin: 0;
    margin-bottom: ${pixelToRem(12)};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const CreateContent = styled.div`
  padding: ${pixelToRem(10)} ${pixelToRem(20)};
  box-sizing: border-box;
  border: 1px solid ${theme.border.gray};
  margin: 0 1rem;
  margin-bottom: 4rem;
  .line {
    margin: 0 auto;
    width: 100%;
    border-top: 1px solid ${theme.border.gray};
  }
  .buttons {
    ${flexAlign()}
    margin: 2rem 0;
  }
  button {
    outline: none;
    border: none;
    font-size: ${pixelToRem(13)};
    background-color: ${theme.background.baseColor};
    color: ${theme.color.white};
    height: ${pixelToRem(40)};
    width: ${pixelToRem(133)};
    cursor: pointer;

    &:first-child {
      margin-right: ${pixelToRem(30)};
    }

    &:last-child {
      border: 1px solid ${theme.border.baseColor};
      background-color: ${theme.background.white};
      color: ${theme.color.baseColor};
    }
  }

  .notice-text {
    border-bottom: 1px solid ${theme.border.gray};
    margin-bottom: 1rem;
    font-weight: 900;
    padding: ${pixelToRem(10)} 0;
    font-size: ${pixelToRem(18)};
  }
  .input-field {
    display: flex;
    height: ${pixelToRem(20)};
    margin-bottom: ${pixelToRem(40)};

    &:last-child {
      margin-top: ${pixelToRem(16)};
    }

    .left-field {
      width: ${pixelToRem(100)};
      ${flexAlign(undefined, undefined, 'none', undefined)}
      color: ${theme.color.black};
      font-size: ${pixelToRem(14)};
      font-weight: 600;
      height: 2rem;
      span {
        color: red;
        margin-right: 0.3rem;
      }

      .required {
        color: #f00;
      }
    }

    .right-field {
      flex: 8;
      box-sizing: border-box;
      border: 1px solid ${theme.border.gray};
      background-color: ${theme.background.white};
      color: ${theme.color.black};
      font-size: ${pixelToRem(13)};
      padding-left: ${pixelToRem(10)};
      outline: none;
      height: 2rem;
      &::placeholder {
        font-size: ${pixelToRem(10)};
        color: ${theme.color.gray707070};
      }
    }

    .label-field {
      flex: 8;
      ${flexAlign(undefined, undefined, 'none', undefined)}
    }

    .upload-label {
      height: ${pixelToRem(30)};
      width: ${pixelToRem(50)};
      margin-top: auto;
      ${flexAlign()};
      color: ${theme.color.white};
      background-color: ${theme.background.baseColor};
      outline: none;
      cursor: pointer;
      border: none;
      font-size: ${pixelToRem(10)};
    }
  }
  .textarea-field {
    display: flex;
    margin-bottom: ${pixelToRem(40)};

    .left-field {
      width: ${pixelToRem(100)};
      display: flex;
      color: ${theme.color.black};
      font-weight: 600;
      font-size: ${pixelToRem(14)};
      span {
        color: red;
        margin-right: 0.3rem;
      }
      .required {
        color: #f00;
      }
    }
    .right-field {
      flex: 8;
      outline: none;
      resize: none;
      border: 1px solid ${theme.border.gray};
      ::placeholder {
        font-size: ${pixelToRem(10)};
        color: ${theme.color.gray707070};
      }
    }
  }
  .additional {
    color: ${theme.color.gray707070};
    font-size: ${pixelToRem(10)};
  }
`;

export const FileTag = styled.div`
  ${flexAlign(undefined, undefined, 'space-evenly')}
  border-radius: ${pixelToRem(17)};
  height: ${pixelToRem(18)};
  border: 2px solid ${theme.border.baseColor};
  margin: auto 0.8rem;
  color: ${theme.color.baseColor};
  padding: 5px 0;
  font-size: ${pixelToRem(7)};
  .cancel-imoji {
    ${flexAlign()}
    color: ${theme.color.gray707070};
    margin-left: ${pixelToRem(10)};
    font-size: ${pixelToRem(8)};
    cursor: pointer;
  }
`;
