import React from 'react';
import { useParams, Redirect } from 'react-router';

import Common from '../../../layout/mobile/Common';
import Read from '../../../components/mobile/notice/Read';

const paths = ['notice', 'perform', 'labnews', 'media'];

function NoticeRead() {
  const params = useParams();

  if (!paths.includes(params.category)) {
    return <Redirect to={'/'} />;
  }

  return (
    <Common>
      <Read />
    </Common>
  );
}

export default React.memo(NoticeRead);
