import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { baseUrl } from '@config/config';

import { pixelToRem, flexAlign } from '@utils/commonStyles';
import { theme } from '@utils/theme';

function HistorySection() {
  const [src, setSrc] = useState('');
  const [file, setFile] = useState(undefined);
  const onShowHistory = useCallback(async () => {
    const res = await axios.get(`${baseUrl}/file/detail?target=history`);
    if (res.data.status === 200) {
      const { Bucket, Key } = res.data.data.fileData;
      setSrc(`https://${Bucket}.s3.ap-northeast-2.amazonaws.com/${Key}`);
    }
  }, [baseUrl]);

  useEffect(() => {
    onShowHistory();
  }, []);

  const onUpload = useCallback(
    async (e) => {
      if (!e.target.files) {
        return;
      }

      if (!e.target.files[0]) {
        return;
      }

      if (e.target.files[0].size > 100 * 1024 * 1024) {
        return;
      }

      const token = sessionStorage.getItem('token');

      if (!token) {
        return;
      }

      try {
        const formData = new FormData();
        formData.append('prefix', 'history');
        formData.append('files', e.target.files[0]);
        const res = await axios.post(`${baseUrl}/file`, formData, {
          headers: { 'Content-Type': 'multipart/form-data', token },
        });
        if (res.data.status === 200) {
          setFile(undefined);
        }
      } catch (err) {
        // const a = 1
      }
    },
    [baseUrl],
  );

  return (
    <>
      <HistorySectionContent>
        <img src={src} alt={'history'} />
        {sessionStorage.getItem('token') && (
          <div className='upload'>
            <input
              type={'file'}
              hidden
              onChange={onUpload}
              id={'history-upload'}
              accept='image/*'
              multiple={false}
              value={file}
            />
            <label htmlFor={'history-upload'}>연혁 업로드</label>
          </div>
        )}
      </HistorySectionContent>
    </>
  );
}

const HistorySectionContent = styled.section`
  ${flexAlign('column', undefined, undefined)}
  margin-bottom: 7rem;

  img {
    width: 90%;
    margin: 0 auto;
  }

  .upload {
    ${flexAlign(undefined, undefined, undefined)}
    margin-top: 1.875rem;
    label {
      ${flexAlign()}
      box-sizing: border-box;
      width: ${pixelToRem(133)};
      height: ${pixelToRem(40)};
      background-color: ${theme.color.baseColor};
      color: ${theme.color.white};
      font-size: 0.875rem;
      cursor: pointer;
    }
  }
`;
export default React.memo(HistorySection);
