import React from 'react';
import { useParams, Redirect } from 'react-router';
import Common from '../../../layout/mobile/Common';
import Update from '../../../components/mobile/notice/Update';

const paths = ['notice', 'perform', 'labnews', 'media'];

function NoticeUpdate() {
  const params = useParams();

  if (!paths.includes(params.category)) {
    return <Redirect to={'/'} />;
  }

  return (
    <Common isExistBar={false} isExistSectionHeader={false}>
      <Update />
    </Common>
  );
}

export default React.memo(NoticeUpdate);
