import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';

import ClipIcon from 'public/images/attachment.svg';

import { baseUrl } from '@config/config';

import useModal from '@hooks/useModal';

import parseJwt from '@utils/parseJwt';
import getFirstUrl from '@utils/getFirstUrl';

import { FileContainer, ReadContainer, ReadContent } from './style';
import Modal from '../Modal';

function Read() {
  const history = useHistory();
  const [post, setPost] = useState('');
  const [fileList, setFileList] = useState([]);

  const onList = useCallback(() => {
    history.push(
      `/${history.location.pathname.split('/').filter((c) => c)[0]}`,
    );
  }, [history]);

  const showPost = useCallback(async () => {
    const pathArray = history.location.pathname.split('/');
    const result = await axios.get(
      `${baseUrl}/post/${pathArray[pathArray.length - 1]}`,
    );

    if (result.data.status === 200) {
      setPost(result.data.data.post);

      if (result.data.data.files) {
        setFileList(result.data.data.files);
      }
      return result;
    }
    throw new Error('');
  }, [baseUrl, history]);

  useEffect(() => {
    showPost();
  }, []);

  const [isExistPermission] = React.useState(
    parseJwt(sessionStorage.getItem('token') || false),
  );
  const [removeTarget, setRemoveTarget] = React.useState(-1);
  const [modalHidden, onHidden] = useModal();

  const onRemoveReady = useCallback(
    (id) => () => {
      setRemoveTarget(id);
      onHidden();
    },
    [],
  );

  const onRemove = useCallback(async () => {
    try {
      const token = sessionStorage.getItem('token');
      if (removeTarget < 1 || !token) {
        return;
      }
      const formData = new FormData();
      formData.append('id', removeTarget);
      const result = await axios.post(
        `${baseUrl}/post/delete`,
        { id: removeTarget },
        {
          headers: { token },
        },
      );
      if (result.data.status === 200) {
        onHidden();
      } else {
        throw new Error('failed to remove the post');
      }
    } catch (err) {
      // console.error('fail!');
    }
  }, [baseUrl, removeTarget]);
  const onUpdate = useCallback(
    (id) => {
      const prefix = getFirstUrl(history.location.pathname);
      history.push(`/${prefix}/update/${id}`);
    },
    [history],
  );

  const onDownload = useCallback(
    (e) => {
      const { dataset } = e.target;
      const { idx } = dataset;
      const { name, url } = fileList[idx];
      const aTag = document.createElement('a');
      aTag.href = url;
      aTag.download = name;
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    },
    [fileList],
  );

  return (
    <>
      <Modal onHidden={onHidden} onClick={onRemove} modalHidden={modalHidden}>
        <p
          style={{
            textAlign: 'center',
            fontSize: 15,
            fontWeight: 900,
          }}>
          공지사항 정보를 <span style={{ color: 'red' }}>삭제</span>
          하시겠습니까?
        </p>
      </Modal>
      <ReadContainer>
        <div className={'read-header'}>
          <div className='read-title'>{`${post?.title}`}</div>
          {isExistPermission ? (
            <>
              <div className='admin-btn'>
                <button className='update' onClick={() => onUpdate(post?.id)}>
                  수정
                </button>

                <button className='delete' onClick={onRemoveReady(post?.id)}>
                  삭제
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <ReadContent>
          <div className='read-info'>
            {`${new Date(post?.createdAt)
              .toLocaleDateString('ko-KR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
              .replace(/\s+/g, '')
              .slice(0, -1)}`}
            <span className='read-author'>{`${post?.author}`}</span>
          </div>
          {fileList && fileList.length > 0 ? (
            <FileContainer>
              <div className={'addedFile'}>
                <span>{'첨부파일'}</span>
                <div className={'file-icon'}>
                  <ClipIcon />
                </div>
              </div>
              {fileList.map((file, index) => (
                <button
                  className={'file-items'}
                  data-idx={index}
                  onClick={onDownload}
                  key={`file-${file.id}`}>
                  {`첨부파일 ${index + 1}`}
                </button>
              ))}
            </FileContainer>
          ) : null}
          <div
            dangerouslySetInnerHTML={{ __html: post?.contents || '' }}
            className='main-content'></div>
        </ReadContent>
        <div className={'read-footer'}>
          <button type='button' onClick={onList}>
            목록보기
          </button>
        </div>
      </ReadContainer>
    </>
  );
}

export default React.memo(Read);
