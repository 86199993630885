import React from 'react';
import styled from 'styled-components';
import CloseIcon from 'public/images/close.svg';
import { theme } from '../../utils/theme';
import { flexAlign, pixelToRem } from '../../utils/commonStyles';

function Modal({
  modalHidden,
  onHidden,
  title = '알림',
  onClick = () => {},
  children,
  submitText = '확인',
  cancelText = '닫기',
  isExistFirstButton = true,
  isExistSecondButton = true,
  flex = 0.5,
}) {
  return (
    <ModalContainer hidden={modalHidden}>
      <ModalWrapper flex={flex}>
        <ModalHeader>
          <h3>{title}</h3>
          <button type='button' onClick={onHidden}>
            <CloseIcon />
          </button>
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
        <ModalFooter>
          {isExistFirstButton ? (
            <button type='button' onClick={onClick}>
              {submitText}
            </button>
          ) : null}
          {isExistSecondButton ? (
            <button type='button' onClick={onHidden}>
              {cancelText}
            </button>
          ) : null}
        </ModalFooter>
      </ModalWrapper>
    </ModalContainer>
  );
}

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.39);
  display: ${(props) => (props.hidden ? 'flex' : 'none')};
`;

export const ModalWrapper = styled.div`
  background-color: ${theme.background.white};
  flex: ${(props) => (props.flex ? props.flex : 0.5)};
  border-radius: ${pixelToRem(7)};
`;

export const ModalHeader = styled.div`
  ${flexAlign(undefined, undefined, 'space-between')}
  padding: 1.25rem;
  box-sizing: border-box;
  border-bottom: 1px solid ${theme.border.gray707070};
  height: 1rem;
  h3 {
    color: ${theme.color.black};
    margin: 0;
    font-size: 15px;
    font-weight: 900;
  }
  button {
    cursor: pointer;
    outline: none;
    border: none;
    box-sizing: border-box;
    padding: 0;
    background-color: ${theme.background.white};
    margin-right: -0.4rem;
    svg {
      fill: #707070;
      transform: scale(1.333);
      margin: 0;
    }
  }
`;

export const ModalContent = styled.div`
  background-color: ${theme.background.white};
  padding: 32px 0;

  .flexable {
    display: flex;
  }
`;

export const ModalFooter = styled.div`
  ${flexAlign()};
  box-sizing: border-box;
  border-top: 1px solid ${theme.border.gray707070};
  padding: 1rem;
  flex: 1;
  button {
    cursor: pointer;
    outline: none;
    border: none;
    box-sizing: border-box;
    width: 8.3125rem;
    height: 2.5rem;
  }
  button:last-child {
    background-color: ${theme.background.white};
    color: ${theme.color.baseColor};
    border: 1px solid ${theme.border.baseColor};
    margin-left: 0.5rem;
  }
  button:first-child {
    background-color: ${theme.background.baseColor};
    color: ${theme.color.white};
    margin-right: 0.5rem;
  }
`;

export default React.memo(Modal);
