import React from 'react';
import { Helmet } from 'react-helmet-async';

import { TelText } from '@statics/text';
import { subMenu } from '@statics/menu';

function switchToSubMenuKey(current) {
  const obj = (key) => Object.values(subMenu[key]).map((r) => r.path);
  const some = (f) => (key) => (x) => f(key).some((r) => x.startsWith(r));
  const starts = some(obj);

  if (current === '/') {
    return `(주)카라멜라, 딥러닝, 머신러닝, 소프트웨어, metu, 디지털 성범죄, 데이터 크롤러, 크롤링, 데이터, AI, 광주 AI, 광주 인공지능, 인공지능, 광주, 광주광역시, 웹, 웹개발, 앱, 앱개발, 동구, 광주광역시 동구,  caramella, caramella Inc., 금남로, 광주광역시 동구 금남로, ${TelText}, saas, 얼굴인식, 웹사이트`;
  }

  if (starts('company')(current)) {
    return '인사말, 연혁, 조직도, 기업 인증서, 오시는길, (주)카라멜라, 카라멜라, 소프트웨어 개발';
  }

  if (starts('branch')(current)) {
    return '카라멜라, (주)카라멜라, 카라멜라 사업분야, 사업분야, 웹서비스, SaaS, 딥러닝, 얼굴인식, AI, 인공지능, 데이터, 가공, 데이터가공, 데이터 수집, 크롤링, 크롤러';
  }

  if (starts('develop')(current)) {
    return '소프트웨어 개발, 소프트웨어 개발 문의, 이메일, 인터넷, 인터넷 소프트웨어 개발, 앱개발, 웹개발, 웹사이트 개발, 딥러닝 개발, 머신러닝 개발, 인공지능 개발, 얼굴인식, 인공지능, AI, 크롤링, 크롤러, 데이터 파싱, 파싱';
  }

  if (starts('portfolio')(current)) {
    return '카라멜라, (주)카라멜라, 포트폴리오';
  }

  if (starts('notice')(current)) {
    return '카라멜라, (주)카라멜라, 공지사항, 사업실적, 연구소 소식, 기업부설연구소, 연구소, 보도자료, 미투, 공시, 기업, 기업자료';
  }

  return '';
}

function Head({ pathname }) {
  const [isAndroid, setIsAndroid] = React.useState(true);

  React.useEffect(() => {
    if (!navigator.userAgent.toLowerCase().includes('android')) {
      setIsAndroid(false);
    }
  }, []);

  return (
    <Helmet>
      {!isAndroid && (
        <meta
          name='viewport'
          content='initial-scale=1.0,maximum-scale=1,width=device-width'
        />
      )}
      <meta name='keywords' content={switchToSubMenuKey(pathname)} />
    </Helmet>
  );
}

export default React.memo(Head);
