import React from 'react';
import styled from 'styled-components';

import { portfolioes } from '@statics/portfolio';

function PortfolioSection() {
  return (
    <>
      <PortfolioSectionContent>
        <div className='port-section'>
          <div className='port-images'>
            {portfolioes.map((key, index) => (
              <PortfolioBackground image={key.img} key={index}>
                <div className='portfolio-darken'></div>
              </PortfolioBackground>
            ))}
          </div>
        </div>
      </PortfolioSectionContent>
    </>
  );
}

const PortfolioSectionContent = styled.section`
  /* box-sizing: border-box; */
  margin: 0 1rem 4rem;

  .port-section {
    border: 1px solid #ccc;
    padding: 10px;
  }
  .port-images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.5rem;
    row-gap: 0.5rem;

    margin: 0 auto;
  }
`;
export const PortfolioBackground = styled.div`
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 1.54 / 1;
  .portfolio-darken {
    width: 100%;
    height: 100%;
    display: none;
    font-weight: 900;
  }
`;

export default React.memo(PortfolioSection);
