import React from 'react';
import { useLocation } from 'react-router';
import MobileHeader from '../../../components/mobile/Header';
import MobileBanner from '../../../components/mobile/home/Banner';
import MtopSection from '../../../components/mobile/home/TopSection';
import MmidSection from '../../../components/mobile/home/MidSection';
import MbotSection from '../../../components/mobile/home/BotSection';
import MboileFooter from '../../../components/mobile/Footer';
import Head from '../../../components/Head';

function mobileHome() {
  const { pathname } = useLocation();
  return (
    <>
      <Head pathname={pathname} />
      <MobileHeader />
      <MobileBanner />
      <MtopSection />
      <MmidSection />
      <MbotSection />
      <MboileFooter />
    </>
  );
}
export default React.memo(mobileHome);
