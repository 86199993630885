import styled from 'styled-components';

const HorizonBar = styled.div`
  width: ${(props) => `${props.width}px` || `${50}px`};
  height: ${(props) => `${props.height}px` || `${1}px`};
  background-color: ${(props) => props.bgColor || '#08587D'};
  /* margin-top: 20px; */
  margin-bottom: 20px;
  align-self: center;
`;

export default HorizonBar;
