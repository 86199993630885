import React from 'react';
import ReactDOM, { hydrate } from 'react-dom';
import App from './App';

const root = document.getElementById('root');

if (root.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    root,
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    root,
  );
}
