import React from 'react';
import styled from 'styled-components';
import contactImage from 'public/images/contact.png';
import MapPin from 'public/images/mapPin.svg';
import Phone from 'public/images/phone.svg';
import Mail from 'public/images/mail.svg';
import SVGFactory from './SVGFactory';

function Contact() {
  return (
    <ContactContainer>
      <ContactItem>
        <div className='contact-background'>
          <div className='contact-mark'>
            <div className='line'></div>
            <p className='letter'>CONTACT</p>
          </div>
          <div className='contact-listItem'>
            <div className='contact-listItem-wrapper'>
              <div className='contact-listItem-icon'>
                <SVGFactory Icon={Phone} width={24} height={24} />
              </div>
              <div className='contact-listItem-letter'>
                <p className='contact-letter-bold'>TEL.</p>
                <p>062-714-3626</p>
              </div>
            </div>
          </div>
          <div className='contact-listItem'>
            <div className='contact-listItem-wrapper'>
              <div className='contact-listItem-icon'>
                <SVGFactory Icon={Mail} width={24} height={24} />
              </div>
              <div className='contact-listItem-letter'>
                <p className='contact-letter-bold'>E-mail</p>
                <p>PM@caramella.kr</p>
              </div>
            </div>
          </div>
          <div className='contact-listItem'>
            <div className='contact-listItem-wrapper'>
              <div className='contact-listItem-icon'>
                <SVGFactory Icon={MapPin} width={24} height={24} />
              </div>
              <div className='contact-listItem-letter'>
                <p className='contact-letter-bold'>Address</p>
                <p>{'광주광역시 동구 금남로 193-12, 702호'}</p>
              </div>
            </div>
          </div>
        </div>
      </ContactItem>
    </ContactContainer>
  );
}

export const ContactContainer = styled.div`
  max-width: 1920px;
  min-width: 1280px;
  background: url(${contactImage}) no-repeat rgba(0, 0, 0, 0.5);
  background-size: cover;
  margin: 0 auto;
`;

const ContactItem = styled.div`
  .contact-background {
    display: flex;
    height: 250px;
    background-color: rgba(2, 21, 67, 0.56);
  }
  .contact-mark {
    border-right: 1px solid #fff;
    box-sizing: border-box;
    width: 370px;
    min-width: 370px;
    padding-left: 30px;
    padding-top: 60px;
    .line {
      width: 48px;
      height: 3px;
      background-color: #fff;
      margin-bottom: 30px;
    }
    .letter {
      font-size: 50px;
      color: #fff;
      line-height: 1.4;
      margin: 0;
      font-weight: 600;
    }
  }
  .contact-listItem {
    padding: 40px;
    padding-left: 0;
    padding-right: 0;
    border-right: 1px solid #fff;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    &:last-child {
      border-right: none;
    }
    .contact-listItem-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      flex: 1;
      padding-left: 30px;
      padding-right: 30px;
    }
    .contact-listItem-icon {
      display: flex;
      justify-content: flex-end;
      svg {
        transform: scale(1.5);
        fill: #fff;
      }
    }
    .contact-listItem-letter {
      font-size: 17px;
      img {
        width: 125px;
        margin-top: 5px;
        margin-bottom: 14px;
      }
      .contact-letter-bold {
        font-weight: 900;
      }
    }
  }
`;

export default React.memo(Contact);
