import React from 'react';
import certificationImage from 'public/images/certification.png';
import Common from '../../../layout/desktop/Common';
import { ContentAlign } from '../../../components/desktop/company/styles';

function Cert() {
  return (
    <Common>
      <ContentAlign>
        <img src={certificationImage} alt={'history'} width={'70%'} />
      </ContentAlign>
    </Common>
  );
}

export default React.memo(Cert);
