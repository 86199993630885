import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import Slider from 'react-slick';
import styled from 'styled-components';
import axios from 'axios';

import ArrowRight from 'public/images/arrowRight.png';
import ArrowLeft from 'public/images/arrowLeft.png';
import Bar from 'public/images/bar.png';

import { baseUrl } from '@config/config';

import { theme } from '@utils/theme';
import { flexAlign, pixelToRem } from '@utils/commonStyles';

function switchTitle(title) {
  switch (title) {
    case 'bps':
      return '사업 실적';
    case 'labNews':
      return '연구소 소식';
    case 'notices':
      return '공지사항';
    default:
      return '';
  }
}

function switchURL(url, id) {
  switch (url) {
    case 'bps':
      return `/perform/${id}`;
    case 'labNews':
      return `/labnews/${id}`;
    case 'notices':
      return `/notice/${id}`;
    default:
      return '';
  }
}

const settings = {
  dots: true,
  infinite: true,
  speed: 450,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const tagRegExp = new RegExp(
  /<\/*p>|<\/*h1>|<\/*h2>|<\/*h3>|<\/*h4>|<\/*h5>|<\/*h6>|<\/*br>|<\/*strong>|<\/*i>|<\/*b>|<\/*small>|<\/*span>|<\/*em>/g,
);

function mobileBotSection() {
  const [list, getList] = useState([]);
  const history = useHistory();

  const onShowBoard = useCallback(async () => {
    try {
      const result = await axios.get(`${baseUrl}/main`);
      if (result.data.status === 200) {
        const data = result.data.data.board;
        getList(data);
      }
      throw new Error('error');
    } catch (err) {
      // const a = 1;
    }
  }, [baseUrl]);

  const onMove = useCallback(
    (url) => () => {
      history.push(`${url}`);
    },
    [history],
  );

  useEffect(() => {
    onShowBoard();
  }, []);

  return (
    <>
      <MobileBotSide>
        {/* <StyleSlider {...settings}> */}
        <Slider useCSS {...settings}>
          {Object.entries(list)
            .slice(0)
            .reverse()
            .map(([key, value]) => {
              const title = value.slice(0, 1).pop()?.title;
              const isExistBracket =
                value.length > 1 &&
                title.includes(']') &&
                title.split(']').length > 1;
              return (
                <Notice key={key}>
                  <div className='notice-top'>
                    <div className='main_title'>
                      <img src={Bar} alt='bar' />
                      {switchTitle(key)}
                    </div>
                    <div className='sub_title'>
                      {isExistBracket ? title.split(']')[0].concat(']') : title}
                      <br />
                      {isExistBracket ? title.split(']')[1] : ''}
                    </div>
                  </div>
                  <div className='notice-top'>
                    {value && value.slice(1) ? (
                      value.slice(0, 1).map((post) => (
                        <div key={post.id} className='prev-list'>
                          <div className='prev-notice'>
                            {
                              post.contents
                                .replace(tagRegExp, ' ')
                                .split('.')[0]
                            }
                          </div>
                          <span
                            className='more-info'
                            role='presentation'
                            onClick={onMove(switchURL(key, post.id))}>
                            자세히보기&nbsp;&nbsp;&gt;&gt;
                          </span>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </Notice>
              );
            })}
        </Slider>
      </MobileBotSide>
    </>
  );
}

const MobileBotSide = styled.section`
  height: 10.3rem;
  background-color: ${theme.background.lightGray};
  padding: 15px 0;

  .slick-list {
    margin-top: 0.5rem;
  }

  .slick-slide > div {
    padding: 0 0.5rem;
  }

  .slick-arrow::before {
    font-weight: 500;
    font-size: 14px;
    ${flexAlign()};
  }
  .slick-prev {
    margin-left: 2.5rem;
    margin-top: auto;
    height: 1.5rem;
    width: 1.5rem;
    z-index: 1;
    display: flex;
    background-image: url(${ArrowLeft});
    &::before {
      display: none;
    }
  }
  .slick-next {
    z-index: 1;
    margin-top: auto;
    margin-right: 2.5rem;
    height: 1.5rem;
    width: 1.5rem;
    background-image: url(${ArrowRight});

    &::before {
      display: none;
    }
  }
  .slick-dots {
    .slick-active {
      button::before {
        color: ${theme.color.gray707070};
      }
    }
    button::before {
      color: ${theme.color.gray};
      display: flex;
      margin-top: -0.2rem;
    }
    .slick-active button:before {
    }
  }
`;

const Notice = styled.article`
  border: 1px solid ${theme.border.gray};
  background-color: ${theme.background.white};
  height: 9rem;
  position: relative;
  display: flex;
  margin-left: -1px;
  .notice-top {
    padding: 0.3rem;
    margin-left: 1.5rem;

    .main_title {
      display: flex;
      margin-top: ${pixelToRem(11)};
      font-size: 16px;
      font-weight: 700;
      @media only all and (min-width: 630px) {
        font-size: 18px;
      }
    }
    .sub_title {
      margin-top: ${pixelToRem(5)};
      margin-left: 0.5rem;
      font-size: 12px;
      line-height: 1.6;
      font-weight: 600;
      @media only all and (min-width: 630px) {
        font-size: 14px;
      }
    }
    .prev-notice {
      width: 90%;
      margin-left: 0.5rem;
      color: ${theme.color.gray707070};
      font-size: 10px;
      font-weight: 600;
      margin-top: -3px;
      @media only all and (min-width: 630px) {
        font-size: 12px;
      }
    }
  }

  img {
    margin-right: ${pixelToRem(6)};
    height: ${pixelToRem(17)};
  }

  .more-info {
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: flex-end;
    margin-right: 20px;
    /* position: absolute; */
    color: ${theme.color.baseColor};
    /* right: 9%;
    bottom: 10%; */
    font-size: 11px;
    font-weight: bold;
    @media only all and (min-width: 630px) {
      font-size: 13px;
    }
  }
`;

export default React.memo(mobileBotSection);
