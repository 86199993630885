import React, { useCallback } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router';
import ReactQuill from 'react-quill';

import { baseUrl } from '@config/config';

import { useQuill } from '@statics/quillModules';
import { subMenu } from '@statics/menu';

import useText from '@hooks/useText';
import useChangeString from '@hooks/useChangeString';

import getFirstUrl from '@utils/getFirstUrl';

import { CreateContent, FileTag } from './style';
import './quill.snow.css';

function postTypeSwitch(url) {
  switch (url) {
    case 'perform':
      return 'business_performance';
    case 'media':
      return 'press';
    case 'lab_news':
      return 'lab_news';
    default:
      return url;
  }
}

function Create() {
  const [textEditor, onTextEditor, textContents] = useText();
  const [title, onTitle] = useChangeString();
  const [author, onAuthor] = useChangeString();

  const [uploadedFileNames, setUploadedFileNames] = React.useState([]);
  const [uploadFiles, setUploadFiles] = React.useState([]);

  const location = useLocation();
  const history = useHistory();
  const { QuillRef } = useQuill();

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onUpload = useCallback((e) => {
    if (!e.target.files) {
      return;
    }

    if (!e.target.files[0]) {
      return;
    }

    if (
      Array.from(e.target.files).some((file) => file.size > 100 * 1024 * 1024)
    ) {
      return;
    }

    Array.from(e.target.files).forEach((file) => {
      setUploadFiles((prev) => prev.concat(file));
      setUploadedFileNames((prev) => prev.concat(file.name));
    });
  }, []);

  const onRemove = useCallback((filename) => {
    setUploadedFileNames((prev) => prev.filter((v) => v !== filename));
    setUploadFiles((prev) => prev.filter((x) => x.name !== filename));
  }, []);

  const onCreate = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const token = sessionStorage.getItem('token');
        if (
          !title.trim() ||
          !author.trim() ||
          !textContents.trim().replace(/\s/g, '') ||
          !token
        ) {
          return;
        }

        const formData = new FormData();

        formData.append('title', title.trim());
        formData.append('contents', textEditor);

        formData.append(
          'postType',
          postTypeSwitch(getFirstUrl(location.pathname)),
        );

        if (uploadFiles.length > 0) {
          uploadFiles.forEach((uploadFile) => {
            formData.append(uploadFile.name, uploadFile);
          });
        }

        const result = await axios.post(`${baseUrl}/post`, formData, {
          headers: { token, 'Content-Type': 'multipart/form-data' },
        });

        if (result.data.status === 200) {
          history.push(`/${getFirstUrl(location.pathname)}`);
        } else {
          throw new Error('error');
        }
      } catch (err) {
        // const b = 3;
      }
    },
    [location, title, author, textContents, textEditor, history, uploadFiles],
  );

  return (
    <>
      <CreateContent>
        <form encType={'multipart/form-data'} onSubmit={onCreate}>
          <div className='notice-text'>
            {`${
              Object.values(subMenu)
                .flat()
                .find((x) => location.pathname.startsWith(x.path))?.title
            } 글 등록`}
          </div>
          <div className='input-field'>
            <div className='left-field'>
              <span>*</span>제목
            </div>
            <input
              type='text'
              className='right-field'
              value={title}
              onChange={onTitle}
            />
          </div>
          <div className='input-field'>
            <div className='left-field'>
              <span>*</span>작성자
            </div>
            <input
              type='text'
              className='right-field'
              value={author}
              onChange={onAuthor}
            />
          </div>
          <div className='textarea-field'>
            <div className='left-field'>
              <span>*</span>공지 내용
            </div>
            <ReactQuill
              ref={(element) => {
                if (element !== null) {
                  QuillRef.current = element;
                }
              }}
              modules={{ toolbar: false }}
              theme='snow'
              value={textEditor}
              onChange={onTextEditor}
            />
          </div>
          <div className='input-field'>
            <div className='left-field'>첨부파일</div>
            <div className='label-field'>
              <input
                hidden
                id='upload-files'
                type='file'
                accept='image/*'
                multiple
                onChange={onUpload}
              />
              <label htmlFor='upload-files' className='upload-label'>
                파일선택
              </label>
              {uploadedFileNames.map((v) => (
                <FileTag key={v}>
                  <span>{v}</span>
                  <span
                    role='presentation'
                    className='cancel-imoji'
                    onClick={() => onRemove(v)}
                    onKeyUp={() => {}}>
                    {String.fromCharCode(0x1f5d9)}
                  </span>
                </FileTag>
              ))}
            </div>
          </div>
          {/* <SubmitButton> */}
          <div className='line'></div>
          <div className='buttons'>
            <button type='submit'>등록</button>
            <button type='button' onClick={onCancel}>
              닫기
            </button>
          </div>
          {/* </SubmitButton> */}
        </form>
      </CreateContent>
    </>
  );
}

export default React.memo(Create);
