import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Logo from 'public/images/logo.png';

import { header, subMenu } from '@statics/menu';

import Submenu from './Submenu';
// import SVGFactory from './SVGFactory';

export const LogoComponent = styled.img`
  /* aspect-ratio: 228 / 26; */
`;

function Header({ isExistMenu = true }) {
  const [isHover, setIsHover] = useState(false);
  const history = useHistory();

  const onClick = useCallback(() => {
    history.push('/');
  }, [history]);

  const onMouseEnter = useCallback(() => {
    setIsHover(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  return (
    <HeaderWrapper>
      <HeaderContainer onMouseLeave={onMouseLeave}>
        <HeaderItem>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <LogoComponent
            src={Logo}
            alt={'Logo'}
            onClick={onClick}
            onKeyUp={() => {}}
          />
          {/* <SVGFactory Icon={Logo} height={26} width={} /> */}
          {isExistMenu ? (
            <ul className='main-menu'>
              {header.map((v) => (
                <SmallMenu
                  isSelected={subMenu[v.key]
                    .map((c) => c.path)
                    .some((k) => history.location.pathname.startsWith(k))}
                  key={v.text}
                  onMouseEnter={onMouseEnter}>
                  {v.text}
                </SmallMenu>
              ))}
            </ul>
          ) : (
            <></>
          )}
        </HeaderItem>
        {isExistMenu ? <Submenu isHover={isHover} /> : <></>}
      </HeaderContainer>
    </HeaderWrapper>
  );
}
export const HeaderWrapper = styled.nav`
  max-width: 1920px;
  min-width: 1280px;
  margin: 0 auto;
`;
export const HeaderContainer = styled.div`
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const HeaderItem = styled.header`
  min-width: 1280px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 40px;
  background-color: #fff;
  img {
    cursor: pointer;
    max-height: 26px;
  }
  .main-menu {
    display: flex;
    margin-right: 50px;
    padding: 0;
  }
`;

export const SmallMenu = styled.li`
  margin-left: 50px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 900;
  text-align: center;
  color: ${(props) => (props.isSelected ? '#08587D' : '#000')};
  &:nth-child(1) {
    width: 100px;
  }
  &:nth-child(2) {
    width: 180px;
  }
  &:nth-child(3) {
    width: 120px;
  }
  &:nth-child(4) {
    width: 120px;
  }
  &:nth-child(5) {
    width: 85px;
  }
  &:hover {
    color: #08587d;
  }
  .sub-menu {
    display: none;
    li {
      font-size: 17px;
    }
  }
`;

export default React.memo(Header);
