import styled from 'styled-components';
import { theme } from '../../../utils/theme';
import { pixelToRem, flexAlign } from '../../../utils/commonStyles';

export const TopContainer = styled.div`
  ${flexAlign(undefined, undefined, 'space-between')};
  flex: 1;
  margin-bottom: ${pixelToRem(30)};

  p {
    ${flexAlign(undefined, undefined, undefined, 'flex-end')};
    margin: 0;
    font-size: ${pixelToRem(17)};
    font-weight: 600;
    color: ${theme.color.black};
  }

  .searchBox {
    height: ${pixelToRem(40)};
    box-sizing: border-box;
    border: 1px solid ${theme.border.gray};
    background-color: ${theme.background.white};
    ${flexAlign(undefined, undefined, 'space-between')};
    padding: ${pixelToRem(16)};
    border-radius: ${pixelToRem(10)};
    flex: 0.15;

    input {
      flex: 1;
      outline: none;
      border: none;
      &::placeholder {
        color: ${theme.color.gray707070};
      }
    }
    svg {
      cursor: pointer;
    }
  }
`;

export const TableContainer = styled.div`
  flex: 1;
  ${flexAlign('column', undefined, 'stretch', 'stretch')};
`;

export const HeadGrid = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: ${(props) =>
    props.isExistPermission
      ? '1fr 5fr 1.5fr 1.5fr 1fr 1fr'
      : '1fr 5fr 2fr 2fr'};
  grid-template-rows: 1fr;
  background-color: ${theme.background.grayE4e4e4};
  box-sizing: border-box;
  border-top: 2px solid ${theme.border.grayC0c0c0};
  border-bottom: 1px solid ${theme.border.gray};
  border-left: 1px solid ${theme.border.gray};

  & > div {
    box-sizing: border-box;
    border-right: 1px solid ${theme.border.gray};
    ${flexAlign()};
    padding: ${pixelToRem(5)} ${pixelToRem(5)};
    height: 40px;
    margin: 0;
  }

  & > div:nth-child(2) {
    justify-content: flex-start;
  }
`;

export const BodyGrid = styled(HeadGrid)`
  background-color: ${theme.background.white};
  border-left: 1px solid ${theme.border.gray};
  border-top: none;

  & > div {
    overflow: hidden;
  }

  & > div:nth-child(2) {
    cursor: pointer;
  }

  button {
    background-color: ${theme.background.white};
    ${flexAlign()};
    font-size: ${pixelToRem(17)};
    outline: none;
    box-sizing: border-box;
    height: ${pixelToRem(30)};
    width: ${pixelToRem(50)};
    cursor: pointer;
    border: 1px solid ${theme.border.black};
    &:active {
      background: ${theme.background.baseColor};
      border: none;
      color: ${theme.color.white};
    }
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.isExistPermission ? 'space-between' : 'center'};
  align-items: center;
  margin-top: ${pixelToRem(30)};

  button.create {
    width: ${pixelToRem(133)};
    height: ${pixelToRem(40)};
    ${flexAlign()};
    color: ${theme.color.white};
    background-color: ${theme.background.baseColor};
    border: none;
    outline: none;
    box-sizing: border-box;
    cursor: pointer;
  }
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${pixelToRem(30)} ${pixelToRem(50)};

  .input-field {
    display: flex;
    height: 40px;
    margin-bottom: 16px;

    &:last-child {
      margin-top: 16px;
    }

    .left-field {
      width: 130px;
      display: flex;
      align-items: center;
      color: #8c8c9b;
      font-size: 17px;
    }
    .right-field {
      flex: 8;
      box-sizing: border-box;
      border: 1px solid #ccc;
      background-color: #fff;
      color: #000;
      font-size: 17px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      outline: none;
    }
    .label-field {
      flex: 8;
      display: flex;
      align-items: center;
      .upload-label {
        height: 40px;
        width: 133px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #08587d;
        outline: none;
        cursor: pointer;
        border: none;
        font-size: 17px;
        margin-right: 16px;
      }
    }
  }
  .textarea-field {
    display: flex;

    .left-field {
      width: 130px;
      display: flex;
      color: #8c8c9b;
      font-size: 17px;
      margin-top: 13px;
    }

    .right-field {
      flex: 8;
      max-height: 430px;
      height: 430px;
    }
  }
`;

export const FileTag = styled.div`
  border-radius: 17px;
  height: 26px;
  border: 1px solid #707070;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #707070;
  margin-right: 16px;
  padding: 4px 8px;
  font-size: 17px;

  .cancel-imoji {
    display: flex;
    align-items: center;
    color: #707070;
    margin-left: 10px;
    font-size: 17px;
    cursor: pointer;
  }
`;

export const CreateContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #ccc;
  background-color: #fff;

  .create-header {
    height: 70px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    font-size: 20px;
    border-bottom: 1px solid #ccc;
  }

  .create-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 91px;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
    padding-right: 50px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 133px;
      border: none;
      outline: none;
      cursor: pointer;
      box-sizing: border-box;
      background-color: #08587d;
      font-size: 17px;
      &:first-child {
        color: #fff;
      }
      &:last-child {
        color: #08587d;
        background-color: #fff;
        border: 1px solid #08587d;
        margin-left: 15px;
      }
    }
  }
`;

export const ReadContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #ccc;
  background-color: #fff;
  a {
    color: #08587d;
    font-weight: 700;
    word-break: break-all;
  }

  .read-header {
    height: 70px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    font-size: 20px;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
    font-weight: 900;
    color: #000;
  }

  .read-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 91px;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
    padding-left: 20px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 133px;
      outline: none;
      cursor: pointer;
      box-sizing: border-box;
      border: 1px solid #08587d;
      color: #08587d;
      background-color: #fff;
      font-size: 17px;
      font-weight: 600;
    }
  }
`;

export const ReadContent = styled.div`
  padding: 20px;
`;

export const FileContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  .addedFile {
    margin-right: 12px;
    font-size: 17px;
    color: #000;
    display: flex;

    .file-icon {
      width: 17px;
      height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        transform: scale(0.7);
      }
    }
  }

  .file-items {
    color: #08587d;
    margin: 0;
    margin-bottom: 12px;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: 1px solid #08587d;
    border-radius: 16px;
    padding: 5px 10px;
    font-size: 17px;
    margin-right: 10px;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const BodyGridNoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  background-color: #fff;
  box-sizing: border-box;
  height: 180px;
  font-size: 20px;
  color: #000;
`;
