import React from 'react';
import Common from '../../../layout/mobile/Common';
import AddressSection from '../../../components/mobile/company/AddressSection';

function Address() {
  return (
    <>
      <Common>
        <AddressSection />
      </Common>
    </>
  );
}
export default React.memo(Address);
