import React from 'react';
import Common from '../../../layout/mobile/Common';
import OrganizationSection from '../../../components/mobile/company/OrganizationSection';

function Organization() {
  return (
    <>
      <Common>
        <OrganizationSection />
      </Common>
    </>
  );
}
export default React.memo(Organization);
