import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router';

import commonBanner from 'public/images/commonBanner.jpeg';

import { header, subMenu } from '@statics/menu';

import Footer from '../../components/desktop/Footer';
import Header from '../../components/desktop/Header';
import Head from '../../components/Head';
import VerticalBar from '../../components/desktop/VerticalBar';

function Common({ children, isExistBar = true, isExistSectionHeader = true }) {
  const location = useLocation();
  const history = useHistory();
  const onClick = useCallback(
    (path) => {
      history.push(path);
    },
    [history],
  );

  return (
    <>
      {/* <meta name='keywords' content='asdfasdf' /> */}
      <Head pathname={history.location.pathname} />
      <Header />
      <TopBanner>
        <h2>{`${
          header.find((k) => {
            const obj = Object.entries(subMenu).find(([, arr]) =>
              arr.find((v) => location.pathname.startsWith(v.path)),
            )[0];
            return k.key === obj;
          }).text || '회사소개'
        }`}</h2>
        <p>{`${
          header.find(
            (k) =>
              k.key ===
              Object.entries(subMenu).find(([, arr]) =>
                arr.find((v) => location.pathname.startsWith(v.path)),
              )[0],
          )?.text || '회사소개'
        } ${String.fromCharCode(62)} ${
          Object.values(subMenu)
            .flat()
            .find((arr) =>
              arr.path.startsWith(
                `/${location.pathname.split('/').filter((c) => c)[0]}`,
              ),
            )?.title || '인사말'
        }`}</p>
      </TopBanner>
      <TopTabNavigator>
        {Object.values(subMenu)
          .map((x) =>
            x
              .map((k) => location.pathname.startsWith(k.path))
              .some((z) => z === true),
          )
          .map((p, q) => {
            if (p === true) return q;
            return undefined;
          })
          .filter((x) => x !== undefined)
          .map((k) => Object.values(subMenu)[k])
          .flat()
          .map((element) => (
            <TopTabButton
              type='button'
              onClick={() => onClick(element.path)}
              key={element.title}
              isSelected={location.pathname.startsWith(element.path)}>
              {element.title}
            </TopTabButton>
          ))}
      </TopTabNavigator>
      <ChildrenContainer>
        {isExistSectionHeader ? (
          <div className='section-header'>
            <h1>
              {
                Object.values(subMenu)
                  .flat()
                  .find((v) =>
                    v.path.startsWith(
                      `/${location.pathname.split('/').filter((c) => c)[0]}`,
                    ),
                  )?.title
              }
            </h1>
            <h3>
              {
                Object.values(subMenu)
                  .flat()
                  .find((v) =>
                    v.path.startsWith(
                      `/${location.pathname.split('/').filter((c) => c)[0]}`,
                    ),
                  )?.subTitle
              }
            </h3>
          </div>
        ) : null}
        {isExistBar ? <VerticalBar width={1} height={50} /> : null}
        {children}
      </ChildrenContainer>
      <Footer />
    </>
  );
}

export const TopBanner = styled.div`
  background-image: url(${commonBanner});
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  min-width: 1280px;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  h2 {
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 16px;
  }
  p {
    font-size: 17px;
    margin: 0;
  }
`;

export const TopTabNavigator = styled.div`
  height: 70px;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
`;
export const TopTabButton = styled.button`
  & {
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-size: 17px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: transparent;
    margin-right: 30px;
    color: ${(props) => (props.isSelected ? '#08587d' : '#707070')};
    font-weight: ${(props) => (props.isSelected ? 900 : 600)};
    position: relative;
    height: 100%;
    &:last-child {
      margin-right: 0;
    }
    &::before {
      position: absolute;
      bottom: 0;
      content: '';
      width: 100%;
      height: ${(props) => (props.isSelected ? '5px' : '0px')};
      background-color: #08587d;
    }
    /* &:hover {
      color: #08587d;
      font-weight: 900;
      &:hover::before {
        position: absolute;
        bottom: 0;
        content: '';
        width: 100%;
        height: 5px;
        background-color: #08587d;
      }
    } */
  }
`;

export const ChildrenContainer = styled.div`
  box-sizing: border-box;
  max-width: 1920px;
  margin: 0 auto;
  /* margin-top: 50px; */
  padding: 100px 50px 200px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .section-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      margin: 0;
      font-size: 40px;
      font-weight: 900;
      line-height: 2;
    }
    h3 {
      margin: 0;
      font-size: 25px;
      font-weight: 600;
      color: #707070;
      line-height: 1.5;
    }
  }
`;

export default React.memo(Common);
