import BebeLiebe from 'public/images/portfolio/bebeLiebe.png';
import Covid19 from 'public/images/portfolio/covid19.png';
// import Docean from 'public/images/portfolio/docean.png';
import DongGu from 'public/images/portfolio/dongGu.png';
import JoyNco from 'public/images/portfolio/joyNco.png';
import KimBab from 'public/images/portfolio/kimbab.png';
import MarryBee from 'public/images/portfolio/marryBee.png';
import Molly from 'public/images/portfolio/molly.png';
import Mulit from 'public/images/portfolio/multimediaCorpus.png';
import Self from 'public/images/portfolio/selfieToy.png';
// import voucher from 'public/images/portfolio/voucher.png';
import idmasking from 'public/images/portfolio/idmasking.png';
import metu from 'public/images/portfolio/meetu.png';
import metaResearch from 'public/images/portfolio/metaResearch.png';

export const portfolioes = [
  {
    img: BebeLiebe,
    width: '100%',
    text: 'BEBELIEBE',
  },
  { img: JoyNco, width: '100%', text: 'JOY&CO' },
  {
    img: Mulit,
    width: '100%',
    text: 'Multimediacorpus.com',
  },
  { img: MarryBee, width: '100%', text: 'MarryBee.com' },
  { img: Self, width: '100%', text: 'SelfieToy.co.kr' },
  { img: Covid19, width: '100%', text: 'CoronaStatus.com' },
  {
    img: KimBab,
    width: '100%',
    text: 'Kimbab.com',
  },
  { img: Molly, width: '100%', text: 'Molly.kr' },
  {
    img: DongGu,
    width: '100%',
    text: 'donggusports.kr',
  },
  /*{
    img: Docean,
    width: '100%',
    text: 'D-ocean.kr',
  },*/
  {
    img: metu,
    width: '100%',
    text: 'METU',
  },
  {
    img: idmasking,
    width: '100%',
    text: 'ID-Masking',
  },
  {
    img: metaResearch,
    width: '100%',
    text: 'META RESEARCH',
  },
].reverse();
