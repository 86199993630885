import React from 'react';
import OrganizationImage from 'public/images/organization.png';
import Common from '../../../layout/desktop/Common';
import { ContentAlign } from '../../../components/desktop/company/styles';

function Organization() {
  return (
    <Common>
      <ContentAlign>
        <img src={OrganizationImage} alt={'history'} width={'80%'} />
      </ContentAlign>
    </Common>
  );
}

export default React.memo(Organization);
