export default function parseJwt(token) {
  if (!token) {
    return undefined;
  }
  let jsonPayload = '';
  if (token) {
    const base64Url = token
      .split('.')[1]
      ?.replace(/-/g, '+')
      .replace(/_/g, '/');
    atob(base64Url)
      .split('')
      .forEach((c) => {
        jsonPayload += `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      });
    jsonPayload = decodeURIComponent(jsonPayload);
  }

  return JSON.parse(jsonPayload);
}

export function showToken() {
  const token = sessionStorage.getItem('token');
  if (token) {
    const parsedToken = parseJwt(token);
    if (parsedToken) {
      return parsedToken;
    }
    return undefined;
  }
  return undefined;
}
