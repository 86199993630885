import React from 'react';
import './global.css';
import { isBrowser } from 'react-device-detect';
import { HelmetProvider } from 'react-helmet-async';
import DesktopDisplay from './DesktopDisplay';
import MobileDisplay from './MobileDisplay';

function App() {
  // alert(navigator.userAgent.toLowerCase().indexOf('mobile'));
  if (isBrowser) {
    return (
      <>
        <HelmetProvider>
          <DesktopDisplay />
        </HelmetProvider>
      </>
    );
  }

  return (
    <HelmetProvider>
      <MobileDisplay />
    </HelmetProvider>
  );
}

export default App;
