import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Building from 'public/images/building.svg';
import Command from 'public/images/command.svg';
import Chat from 'public/images/chat.svg';

import { theme } from '@utils/theme';
import { flexAlign } from '@utils/commonStyles';

import SVGFactory from './SVGFactory';

function mobileMidSection() {
  return (
    <>
      <MobileMidSide>
        <div className='container'>
          <div className='box_1'>
            <div>
              <Link to='/greeting' className='iconbox'>
                <div className='icon'>
                  <SVGFactory
                    Icon={Building}
                    alt='what'
                    width={24}
                    height={24}
                  />
                </div>
              </Link>
              <h1>회사소개</h1>
              <p>
                (주)카라멜라는 IT융합기술을
                <br />
                바탕으로 성장하는 회사입니다.
              </p>
            </div>
            <Link to='/greeting' className='moreinfo'>
              자세히보기&nbsp;&gt;&gt;
            </Link>
          </div>
          <div className='box_1'>
            <div>
              <Link to='/business' className='iconbox'>
                <div className='icon'>
                  <SVGFactory
                    Icon={Command}
                    alt='what'
                    width={24}
                    height={24}
                  />
                </div>
              </Link>
              <h1>사업분야</h1>
              <p>빅데이터를 활용한 앞선 기술로 S/W 서비스를 만들어 갑니다</p>
            </div>
            <Link to='/business' className='moreinfo'>
              자세히보기&nbsp;&gt;&gt;
            </Link>
          </div>
          <div className='box_1'>
            <div>
              <Link to='/develop' className='iconbox'>
                <div className='icon'>
                  <SVGFactory Icon={Chat} alt='what' width={24} height={24} />
                </div>
              </Link>
              <h1>개발문의</h1>
              <p>고객님의 니즈에 맞게 최선을 다해 개발 해드립니다.</p>
            </div>
            <Link to='/develop' className='moreinfo'>
              자세히보기&nbsp;&gt;&gt;
            </Link>
          </div>
        </div>
      </MobileMidSide>
    </>
  );
}

const MobileMidSide = styled.section`
  text-align: center;
  .container {
    height: 13.725rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @media only all and (min-width: 1024px) {
      height: 16rem;
    }
    h1 {
      width: auto;
      margin-top: 20px;
      font-size: 13px;
      font-weight: 600;
      @media only all and (min-width: 630px) {
        font-size: 17px;
      }
      @media only all and (min-width: 1024px) {
        font-size: 19px;
      }
    }
    p {
      margin-top: -1px;
      font-size: 8px;
      color: ${theme.color.gray707070};
      @media only all and (min-width: 630px) {
        font-size: 10px;
        margin-top: -8px;
      }
      @media only all and (min-width: 1024px) {
        font-size: 13px;
      }
    }
  }

  .box_1 {
    padding-top: 20px;
    padding-bottom: 10px;
    flex: 1;
    ${flexAlign('column', 'flex', 'space-between')};
    border-right: 1px solid ${theme.border.gray};

    :last-child {
      border: none;
    }

    & > div {
      display: flex;
      flex-direction: column;
    }
  }

  .iconbox {
    margin: 0 auto;
    width: 5.438rem;
    height: 5.5rem;
    border-radius: 50%;
    background-color: ${theme.background.baseColor};
    position: relative;
  }

  .icon {
    position: absolute;
    top: 14.2%;
    left: 15.5%;
    margin: 0 auto;
    width: 3.75rem;
    height: 3.938rem;
    border-radius: 50%;
    background-color: ${theme.background.white};

    & > svg {
      fill: #08587d;
    }
  }
  .iconimg {
    position: absolute;
    top: 28%;
    left: 30%;
  }

  .moreinfo {
    color: ${theme.color.baseColor};
    font-weight: bold;
    font-size: 9px;
    @media only all and (min-width: 1024px) {
      font-size: 12px;
    }
  }
  div.box_1:nth-child(2)
    > a:nth-child(1)
    > div:nth-child(1)
    > svg:nth-child(1) {
    margin-top: 1px;
  }
`;
export default React.memo(mobileMidSection);
