export const greetingText = [
  '카라멜라 홈페이지를 찾아주신 분들께 감사드립니다.',
  '저희는 빠른 웹서비스 소프트웨어 구축 역량과 우수한 AI 기술 역량을 가지고 있으며 ',
  '끊임 없는 연구/개발 중심의 기술혁신과 구성원들의 성장을',
  '목표로 새로운 가치를 창출하기 위해 노력하고 있습니다.',
];

export const MobilegreetingText = [
  '카라멜라 홈페이지를 찾아주신 분들께 감사드립니다.',
  '저희는 빠른 웹서비스 소프트웨어 구축 역량과 우수한 AI 기술 역량을 가지고 있으며 ',
  '끊임 없는 연구/개발 중심의 기술혁신과 구성원들의 성장을 목표로',
  '새로운 가치를 창출하기 위해 노력하고 있습니다.',
];

export const webServiceText = [
  'JavaScript, ReactJS, NodeJS, Mongo 등을 사용해서',
  '고객에게 최고의 SaaS 경험을 제공하고 있습니다.',
];

export const deepLearningAIText = [
  '데이터 크롤러를 이용해 다양한 데이터를 수집하여',
  '이미지 분류, 시계열처리 등의 딥러닝을 개발하고 있습니다.',
];

export const AiLearningText = [
  '머신러닝, 딥러닝에 사용될 미가공 데이터를 학습에',
  '적합하게 가공하여 고객에게 제공하고 있습니다.',
];

export const TelText = '062-714-3626';
