import React from 'react';
import styled from 'styled-components';

import { portfolioes } from '@statics/portfolio';

import { pixelToRem, flexAlign } from '@utils/commonStyles';
import { theme } from '@utils/theme';

function PortfolioGrid() {
  return (
    <PortfolioGridContainer>
      {portfolioes.map((key, index) => (
        <PortfolioBackground image={key.img} key={index}>
          <div className='portfolio-darken'>{key.text}</div>
        </PortfolioBackground>
      ))}
    </PortfolioGridContainer>
  );
}

export const PortfolioGridContainer = styled.div`
  box-sizing: border-box;
  padding: ${pixelToRem(30)};
  border: 1px solid ${theme.border.gray};
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${pixelToRem(30)};
  row-gap: ${pixelToRem(30)};
  img {
    width: 100%;
  }
`;

export const PortfolioBackground = styled.div`
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 1.54 / 1;
  .portfolio-darken {
    width: 100%;
    height: 100%;
    display: none;
    font-weight: 900;
  }
  &:hover > .portfolio-darken {
    ${flexAlign()};
    background-color: rgba(0, 0, 0, 0.4);
    color: ${theme.color.white};
    font-size: ${pixelToRem(25)};
  }
`;

export default React.memo(PortfolioGrid);
