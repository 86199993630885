export default function postTypeSwitch(url) {
  switch (url) {
    case 'perform':
      return 'business_performance';
    case 'media':
      return 'press';
    case 'labnews':
      return 'lab_news';
    default:
      return url;
  }
}
