import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ContentAlign } from './styles';
import { theme } from '../../../utils/theme';

function HistoryContent() {
  const [src, setSrc] = useState('');
  const [file, setFile] = useState(undefined);

  const onShowHistory = useCallback(async () => {
    const res = await axios.get('/file/detail?target=history');
    if (res.data.status === 200) {
      const { Bucket, Key } = res.data.data.fileData;
      setSrc(`https://${Bucket}.s3.ap-northeast-2.amazonaws.com/${Key}`);
    }
  }, []);

  useEffect(() => {
    onShowHistory();
  }, []);

  const onUpload = useCallback(async (e) => {
    const token = sessionStorage.getItem('token');
    if (
      !token ||
      !e.target.files ||
      !e.target.files[0] ||
      e.target.files[0].size > 100 * 1024 * 1024
    ) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append('prefix', 'history');
      formData.append('files', e.target.files[0]);
      const res = await axios.post('/file', formData, {
        headers: { 'Content-Type': 'multipart/form-data', token },
      });
      if (res.data.status === 200) {
        setFile(undefined);
      }
    } catch (err) {
      // const a = 1
    }
  }, []);

  return (
    <HistoryContentContainer>
      <ContentAlign>
        <img src={src} alt={'history'} />
      </ContentAlign>
      {sessionStorage.getItem('token') && (
        <div className='upload'>
          <input
            type={'file'}
            hidden
            onChange={onUpload}
            id={'history-upload'}
            accept='image/*'
            multiple={false}
            value={file}
          />
          <label htmlFor={'history-upload'}>연혁 업로드</label>
        </div>
      )}
    </HistoryContentContainer>
  );
}

export const HistoryAlign = styled.div`
  display: flex;
  justify-content: center;
`;

export const HistoryContentContainer = styled.div`
  display: block;
  .upload {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 133px;
      height: 40px;
      background-color: ${theme.background.baseColor};
      color: ${theme.color.white};
      font-size: 17px;
      cursor: pointer;
    }
  }
`;

export default React.memo(HistoryContent);
