import React from 'react';
import Common from '../../../layout/mobile/Common';
import BusinessSection from '../../../components/mobile/business/BusinessSection';

function Business() {
  return (
    <>
      <Common>
        <BusinessSection />
      </Common>
    </>
  );
}
export default React.memo(Business);
