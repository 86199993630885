export default function testFiles(e, size = 100 * 1024 * 1024) {
  if (
    e.target.files &&
    e.target.files[0] &&
    Array.from(e.target.files).every((file) => file.size <= size)
  ) {
    return false;
  }
  return true;
}
