import React from 'react';
import Common from '../../../layout/mobile/Common';

import HistorySection from '../../../components/mobile/company/HistorySection';

function History() {
  return (
    <>
      <Common>
        <HistorySection />
      </Common>
    </>
  );
}
export default React.memo(History);
