import React, { useCallback, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router';
import SearchIcon from 'public/images/search.svg';

import { boardHeadText } from '@statics/texts/notices';

import useChangeString from '@hooks/useChangeString';
import useModal from '@hooks/useModal';

import getFirstUrl from '@utils/getFirstUrl';
import parseJwt from '@utils/parseJwt';
import postTypeSwitch from '@utils/postTypeSwitch';

import Modal from '../Modal';
import Pagination from '../Pagination';
import {
  TopContainer,
  TableContainer,
  HeadGrid,
  BodyGrid,
  FooterContainer,
  BodyGridNoContent,
} from './styles';

function List() {
  const [keyword, onKeyword, setKeyword] = useChangeString();
  const [list, setList] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [removeTarget, setRemoveTarget] = React.useState(-1);
  const [isExistPermission] = React.useState(
    parseJwt(sessionStorage.getItem('token') || false),
  );
  const location = useLocation();
  const [modalHidden, onHidden] = useModal();
  const [searchModalHidden, onSearchModalHidden] = useModal();
  const history = useHistory();

  const onCreating = useCallback(() => {
    history.push(`/${getFirstUrl(history.location.pathname)}/create`);
  }, [history]);

  const onUpdate = useCallback(
    (id) => {
      const prefix = getFirstUrl(history.location.pathname);
      history.push(`/${prefix}/update/${id}`);
    },
    [history],
  );

  const onRead = useCallback(
    (id) => () => {
      const prefix = history.location.pathname.split('/').filter((x) => x)[0];
      history.push(`${prefix}/${id}`);
    },
    [history],
  );

  const onRemoveReady = useCallback(
    (id) => () => {
      setRemoveTarget(id);
      onHidden();
    },
    [],
  );

  const onRemove = useCallback(async () => {
    try {
      const token = sessionStorage.getItem('token');
      if (removeTarget < 1 || !token) {
        return;
      }

      const formData = new FormData();
      formData.append('id', removeTarget);

      const result = await axios.post(
        '/post/delete',
        { id: removeTarget },
        {
          headers: { token },
        },
      );
      if (result.data.status === 200) {
        setList((prev) => prev.filter((x) => x.id !== removeTarget));
        setTotalCount((prev) => prev - 1);
        onHidden();
      } else {
        throw new Error('failed to remove the post');
      }
    } catch (err) {
      // console.error('fail!');
    }
  }, [removeTarget]);

  const onSearchClick = useCallback(async () => {
    try {
      if (keyword.trim().length < 1) {
        return;
      }

      const result = await axios.get(
        `/post?postType=${postTypeSwitch(
          getFirstUrl(location.pathname),
        )}&page=${page}&pageSize=${50}&keyword=${keyword}`,
      );

      if (result.data.status === 200) {
        setTotalCount(result.data.data.totalCount ?? 0);
        setKeyword('');
        if (!result.data.data.posts) {
          setList([]);
          onSearchModalHidden();
        } else {
          setList(result.data.data.posts);
        }
      } else {
        // const a = 1
      }
    } catch (err) {
      // const a = 1
    }
  }, [location, keyword]);

  const onSearch = useCallback(
    async (e) => {
      try {
        if (e.key === 'Enter' && keyword.trim().length > 0) {
          const result = await axios.get(
            `/post?postType=${postTypeSwitch(
              getFirstUrl(location.pathname),
            )}&page=${page}&pageSize=${50}&keyword=${keyword}`,
          );

          if (result.data.status === 200) {
            setTotalCount(result.data.data.totalCount ?? 0);
            setKeyword('');
            if (!result.data.data.posts) {
              setList([]);
              onSearchModalHidden();
            } else {
              setList(result.data.data.posts);
            }
          }
        }
      } catch (err) {
        // const a = 1
      }
    },
    [location, keyword],
  );

  const onShowList = useCallback(async (path, pageNumber) => {
    try {
      const result = await axios.get(
        `/post?postType=${postTypeSwitch(
          getFirstUrl(path),
        )}&page=${pageNumber}&pageSize=${50}`,
      );
      if (result.data.status === 200) {
        setTotalCount(result.data.data.totalCount ?? 0);
        if (!result.data.data.posts) {
          setList([]);
          return;
        }
        const x = result.data.data.posts.slice().sort((a, b) => b.id - a.id);
        setList(x);
      } else {
        throw new Error('error');
      }
    } catch (err) {
      // setList([]);
    }
  }, []);

  useEffect(() => {
    onShowList(history.location.pathname, page);
  }, [history.location.pathname, page]);

  const modalStyle = React.useMemo(
    () => ({
      textAlign: 'center',
      fontSize: 23,
      fontWeight: 600,
    }),
    [],
  );

  return (
    <>
      <Modal
        onHidden={onHidden}
        onClick={onRemove}
        modalHidden={modalHidden}
        titleSize={22}
        titleBold={800}>
        <p style={modalStyle}>해당 정보를 삭제하시겠습니까?</p>
      </Modal>
      <Modal
        onHidden={onSearchModalHidden}
        onClick={onSearchModalHidden}
        modalHidden={searchModalHidden}
        isExistSecondButton={false}
        titleSize={22}
        titleBold={800}>
        <p style={modalStyle}>검색결과가 없습니다</p>
      </Modal>
      <TableContainer>
        <TopContainer>
          <p>전체 {`${totalCount}건`}</p>
          <div className='searchBox'>
            <input
              placeholder='검색'
              value={keyword}
              onChange={onKeyword}
              onKeyUp={onSearch}
            />
            <SearchIcon onClick={onSearchClick} />
          </div>
        </TopContainer>
        <HeadGrid isExistPermission={isExistPermission}>
          {boardHeadText.map((value) => (
            <div key={value}>{value}</div>
          ))}
          {isExistPermission ? (
            <>
              <div>수정</div>
              <div>삭제</div>
            </>
          ) : (
            <></>
          )}
        </HeadGrid>
        {list && list.length > 0 ? (
          list.map((x, index) => (
            <BodyGrid key={x.id} isExistPermission={isExistPermission}>
              <div>{totalCount - (index + (page - 1) * 50)}</div>
              <div role='presentation' onClick={onRead(x.id)}>
                {x.title}
              </div>
              <div>{x.author}</div>
              <div>
                {new Date(x.createdAt)
                  .toLocaleDateString('ko-KR', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                  .replace(/\s+/g, '')
                  .slice(0, -1)}
              </div>
              {isExistPermission ? (
                <>
                  <div>
                    <button
                      type='button'
                      className='update'
                      onClick={() => onUpdate(x.id)}>
                      수정
                    </button>
                  </div>
                  <div>
                    <button type='button' onClick={onRemoveReady(x.id)}>
                      삭제
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </BodyGrid>
          ))
        ) : (
          <BodyGridNoContent>게시물이 없습니다</BodyGridNoContent>
        )}
        <FooterContainer isExistPermission={isExistPermission}>
          {isExistPermission && <div></div>}
          <Pagination
            setPage={setPage}
            page={page}
            totalCount={totalCount}
            isExistPermission={isExistPermission}
          />
          {isExistPermission ? (
            <button type='button' className='create' onClick={onCreating}>
              글쓰기
            </button>
          ) : (
            <></>
          )}
        </FooterContainer>
      </TableContainer>
    </>
  );
}

export default React.memo(List);
