import React from 'react';

import dataTool from 'public/images/business/desktop/dataTool.png';

import { AiLearningText } from '@statics/text';

import Common from '../../../layout/desktop/Common';
import {
  Contents,
  ImageLinkContainer,
  ImageLink,
} from '../../../components/desktop/business/styles';

function AI() {
  return (
    <Common>
      <Contents>
        <div className='explain'>
          {AiLearningText.map((text) => (
            <p className='text-bold' key={text}>
              {text}
            </p>
          ))}
        </div>
        <ImageLinkContainer>
          <ImageLink>
            <p>{'데이터 라벨링 툴'}</p>
            <img src={dataTool} alt='labeling tool' />
          </ImageLink>
        </ImageLinkContainer>
      </Contents>
    </Common>
  );
}

export default React.memo(AI);
