import React from 'react';

import MollyWeb from 'public/images/business/mobile/mollyweb.png';
// import DoceanWeb from 'public/images/business/mobile/doceanweb.png';
import DongguWeb from 'public/images/business/mobile/dongguweb.png';

import { webServiceText } from '@statics/text';

import { BusinessContent, BusinessBotContent } from './style';

function WebSection() {
  return (
    <>
      <BusinessContent>
        {webServiceText.map((text) => (
          <div className='content_bottom' key={text}>
            <div>
              <br />
              {text}
            </div>
          </div>
        ))}
      </BusinessContent>
      <BusinessBotContent>
        <div className='bot-content'>
          <a href='https://molly.kr' target='_blank' rel='noopener noreferrer'>
            <h1>Molly (몰리)</h1>
            <img src={MollyWeb} alt='Molly' />
          </a>
          <a
            href='https://donggusports.kr'
            target='_blank'
            rel='noopener noreferrer'>
            <h1>광주광역시동구체육회</h1>
            <img src={DongguWeb} alt='Donggu' />
          </a>
          {/* <a
            href='https://d-ocean.kr'
            target='_blank'
            rel='noopener noreferrer'>
            <h1>D-ocean(디오션)</h1>
            <img src={DoceanWeb} alt='Docean' />
          </a> */}
          <div></div>
        </div>
      </BusinessBotContent>
    </>
  );
}

export default React.memo(WebSection);
