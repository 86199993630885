import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';

function DesktopLayout({ children }) {
  return (
    <DesktopLayoutItem>
      <Header />
      {children}
      <Footer />
    </DesktopLayoutItem>
  );
}

const DesktopLayoutItem = styled.div`
  min-width: 1280px;
`;

export default React.memo(DesktopLayout);
