import React from 'react';
import styled from 'styled-components';

import Banner from 'public/images/banner3.png';

import { theme } from '@utils/theme';
import { flexAlign } from '@utils/commonStyles';

function mobileBanner() {
  return (
    <>
      <MobileBannerSide>
        <div className='background-cover'>
          <div className='banner-text'>
            <h1>IT융합 기술으로 미래를 향한 도전</h1>
            <p>
              빅데이터를 이용하여 최적화된 미래를 만드는
              <br />
              앞선 기술로 여러분과 함께 더 나은 미래를 만들어 갑니다.
            </p>
          </div>
        </div>
      </MobileBannerSide>
    </>
  );
}

const MobileBannerSide = styled.section`
  height: 11rem;
  position: relative;
  color: ${theme.color.white};
  width: 100%;
  background-image: url(${Banner});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media only all and (min-width: 768px) {
    background-position: center;
    height: 13rem;
  }
  @media only all and (min-width: 1024px) {
    background-position: center;
    height: 20rem;
  }

  .background-cover {
    ${flexAlign(undefined, undefined, 'none')}
    width: 100%;
    height: 100%;
    background-color: rgba(2, 21, 67, 0.3);
  }
  .banner-text {
    margin-left: 1rem;
  }
  .banner-text h1 {
    font-weight: bolder;
    font-size: 15px;
    line-height: 1rem;
    @media only all and (min-width: 768px) {
      font-size: 20px;
    }
  }

  .banner-text p {
    font-weight: 600;
    font-size: 9px;
    line-height: 1rem;
    @media only all and (min-width: 768px) {
      font-size: 10px;
    }
  }
`;

export default React.memo(mobileBanner);
