import React, { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import Common from '../../../layout/desktop/Common';
import useChangeString from '../../../hooks/useChangeString';
import { Fields, SubmitButton } from './styles';
import Modal from '../../../components/desktop/Modal';
import useModal from '../../../hooks/useModal';

function Develop() {
  const [companyName, onChangeCompanyName, setCompanyName] = useChangeString();
  const [name, onChangeName, setName] = useChangeString();
  const [email, onChangeEmail, setEmail] = useChangeString();
  const [tel, onChangeTel, setTel] = useChangeString();
  const [inquiryContent, onChangeInquiryContent, setInquiryContent] =
    useChangeString();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileNames, setUploadedFileNames] = useState([]);
  const history = useHistory();
  const [modalHidden, onHidden] = useModal();

  const onUpload = useCallback((e) => {
    if (!e.target.files) {
      return;
    }
    if (!e.target.files[0]) {
      return;
    }

    if (
      Array.from(e.target.files).some((file) => file.size > 100 * 1024 * 1024)
    ) {
      return;
    }

    Array.from(e.target.files).forEach((files) => {
      setUploadedFiles((prev) => prev.concat(files));
      setUploadedFileNames((prev) => prev.concat(files.name));
    });
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      if (
        !companyName.trim() ||
        !name.trim() ||
        !email.trim() ||
        !tel.trim() ||
        !inquiryContent.trim()
      ) {
        return;
      }

      const formData = new FormData();

      const body = {
        service: 'support',
        contents: JSON.stringify({
          to: 'pm@caramella.kr',
          from: email,
          subject: `${companyName}으로부터 문의가 도착했습니다.`,
          text: `회사명: ${companyName}\n담당자 성함: ${name}\n담당자 이메일: ${email}\n담당자 연락처: ${tel}\n문의 내용: ${inquiryContent}`,
        }),
      };

      Object.entries(body).forEach(([key, value]) => {
        formData.append(`${key}`, value);
      });

      if (uploadedFiles.length > 0) {
        uploadedFiles.forEach((uploadFile) => {
          formData.append(uploadFile.name, uploadFile);
        });
      }

      const result = await axios.post('/email', formData);

      if (result.data.status === 200) {
        setCompanyName('');
        setName('');
        setEmail('');
        setTel('');
        setInquiryContent('');
        setUploadedFiles([]);
        onHidden();
      } else {
        throw new Error('error');
      }
    } catch (err) {
      // const a = 1;
    }
  }, [companyName, name, email, tel, inquiryContent, uploadedFiles]);

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const modalSentenceStyle = useMemo(
    () => ({
      fontSize: 27,
      fontWeight: 600,
      color: '#000',
      textAlign: 'center',
    }),
    [],
  );

  return (
    <Common>
      <Modal
        onClick={onHidden}
        modalHidden={modalHidden}
        submitText={'확인'}
        isExistSecondButton={false}
        onHidden={onHidden}>
        <p style={modalSentenceStyle}>문의가 등록되었습니다</p>
      </Modal>
      <Fields>
        <div className='input-field'>
          <div className='left-field'>
            <span className='required'>{'\u002A'.normalize()}</span>회사명
          </div>
          <input
            type='text'
            className='right-field'
            value={companyName}
            onChange={onChangeCompanyName}
            placeholder={'회사명을 입력해주세요'}
          />
        </div>
        <div className='input-field'>
          <div className='left-field'>
            <span className='required'>{'\u002A'.normalize()}</span>담당자 성함
          </div>
          <input
            type='text'
            className='right-field'
            value={name}
            onChange={onChangeName}
            placeholder={'담당자 성함을 입력해주세요'}
          />
        </div>
        <div className='input-field'>
          <div className='left-field'>
            <span className='required'>{'\u002A'.normalize()}</span>담당자
            이메일
          </div>
          <input
            type='text'
            className='right-field'
            value={email}
            onChange={onChangeEmail}
            placeholder={'담당자 이메일을 입력해주세요'}
          />
        </div>
        <div className='input-field'>
          <div className='left-field'>
            <span className='required'>{'\u002A'.normalize()}</span>담당자
            연락처
          </div>
          <input
            type='text'
            className='right-field'
            value={tel}
            onChange={onChangeTel}
            placeholder={'담당자 연락처를 입력해주세요'}
          />
        </div>
        <div className='textarea-field'>
          <div className='left-field'>
            <span className='required'>{'\u002A'.normalize()}</span>문의 내용
          </div>
          <textarea
            className='right-field'
            value={inquiryContent}
            onChange={onChangeInquiryContent}
            rows={30}
            placeholder={'개발 문의 내용을 가능한 한 구체적으로 기입해주세요'}
          />
        </div>
        <div className='input-field'>
          <div className='left-field'>첨부파일</div>
          <div className='label-field'>
            <div className='label-container'>
              <div className='no-func'>
                <>
                  {`${'\u0020'.normalize()}`}{' '}
                  {uploadedFileNames.map((v, i) => (
                    <span key={i} style={{ fontSize: 14 }}>{`${v} `}</span>
                  ))}
                </>
              </div>
              <input
                hidden
                id='upload-files'
                type='file'
                accept='image/*'
                multiple
                onChange={onUpload}
              />
              <label htmlFor='upload-files' className='upload-label'>
                찾아보기
              </label>
            </div>
            <div className='additional'>{`${'\u002A'.normalize()} 프로젝트 파악 및 견적 산출에 도움이 될 자료가 있는 경우 첨부파일로 보내주시길 바랍니다`}</div>
          </div>
        </div>
      </Fields>
      <SubmitButton>
        <button type='button' onClick={onSubmit}>
          {'등록'}
        </button>
        <button type='button' onClick={onCancel}>
          {'닫기'}
        </button>
      </SubmitButton>
    </Common>
  );
}

export default React.memo(Develop);
