import React from 'react';

import molly from 'public/images/business/desktop/molly.png';
import dongGu from 'public/images/business/desktop/dongGu.png';
// import docean from 'public/images/business/desktop/docean.png';

import { webServiceText } from '@statics/text';

import Common from '../../../layout/desktop/Common';
import {
  Contents,
  ImageLink,
  ImageLinkContainer,
} from '../../../components/desktop/business/styles';

function Web() {
  return (
    <Common>
      <Contents>
        <div className='explain'>
          {webServiceText.map((v) => (
            <p key={v} className='text-bold'>
              {v}
            </p>
          ))}
        </div>
        <ImageLinkContainer>
          <ImageLink
            href='https://molly.kr'
            target='_blank'
            rel='noopener noreferrer'
            style={{ display: 'block', textAlign: 'center' }}>
            <p>{'Molly (몰리)'}</p>
            <img src={molly} alt='molly' />
          </ImageLink>
          <ImageLink
            href='https://donggusports.kr'
            target='_blank'
            rel='noopener noreferrer'>
            <p>{'광주광역시 동구체육회'}</p>
            <img src={dongGu} alt='dongGu' />
          </ImageLink>
          {/* <ImageLink
            href='https://d-ocean.kr'
            target='_blank'
            rel='noopener noreferrer'>
            <p>{'D-ocean (디오션)'}</p>
            <img src={docean} alt='docean' />
          </ImageLink> */}
        </ImageLinkContainer>
      </Contents>
    </Common>
  );
}

export default React.memo(Web);
