import styled from 'styled-components';
import { flexAlign, pixelToRem } from '../../../utils/commonStyles';

export const ContentAlign = styled.div`
  ${flexAlign()};
`;

export const AddressAlign = styled(ContentAlign)`
  .clipboard {
    cursor: pointer;
    ${flexAlign()};
  }
  p {
    font-size: ${pixelToRem(17)};
  }
`;
