import React, { useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import axios from 'axios';

import SearchIcon from 'public/images/search.svg';

import { baseUrl } from '@config/config';

import useChangeString from '@hooks/useChangeString';
import useModal from '@hooks/useModal';

import getFirstUrl from '@utils/getFirstUrl';
import parseJwt from '@utils/parseJwt';

import Pagination from '../Pagination';
import Modal from '../Modal';
import { NotioceSectionContent, WritingBtnSection } from './style';

function postTypeSwitch(url) {
  switch (url) {
    case 'perform':
      return 'business_performance';
    case 'media':
      return 'press';
    case 'labnews':
      return 'lab_news';
    default:
      return url;
  }
}

function NoticeSection() {
  const [keyword, onKeyword, setKeyword] = useChangeString();
  const [list, setList] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [removeTarget, setRemoveTarget] = React.useState(-1);
  const [isExistPermission] = React.useState(
    parseJwt(sessionStorage.getItem('token') || false),
  );
  const location = useLocation();
  const [modalHidden, onHidden] = useModal();
  const [searchModalHidden, onSearchModalHidden] = useModal();
  const history = useHistory();

  const onCreating = useCallback(() => {
    history.push(`/${getFirstUrl(history.location.pathname)}/create`);
  }, [history]);

  const onUpdate = useCallback(
    (id) => {
      const prefix = getFirstUrl(history.location.pathname);
      history.push(`/${prefix}/update/${id}`);
    },
    [history],
  );

  const onRead = useCallback(
    (id) => () => {
      const prefix = history.location.pathname.split('/').filter((x) => x)[0];
      history.push(`${prefix}/${id}`);
    },
    [history],
  );

  const onRemoveReady = useCallback(
    (id) => () => {
      setRemoveTarget(id);
      onHidden();
    },
    [],
  );

  const onRemove = useCallback(async () => {
    try {
      const token = sessionStorage.getItem('token');
      if (removeTarget < 1 || !token) {
        return;
      }
      const formData = new FormData();
      formData.append('id', removeTarget);
      const result = await axios.post(
        `${baseUrl}/post/delete`,
        { id: removeTarget },
        {
          headers: { token },
        },
      );
      if (result.data.status === 200) {
        setList((prev) => prev.filter((x) => x.id !== removeTarget));
        setTotalCount((prev) => prev - 1);
        onHidden();
      } else {
        throw new Error('failed to remove the post');
      }
    } catch (err) {
      // console.error('fail!');
    }
  }, [baseUrl, removeTarget]);

  const onSearchClick = useCallback(async () => {
    try {
      if (keyword.trim().length < 1) {
        return;
      }

      const result = await axios.get(
        `${baseUrl}/post?postType=${postTypeSwitch(
          getFirstUrl(location.pathname),
        )}&page=${page}&pageSize=${100}&keyword=${keyword}`,
      );

      if (result.data.status === 200) {
        setTotalCount(result.data.data.totalCount ?? 0);
        setKeyword('');
        if (!result.data.data.posts) {
          setList([]);
          onSearchModalHidden();
        } else {
          setList(result.data.data.posts);
        }
      } else {
        // const a = 1
      }
    } catch (err) {
      // const a = 1
    }
  }, [baseUrl, location, keyword]);

  const onSearch = useCallback(
    async (e) => {
      try {
        if (e.key === 'Enter' && keyword.trim().length > 0) {
          const result = await axios.get(
            `${baseUrl}/post?postType=${postTypeSwitch(
              getFirstUrl(location.pathname),
            )}&page=${page}&pageSize=${100}&keyword=${keyword}`,
          );
          if (result.data.status === 200) {
            setTotalCount(result.data.data.totalCount ?? 0);
            setKeyword('');
            if (!result.data.data.posts) {
              setList([]);
              onSearchModalHidden();
            } else {
              setList(result.data.data.posts);
            }
          }
        }
      } catch (err) {
        // const a = 1
      }
    },
    [baseUrl, location, keyword],
  );

  const onShowList = useCallback(
    async (path, pageNumber) => {
      try {
        const result = await axios.get(
          `${baseUrl}/post?postType=${postTypeSwitch(
            getFirstUrl(path),
          )}&page=${pageNumber}&pageSize=${10}`,
        );
        if (result.data.status === 200) {
          setTotalCount(result.data.data.totalCount ?? 0);
          if (!result.data.data.posts) {
            setList([]);
            return;
          }
          const x = result.data.data.posts.slice().sort((a, b) => b.id - a.id);
          setList(x);
        } else {
          throw new Error('error');
        }
      } catch (err) {
        // setList([]);
      }
    },
    [baseUrl],
  );

  useEffect(() => {
    onShowList(history.location.pathname, page);
  }, [history.location.pathname, page]);

  return (
    <>
      <Modal onHidden={onHidden} onClick={onRemove} modalHidden={modalHidden}>
        <p
          style={{
            textAlign: 'center',
            fontSize: 15,
            fontWeight: 900,
          }}>
          해당 정보를 <span style={{ color: 'red' }}>삭제</span>하시겠습니까?
        </p>
      </Modal>
      <Modal
        onHidden={onSearchModalHidden}
        onClick={onSearchModalHidden}
        modalHidden={searchModalHidden}
        isExistSecondButton={false}
        titleSize={15}
        titleBold={800}>
        <p
          style={{
            textAlign: 'center',
            fontSize: 15,
            fontWeight: 600,
          }}>
          검색결과가 없습니다.
        </p>
      </Modal>
      <NotioceSectionContent>
        <div className='searchZone'>
          <SearchIcon width='30px' height='25px' />
          <input
            placeholder='검색'
            value={keyword}
            onChange={onKeyword}
            onKeyUp={onSearch}
          />
          <button className='searchBtn search' onClick={onSearchClick}>
            검색
          </button>
        </div>

        <div className='notice_content'>
          {list && list.length > 0 ? (
            list.map((x) => (
              <div
                className='top'
                key={x.id}
                // isExistPermission={isExistPermission}
              >
                <div className='notice-id'>{x.id}</div>
                <div className='top-title'>
                  <span
                    className='notice_title'
                    role='presentation'
                    onClick={onRead(x.id)}>
                    {x.title}
                  </span>
                  {isExistPermission ? (
                    <>
                      <div className='admin-btn'>
                        <button
                          className='update'
                          onClick={() => onUpdate(x.id)}>
                          수정
                        </button>

                        <button onClick={onRemoveReady(x.id)}>삭제</button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className='notice_info'>
                  <div className='notice_date'>
                    {new Date(x.createdAt)
                      .toLocaleDateString('ko-KR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })
                      .replace(/\s+/g, '')
                      .slice(0, -1)}
                  </div>
                  <div className='notice_author'>{x.author}</div>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </NotioceSectionContent>

      <WritingBtnSection isExistPermission={isExistPermission}>
        <div></div>
        {isExistPermission ? (
          <button type='button' className='create' onClick={onCreating}>
            글쓰기
          </button>
        ) : (
          <></>
        )}
      </WritingBtnSection>
      <Pagination
        isExistPermission={isExistPermission}
        setPage={setPage}
        page={page}
        totalCount={totalCount}
      />
    </>
  );
}

export default React.memo(NoticeSection);
