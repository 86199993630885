import React from 'react';
import Common from '../../../layout/desktop/Common';
import GreetingContent from '../../../components/desktop/company/GreetingContent';

function Greeting() {
  return (
    <Common>
      <GreetingContent />
    </Common>
  );
}

export default React.memo(Greeting);
