import React from 'react';
import Common from '../../../layout/mobile/Common';
import DevelopSection from '../../../components/mobile/develop/DevelopSection';

function Develop() {
  return (
    <>
      <Common>
        <DevelopSection />
      </Common>
    </>
  );
}
export default React.memo(Develop);
