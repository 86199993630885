import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import ReactQuill from 'react-quill';
import axios from 'axios';

import { subMenu } from '@statics/menu';
import { useQuill } from '@statics/quillModules';

import useText from '@hooks/useText';
import useChangeString from '@hooks/useChangeString';

import getFirstUrl from '@utils/getFirstUrl';

import { CreateContainer, Fields, FileTag } from './styles';
import './quill.snow.css';

function Update() {
  const [textEditor, onTextEditor, textContents, setTextEditor] = useText();
  const [title, onTitle, setTitle] = useChangeString();
  const [author, onAuthor, setAuthor] = useChangeString();

  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const { quillModules, QuillRef } = useQuill();

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onUpload = useCallback((e) => {
    if (e.target.files) {
      return;
    }

    if (e.target.files[0]) {
      return;
    }

    if (
      Array.from(e.target.files).some((file) => file.size > 100 * 1024 * 1024)
    ) {
      return;
    }

    const formData = new FormData();
    Array.from(e.target.files).forEach((file) => {
      formData.append('file', file);
      setUploadedFiles((prev) => prev.concat(file.name));
    });
  }, []);

  const onRemove = useCallback((filename) => {
    setUploadedFiles((prev) => prev.filter((v) => v !== filename));
  }, []);

  const onUpdate = useCallback(async () => {
    // e.preventDefault();
    try {
      const token = sessionStorage.getItem('token');
      if (
        !title.trim() ||
        !author.trim() ||
        !textContents.trim().replace(/\s/g, '') ||
        !token
      ) {
        return;
      }

      const formData = new FormData();
      const body = {
        id: params.id,
        title,
        contents: textEditor,
        author,
      };

      Object.entries(body).forEach(([key, value]) => {
        formData.append(`${key}`, value);
      });

      const result = await axios.post('/post/edit', formData, {
        headers: { token },
      });

      if (result.data.status === 200) {
        history.push(`/${getFirstUrl(location.pathname)}`);
      } else {
        throw new Error('error');
      }
    } catch (err) {
      // const b = 3;
    }
  }, [location, title, author, textContents, textEditor, history]);

  const onLoadContent = useCallback(async () => {
    try {
      const result = await axios.get(`/post/${params.id}`);
      if (result.data.status === 200) {
        const { post } = result.data.data;
        setTitle(post.title);
        setAuthor(post.author);
        setTextEditor(post.contents);
      } else {
        throw new Error('error');
      }
    } catch (err) {
      // const a = 1
    }
  }, [params]);

  useEffect(() => {
    onLoadContent();
  }, []);

  return (
    <CreateContainer>
      <div className={'create-header'}>{`${
        Object.values(subMenu)
          .flat()
          .find(
            (x) =>
              x.path === `/${location.pathname.split('/').filter((c) => c)[0]}`,
          ).title
      } 글 수정`}</div>
      <Fields>
        <div className='input-field'>
          <div className='left-field'>제목</div>
          <input
            type='text'
            className='right-field'
            value={title}
            onChange={onTitle}
          />
        </div>
        <div className='input-field'>
          <div className='left-field'>작성자</div>
          <input
            type='text'
            className='right-field'
            value={author}
            onChange={onAuthor}
          />
        </div>
        <div className='textarea-field'>
          <div className='left-field'>내용</div>
          <ReactQuill
            ref={(element) => {
              if (element !== null) {
                QuillRef.current = element;
              }
            }}
            modules={quillModules}
            theme='snow'
            value={textEditor}
            onChange={onTextEditor}
            className='right-field textarea'
          />
        </div>
        <div className='input-field'>
          <div className='left-field'>첨부파일</div>
          <div className='label-field'>
            <input
              hidden
              id='upload-files'
              type='file'
              accept='image/*'
              multiple
              onChange={onUpload}
            />
            <label htmlFor='upload-files' className='upload-label'>
              파일선택
            </label>
            {uploadedFiles.map((v) => (
              <FileTag key={v}>
                <span>{v}</span>
                <span
                  role='presentation'
                  className='cancel-imoji'
                  onClick={() => onRemove(v)}
                  onKeyUp={() => {}}>
                  {String.fromCharCode(0x1f5d9)}
                </span>
              </FileTag>
            ))}
          </div>
        </div>
      </Fields>
      <div className={'create-footer'}>
        <button type='button' onClick={onUpdate}>
          수정
        </button>
        <button type='button' onClick={onCancel}>
          닫기
        </button>
      </div>
    </CreateContainer>
  );
}

export default React.memo(Update);
